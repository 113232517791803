import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { authService } from "services/authService";

const LineCallback: React.FC = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		const code = params.get("code");
		const state = params.get("state");
		const error = params.get("error");

		if (error) {
			setError("Line login failed.");
			return;
		}

		if (code) {
			handleLineLogin(code);
		}
	}, [location.search]);

	const handleLineLogin = async (code: string) => {
		try {
			const response = await authService.lineLogin(code);

			if (response.accessToken) {
				localStorage.setItem("access_token", response.accessToken);

				// 유저가 새로운 회원이라면 추가 정보 입력 페이지로 이동
				if (response.isNewUser) {
					if (response.email) {
						// 이메일이 있다면 신규 유저지만 새로운 정보를 받을 필요 없음
						// navigate("/");
						window.location.href = "/";
					}
					navigate("/signup/line");
				} else {
					// navigate("/");
					window.location.href = "/";
				}
			}
		} catch (error) {
			setError("Login failed. Please try again.");
		}
	};

	return (
		<div className="flex items-center justify-center min-h-screen bg-gray-50">
			<div className="p-6 bg-white rounded shadow-md">
				{error ? <p className="text-red-500">{error}</p> : <p className="text-gray-700">Processing Line login...</p>}
			</div>
		</div>
	);
};

export default LineCallback;
