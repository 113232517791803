import React, { useEffect, useState } from "react";
import SpinnerMedium from "components/Spinner/Medium";
import authClient from "apis/auth";
import { useTranslation } from "react-i18next";
import PageWithBackButton from "./PageWithBackButton";

interface PointHistoryItem {
	historyId: number;
	amount: number;
	type: "ADD" | "USE";
	description: string;
	createdAt: string;
}

const PointPage = () => {
	const { t } = useTranslation();
	const [point, setPoint] = useState<number | null>(null);
	const [history, setHistory] = useState<PointHistoryItem[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [page, setPage] = useState<number>(1);
	const [total, setTotal] = useState<number>(0);
	const limit = 10; // 한 페이지에 보여줄 개수

	useEffect(() => {
		fetchUserPoint();
		fetchPointHistory(page);
	}, [page]);

	// ✅ 현재 보유 포인트 조회
	const fetchUserPoint = async () => {
		try {
			const res = await authClient.get("/user/points");
			setPoint(res.data.data.point);
		} catch (error) {
			console.error(t("point.fetch_error"), error);
		}
	};

	// ✅ 포인트 변동 내역 조회
	const fetchPointHistory = async (page: number) => {
		setLoading(true);
		try {
			const res = await authClient.get(`/user/points/history?page=${page}&limit=${limit}`);
			setHistory(res.data.data.items);
			setTotal(res.data.data.total);
		} catch (error) {
			console.error(t("point.history_fetch_error"), error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<PageWithBackButton>
			<div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg mt-10">
				<h1 className="text-2xl font-bold text-gray-800 mb-6">{t("point.title")}</h1>

				{/* ✅ 현재 보유 포인트 */}
				<div className="p-6 bg-indigo-100 rounded-lg flex justify-between items-center shadow">
					<h2 className="text-lg font-semibold text-gray-900">{t("point.current")}</h2>
					<p className="text-2xl font-bold text-rose-500">{point !== null ? `${point} P` : t("common.loading")}</p>
				</div>

				{/* ✅ 포인트 내역 리스트 */}
				<div className="mt-6">
					<h2 className="text-lg font-semibold text-gray-900">{t("point.history")}</h2>
					{loading ? (
						<SpinnerMedium />
					) : history.length > 0 ? (
						<div className="mt-4">
							<ul className="divide-y divide-gray-200 border rounded-lg">
								{history.map((item) => (
									<li key={item.historyId} className="p-4 flex justify-between items-center">
										<div>
											<p className="text-sm text-gray-500">{new Date(item.createdAt).toLocaleString()}</p>
											<p className="text-sm font-medium">{item.description}</p>
										</div>
										<span className={`font-bold text-lg ${item.type === "ADD" ? "text-green-500" : "text-red-500"}`}>
											{item.type === "ADD" ? "+" : ""}
											{item.amount} P
										</span>
									</li>
								))}
							</ul>

							{/* ✅ 페이지네이션 */}
							<div className="flex justify-between items-center mt-4">
								<button
									className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md disabled:opacity-50"
									onClick={() => setPage((prev) => Math.max(1, prev - 1))}
									disabled={page === 1}>
									{t("common.previous")}
								</button>
								<span className="text-gray-700">
									{page} / {Math.ceil(total / limit)}
								</span>
								<button
									className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md disabled:opacity-50"
									onClick={() => setPage((prev) => prev + 1)}
									disabled={page * limit >= total}>
									{t("common.next")}
								</button>
							</div>
						</div>
					) : (
						<p className="text-gray-500 mt-4">{t("point.no_history")}</p>
					)}
				</div>
			</div>
		</PageWithBackButton>
	);
};

export default PointPage;
