import { menuItems } from "constants/menu";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function MyPageLayoutForMobile() {
	const navigate = useNavigate();
	const { t } = useTranslation();

	return (
		<div className="p-4">
			{/* <h1 className="text-xl font-bold">마이페이지</h1> */}
			<ul className="mt-4 space-y-2">
				{menuItems.map((item) => (
					<li key={item.path}>
						<button onClick={() => navigate(item.path)} className="w-full p-3 text-left text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200">
							{t(item.nameKey)}
						</button>
					</li>
				))}
			</ul>
		</div>
	);
}
