import authClient from "apis/auth";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import AddressSelectPopup from "./addressSelectPopup";
import { formatCurrency } from "utils/strUtil";
import { CreateOrderDto } from "constants/interface";

// 인터페이스 정의
interface CartItem {
	cartItemId: number;
	quantity: number;
	product: {
		productId: number;
		name: string;
		description: string;
		mainImage: string;
		price: string; // 문자열로 응답
		seller: {
			sellerId: number;
			name: string;
			shippingPolicy: SellerShippingPolicy | null;
		};
	};
	productOptionValue: { valueId: string; additionalPrice: string; quantity: string; value: string } | null;
}

interface SellerShippingPolicy {
	policyId: number;
	minimumOrderAmount: string;
	shippingFee: string;
	freeShippingThreshold: string;
	estimatedDeliveryTime?: string;
	returnPeriod?: number;
	returnConditions?: string;
	seller?: {
		sellerId: number;
		name: string;
	};
}

interface SellerGroup {
	sellerId: number;
	sellerName: string;
	items: CartItem[];
	shippingPolicy: SellerShippingPolicy;
}

interface Coupon {
	userCouponId: number;
	coupon: {
		code: string;
		discountAmount: string; // 문자열로 응답
	};
}

const CheckoutPage = () => {
	const { t, i18n } = useTranslation();
	const [isAddressPopupOpen, setIsAddressPopupOpen] = useState(false);
	const [addresses, setAddresses] = useState<any[]>([]);
	const [selectedAddress, setSelectedAddress] = useState<any>(null);
	const [isProductListVisible, setIsProductListVisible] = useState<{ [key: number]: boolean }>({});
	const [paymentMethod, setPaymentMethod] = useState("CREDIT_CARD");
	const [coupons, setCoupons] = useState<Coupon[]>([]);
	const [selectedCoupon, setSelectedCoupon] = useState<Coupon | null>(null);
	const [points, setPoints] = useState(0);
	const [sellerGroups, setSellerGroups] = useState<SellerGroup[]>([]);
	// const [sellerGroups, setSellerGroups] = useState<{ [key: number]: boolean }[]>([]);
	const [userPoints, setUserPoints] = useState(0);
	const navigate = useNavigate();
	const location = useLocation();

	// 데이터 불러오기
	useEffect(() => {
		const fetchData = async () => {
			try {
				// ShoppingCartPage에서 전달된 sellerGroups 사용
				const stateSellerGroups = (location.state as { sellerGroups?: SellerGroup[] })?.sellerGroups;
				if (stateSellerGroups) {
					setSellerGroups(stateSellerGroups);
					// 각 셀러별 상품 목록 토글 상태 초기화
					const initialVisibility = stateSellerGroups.reduce((acc, group) => {
						acc[group.sellerId] = true;
						return acc;
					}, {} as { [key: number]: boolean });
					setIsProductListVisible(initialVisibility);
				} else {
					const cartRes = await authClient.get("/user/carts/item", { params: { locale: i18n.language } });
					const { items, sellerPolicies } = cartRes.data.data || { items: [], sellerPolicies: [] };

					const groupedBySeller = items.reduce((acc: Record<number, SellerGroup>, item: CartItem) => {
						const sellerId = item.product.seller.sellerId;
						if (!acc[sellerId]) {
							let policy = item.product.seller.shippingPolicy;
							if (!policy) {
								policy = sellerPolicies.find((p: SellerShippingPolicy) => p.seller?.sellerId === sellerId) || {
									policyId: -1,
									minimumOrderAmount: "0",
									shippingFee: "3000",
									freeShippingThreshold: "50000",
								};
							}
							acc[sellerId] = {
								sellerId,
								sellerName: item.product.seller.name,
								items: [],
								shippingPolicy: policy,
							};
						}
						acc[sellerId].items.push(item);
						return acc;
					}, {});
					const groups = Object.values(groupedBySeller) as unknown as SellerGroup[];
					setSellerGroups(groups);
					setIsProductListVisible(
						groups.reduce((acc, group) => {
							acc[group.sellerId] = false;
							return acc;
						}, {} as { [key: number]: boolean })
					);
				}

				const couponRes = await authClient.get("/user/user-coupons");
				setCoupons(couponRes.data.data.items || []);

				const addressRes = await authClient.get("/user/user-addresses");
				const addressData = addressRes.data.data || [];
				setAddresses(addressData);
				if (addressData.length > 0) {
					setSelectedAddress(addressData.find((addr) => addr.isDefault) || addressData[0]);
				}

				const pointsRes = await authClient.get("/user/points");
				setUserPoints(pointsRes.data.data.point || 0);
			} catch (error) {
				console.error(t("checkout.fetch_error"), error);
				toast.error(t("checkout.fetch_error"));
			}
		};
		fetchData();
	}, [location.state, i18n.language, t]);

	const openAddressPopup = () => setIsAddressPopupOpen(true);
	const closeAddressPopup = () => setIsAddressPopupOpen(false);
	const handleAddressSelect = (address: any) => {
		setSelectedAddress(address);
		closeAddressPopup();
	};

	const handleCouponSelect = (coupon: Coupon) => setSelectedCoupon(coupon);

	const applyPoints = () => {
		if (points < 0) {
			toast.error(t("checkout.invalid_points_error"));
			setPoints(0);
			return;
		}
		if (points > userPoints) {
			toast.error(t("checkout.points_exceed_error"));
			setPoints(Number(userPoints));
			return;
		}
		toast.success(t("checkout.points_applied", { points }));
	};

	const toggleProductList = (sellerId: number) => {
		setIsProductListVisible((prev) => ({
			...prev,
			[sellerId]: !prev[sellerId],
		}));
	};

	const calculateSubtotal = (items: CartItem[]) => items.reduce((acc, item) => acc + Number(item.product.price) * item.quantity, 0);

	const calculateShippingFee = (group: SellerGroup) => {
		const subtotal = calculateSubtotal(group.items);
		return subtotal >= Number(group.shippingPolicy.freeShippingThreshold) ? 0 : Number(group.shippingPolicy.shippingFee);
	};

	const calculateTotal = () => {
		const subtotal = sellerGroups.reduce((acc, group) => acc + calculateSubtotal(group.items), 0);
		const shippingFees = sellerGroups.reduce((acc, group) => acc + calculateShippingFee(group), 0);
		const discount = selectedCoupon ? Number(selectedCoupon.coupon.discountAmount) : 0;
		const pointsUsed = points || 0;
		return subtotal + shippingFees - discount - pointsUsed;
	};

	const createOrder = async () => {
		try {
			if (!selectedAddress) {
				toast.error(t("checkout.select_address_error"));
				return;
			}

			if (points > userPoints) {
				toast.error(t("checkout.points_exceed_error"));
				return;
			}
			// 셀러별 아이템을 하나의 items 배열로 통합
			const orderItems = sellerGroups.flatMap((group) =>
				group.items.map((item) => ({
					sellerId: group.sellerId,
					productId: item.product.productId,
					quantity: item.quantity,
					optionValueId: item.productOptionValue?.valueId || null,
					price: Number(item.product.price),
				}))
			);

			const totalSubtotal = sellerGroups.reduce((acc, group) => acc + calculateSubtotal(group.items), 0);
			const totalShippingFee = sellerGroups.reduce((acc, group) => acc + calculateShippingFee(group), 0);
			const discount = selectedCoupon ? Number(selectedCoupon.coupon.discountAmount) : 0;
			const pointsUsed = points || 0;
			const finalPayment = totalSubtotal + totalShippingFee - discount - pointsUsed;

			const body: CreateOrderDto = {
				status: "PENDING",
				items: orderItems,
				paymentMethod,
				totalPrice: totalSubtotal,
				discountCoupon: discount,
				usedPoints: pointsUsed,
				shippingFee: totalShippingFee,
				finalPayment,
				recipientName: selectedAddress.name,
				recipientPhone: selectedAddress.phoneNumber,
				shippingAddress: `${selectedAddress.state} ${selectedAddress.city} ${selectedAddress.address} ${selectedAddress.postalCode}`,
			};

			await authClient.post(`/user/orders`, body);
			toast.success(t("checkout.order_success"));
			navigate("/mypage/orders");
		} catch (e) {
			console.error(t("checkout.order_error"), e);
			toast.error(t("checkout.order_error"));
		}
	};

	const addressSection = (
		<section className="bg-white p-6 rounded-lg shadow-md mb-6">
			<h3 className="text-lg font-semibold mb-4">{t("checkout.address_info")}</h3>
			<div className="flex flex-col md:flex-row justify-between items-start md:items-center">
				<div>
					{selectedAddress ? (
						<>
							<div className="font-medium">{selectedAddress.name}</div>
							<ul className="text-sm text-gray-600">
								<li>{selectedAddress.phoneNumber}</li>
							</ul>
							<div className="text-sm text-gray-600">
								{selectedAddress.address}, {selectedAddress.city}, {selectedAddress.state} {selectedAddress.postalCode}
							</div>
						</>
					) : (
						<p className="text-sm text-gray-600">{t("checkout.select_address")}</p>
					)}
				</div>
				<button onClick={openAddressPopup} className="mt-4 md:mt-0 text-blue-500 hover:text-blue-700">
					{t("common.edit")}
				</button>
			</div>
		</section>
	);

	const orderSummary = (
		<section className="bg-white p-6 rounded-lg shadow-md mb-6">
			<h3 className="text-lg font-semibold mb-4">{t("checkout.order_summary")}</h3>
			<div className="space-y-4">
				{sellerGroups.map((group) => (
					<div key={group.sellerId} className="space-y-2">
						<div className="flex justify-between">
							<span>
								{group.sellerName} {t("checkout.item_total")}
							</span>
							<span>{formatCurrency(calculateSubtotal(group.items), i18n.language)}</span>
						</div>
						<div className="flex justify-between">
							<span>
								{group.sellerName} {t("checkout.shipping_fee")}
							</span>
							<span>
								{formatCurrency(calculateShippingFee(group), i18n.language)}
								{calculateShippingFee(group) === 0 && <span className="text-green-600 ml-2">({t("cart.free_shipping")})</span>}
							</span>
						</div>
					</div>
				))}
				<div className="flex justify-between">
					<span>{t("checkout.discount")}</span>
					<span>-{formatCurrency(selectedCoupon ? Number(selectedCoupon.coupon.discountAmount) : 0, i18n.language)}</span>
				</div>
				<div className="flex justify-between">
					<span>{t("checkout.used_points")}</span>
					<span>-{formatCurrency(points || 0, i18n.language)}</span>
				</div>
				<div className="flex justify-between font-bold border-t border-gray-200 pt-4">
					<span>{t("checkout.total_payment")}</span>
					<span>{formatCurrency(calculateTotal(), i18n.language)}</span>
				</div>
			</div>
		</section>
	);

	const pointSection = (
		<div>
			<h4 className="font-medium mb-2">{t("checkout.apply_points")}</h4>
			<div className="flex flex-col md:flex-row items-start md:items-center gap-4">
				<input
					type="number"
					value={points}
					onChange={(e) => setPoints(Number(e.target.value))}
					placeholder={t("checkout.enter_points")}
					className="w-full p-2 border rounded-lg"
					min="0"
					max={userPoints}
				/>
				<button onClick={applyPoints} className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-700">
					{t("common.apply")}
				</button>
			</div>
			<p className="text-sm text-gray-600 mt-2">
				{t("checkout.available_points")}: <strong>{userPoints}P</strong>
			</p>
		</div>
	);

	return (
		<div className="bg-gray-100 min-h-screen pb-24">
			<div className="container mx-auto p-4">
				<h2 className="text-2xl font-bold mb-6">{t("checkout.page_title")}</h2>

				<div className="lg:flex lg:gap-6">
					<div className="lg:w-2/3">
						{addressSection}

						<section className="bg-white p-6 rounded-lg shadow-md mb-6">
							<h3 className="text-lg font-semibold mb-4">{t("checkout.shipping_method")}</h3>
							{sellerGroups.map((group) => (
								<div key={group.sellerId} className="mb-4">
									<strong className="font-medium">
										{group.sellerName} - {formatCurrency(calculateShippingFee(group), i18n.language)}
									</strong>
									{group.shippingPolicy.estimatedDeliveryTime && (
										<p className="text-sm text-gray-600">
											{t("checkout.estimated_delivery")}: {group.shippingPolicy.estimatedDeliveryTime}
										</p>
									)}
								</div>
							))}
						</section>

						<section className="bg-white p-6 rounded-lg shadow-md mb-6">
							<h3 className="text-lg font-semibold mb-4">{t("checkout.discount_apply")}</h3>
							<div className="space-y-4">
								<div>
									<h4 className="font-medium mb-2">{t("checkout.select_coupon")}</h4>
									<select
										value={selectedCoupon?.userCouponId || ""}
										onChange={(e) => {
											const coupon = coupons.find((c) => c.userCouponId === parseInt(e.target.value, 10));
											setSelectedCoupon(coupon || null);
										}}
										className="w-full p-2 border rounded-lg">
										<option value="">{t("checkout.choose_coupon")}</option>
										{coupons.map((coupon) => (
											<option key={coupon.userCouponId} value={coupon.userCouponId}>
												{coupon.coupon.code} ({formatCurrency(Number(coupon.coupon.discountAmount), i18n.language)})
											</option>
										))}
									</select>
								</div>
								{pointSection}
							</div>
						</section>

						<section className="bg-white p-6 rounded-lg shadow-md mb-6">
							<h3 className="text-lg font-semibold mb-4">{t("checkout.payment_method")}</h3>
							<select value={paymentMethod} onChange={(e) => setPaymentMethod(e.target.value)} className="w-full p-2 border rounded-lg">
								<option value="CREDIT_CARD">{t("checkout.credit_card")}</option>
								<option value="BANK_TRANSFER">{t("checkout.bank_transfer")}</option>
								<option value="PAYPAL">{t("checkout.paypal")}</option>
								<option value="GOOGLE_PAY">{t("checkout.google_pay")}</option>
								<option value="APPLE_PAY">{t("checkout.apple_pay")}</option>
								<option value="PAYPAY">{t("checkout.paypay")}</option>
								<option value="KAKAO_PAY">{t("checkout.kakao_pay")}</option>
							</select>
						</section>
					</div>

					<div className="lg:w-1/3">
						{sellerGroups.map((group) => (
							<section key={group.sellerId} className="bg-white p-6 rounded-lg shadow-md mb-6">
								<button onClick={() => toggleProductList(group.sellerId)} className="w-full text-left flex justify-between items-center">
									<h3 className="text-lg font-semibold">
										{group.sellerName} {t("checkout.order_items")}
									</h3>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										className={`h-6 w-6 transform transition-transform ${isProductListVisible[group.sellerId] ? "rotate-180" : ""}`}
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
									</svg>
								</button>
								{isProductListVisible[group.sellerId] && (
									<div className="mt-4 space-y-4">
										{group.items.map((item) => (
											<div
												key={item.cartItemId}
												className="flex items-center gap-4"
												onClick={() => {
													navigate(`/product/${item.product.productId}`);
													window.scrollTo(0, 0);
												}}>
												<img src={item.product.mainImage} alt={item.product.name} className="w-20 h-20 object-cover rounded-lg" />
												<div>
													<p className="font-medium">{item.product.name}</p>
													<p className="text-sm text-gray-600">
														{t("checkout.quantity")}: {item.quantity}
													</p>
													{item.productOptionValue && (
														<p className="text-sm text-gray-600">
															{t("checkout.option")}: {item.productOptionValue.value}
														</p>
													)}
													{/* <p className="text-sm text-gray-600">옵션: {item.productOptionValue.value}</p> */}
													<p className="text-sm text-gray-600">{formatCurrency(Number(item.product.price), i18n.language)}</p>
												</div>
											</div>
										))}
									</div>
								)}
							</section>
						))}
						{orderSummary}
					</div>
				</div>
			</div>

			<div className="fixed bottom-0 left-0 right-0 bg-white p-4 shadow-lg">
				<button
					onClick={createOrder}
					className="w-full bg-rose-400 text-white py-3 rounded-lg hover:bg-rose-600 disabled:bg-gray-400 disabled:cursor-not-allowed"
					disabled={sellerGroups.some((group) => calculateSubtotal(group.items) < Number(group.shippingPolicy.minimumOrderAmount))}>
					{t("checkout.order_now")}
				</button>
			</div>

			{isAddressPopupOpen && <AddressSelectPopup addresses={addresses} onClose={closeAddressPopup} onSelect={handleAddressSelect} />}
		</div>
	);
};

export default CheckoutPage;
