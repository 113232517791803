import { DEFAULT_LOCALE } from "constants/common";

export const ellipsisString = (str: string, max: number = 10) => {
	if (!str) {
		return "";
	}
	if (max < 2) {
		return str;
	}
	return str.length > max ? str.slice(0, max) + "..." : str;
};

export const formatCurrency = (amount: number, locale: string = DEFAULT_LOCALE) => {
	const currencyMap: Record<string, { currency: string; format: (value: string) => string }> = {
		en: { currency: "USD", format: (value) => `$${value}` },
		ja: { currency: "JPY", format: (value) => `¥${value}` },
		ko: { currency: "KRW", format: (value) => `${value}원` },
	};

	const { currency, format } = currencyMap[locale] || currencyMap[DEFAULT_LOCALE]; // 기본값: JPY
	const formattedAmount = new Intl.NumberFormat(locale, { style: "decimal" }).format(amount);

	return format(formattedAmount);
};

// import { useTranslation } from "react-i18next";

// const PriceComponent = ({ amount }: { amount: number }) => {
// 	const { i18n } = useTranslation();
// 	const locale = i18n.language || "en"; // 기본값 en

// 	return <span>{formatCurrency(amount, locale)}</span>;
// };

// export default PriceComponent;
