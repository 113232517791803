import { commonClient } from "apis/common";
import SpinnerMedium from "components/Spinner/Medium";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { formatCurrency } from "utils/strUtil";

const BrandDetailPage = () => {
	const { sellerId } = useParams();
	const navigate = useNavigate();
	const { t, i18n } = useTranslation();
	const [products, setProducts] = useState([]);
	const [shopInfo, setShopInfo] = useState(null);
	const [loading, setLoading] = useState(true);
	const [hasMore, setHasMore] = useState(true);
	const pageRef = useRef(1);

	// 브랜드 정보 & 첫 번째 상품 목록 가져오기
	useEffect(() => {
		setProducts([]);
		setHasMore(true);
		pageRef.current = 1;

		fetchBrandInfo();
		fetchProducts();
	}, [sellerId]);

	const fetchBrandInfo = async () => {
		try {
			const sellerRes = await commonClient.get(`/sellers/${sellerId}`);
			setShopInfo(sellerRes.data.data);
		} catch (error) {
			console.error("브랜드 정보 불러오기 실패:", error);
		}
	};

	const fetchProducts = async () => {
		try {
			setLoading(true);
			const res = await commonClient.get(`/products/sellers/${sellerId}`, {
				params: { page: pageRef.current, limit: 20 },
			});
			const newProducts = res.data.data.items;

			if (newProducts.length === 0) {
				setHasMore(false);
			} else {
				setProducts(newProducts);
				pageRef.current += 1;
			}
		} catch (error) {
			console.error("상품 불러오기 실패:", error);
		} finally {
			setLoading(false);
		}
	};

	// 스크롤 감지하여 추가 상품 로드
	const handleScroll = useCallback(() => {
		if (!hasMore || loading) return;

		const scrollHeight = document.documentElement.scrollHeight;
		const scrollTop = document.documentElement.scrollTop;
		const clientHeight = window.innerHeight;

		if (scrollTop + clientHeight >= scrollHeight - 100) {
			setLoading(true);
			commonClient
				.get(`/products/sellers/${sellerId}`, { params: { page: pageRef.current, limit: 20 } })
				.then((res) => {
					const newProducts = res.data.data.items;
					if (newProducts.length === 0) {
						setHasMore(false);
					} else {
						setProducts((prev) => [...prev, ...newProducts]);
						pageRef.current += 1;
					}
				})
				.catch((error) => console.error("추가 상품 불러오기 실패:", error))
				.finally(() => setLoading(false));
		}
	}, [sellerId, hasMore, loading]);

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, [handleScroll]);

	if (!shopInfo) return <SpinnerMedium />;

	return (
		<div className="bg-white">
			{/* 브랜드 정보 섹션 */}
			<div className="max-w-7xl mx-auto px-4 py-6 sm:px-6 lg:px-8">
				<div className="flex flex-col md:flex-row items-center md:items-start gap-6">
					<img
						src={shopInfo.logoUrl || "https://via.placeholder.com/200"}
						alt={shopInfo.name}
						className="w-32 h-32 rounded-lg shadow-md object-cover"
					/>
					<div>
						<h2 className="text-3xl font-bold tracking-tight text-gray-900">{shopInfo.name}</h2>
						<p className="mt-2 text-gray-600">{shopInfo.description || t("brand.no_description")}</p>
					</div>
				</div>
			</div>

			{/* 상품 목록 */}
			<div className="max-w-7xl mx-auto px-4 pb-6 sm:px-6 lg:px-8">
				<h3 className="text-xl font-semibold text-gray-800 mb-4">{t("brand.product_list")}</h3>

				{!loading && products.length === 0 && <p className="text-gray-500 text-center py-6">{t("brand.no_products")}</p>}

				<div className="grid grid-cols-2 gap-x-4 gap-y-10 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5">
					{products.map((product) => (
						<div key={product.productId} className="group relative cursor-pointer" onClick={() => navigate(`/product/${product.productId}`)}>
							<div className="h-56 w-full overflow-hidden rounded-md bg-gray-200 group-hover:opacity-75 lg:h-72">
								<img
									src={product.mainImage || "https://via.placeholder.com/300"}
									alt={product.name}
									className="h-full w-full object-cover object-center"
								/>
							</div>
							<h3 className="mt-2 text-sm text-gray-700">{product.name}</h3>
							{/* <p className="text-sm text-gray-500">{product.color || t("brand.no_color_info")}</p> */}
							<p className="mt-1 text-sm font-medium text-gray-900">{formatCurrency(product.price, i18n.language)}</p>
						</div>
					))}
				</div>

				{loading && (
					<div className="flex justify-center mt-6">
						<SpinnerMedium />
					</div>
				)}
			</div>
		</div>
	);
};

export default BrandDetailPage;
