import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { infoService } from "services/info";
import { ellipsisString } from "utils/strUtil";

interface SectionProps {
	title: string;
	data: {
		id: number;
		name: string;
		imageUrl: string;
		description?: string;
		alt?: string;
	}[];
	type: "brand" | "category";
}

const Section: React.FC<SectionProps> = ({ title, data, type }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	return (
		<div className="mx-auto max-w-7xl px-4 sm:px-6 py-6 lg:px-8">
			<div className="sm:flex sm:items-baseline sm:justify-between">
				<h2 className="text-2xl font-bold tracking-tight text-gray-900">{title}</h2>
				<a href={`/${type}`} className="hidden text-sm font-semibold text-rose-400 hover:text-rose-300 sm:block">
					{t("preview.browse_all")} {type === "brand" ? t("preview.brands") : t("preview.categories")}
					<span aria-hidden="true"> &rarr;</span>
				</a>
			</div>

			<div className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:grid-rows-2 sm:gap-x-6 lg:gap-8">
				{data.map((item) => (
					<div
						key={item.id}
						onClick={() => navigate(`/${type}/${item.id}`)}
						className="cursor-pointer group aspect-h-1 aspect-w-2 overflow-hidden rounded-lg sm:relative sm:h-full">
						<img
							alt={item.alt || `${t("preview.image_of")} ${item.name}`}
							src={item.imageUrl}
							className="object-cover object-center group-hover:opacity-75 sm:absolute sm:inset-0 sm:h-full sm:w-full"
						/>
						<div aria-hidden="true" className="bg-gradient-to-b from-transparent to-black opacity-50 sm:absolute sm:inset-0" />
						<div className="flex items-end p-6 sm:absolute sm:inset-0">
							<div>
								<h3 className="font-semibold text-white">
									<button onClick={() => navigate(`/${type}/${item.id}`)} className="cursor-pointer">
										{item.name}
									</button>
								</h3>
								{item.description && (
									<p aria-hidden="true" className="mt-1 text-sm text-white">
										{ellipsisString(item.description, 100)}
									</p>
								)}
							</div>
						</div>
					</div>
				))}
			</div>

			<div className="mt-6 sm:hidden">
				<a href={`/${type}`} className="block text-sm font-semibold text-rose-400 hover:text-rose-300">
					{t("preview.browse_all")} {type === "brand" ? t("preview.brands") : t("preview.categories")}
					<span aria-hidden="true"> &rarr;</span>
				</a>
			</div>
		</div>
	);
};

export default function Preview() {
	const { t } = useTranslation();
	const [brands, setBrands] = useState([]);
	const [categories, setCategories] = useState([]);

	useEffect(() => {
		const fetchBrands = async () => {
			const sellers = await infoService.getSellers();
			const mappedBrands = sellers.map((seller) => ({
				id: seller.sellerId,
				name: seller.name,
				imageUrl: seller.imageUrl || "https://via.placeholder.com/400x300",
				description: seller.description,
				alt: `${t("preview.image_of")} ${seller.name}`,
			}));
			setBrands(mappedBrands);
		};

		const fetchCategories = async () => {
			const categories = await infoService.getCategories();
			const mappedCategories = categories.map((category) => ({
				id: category.categoryId,
				name: category.name,
				imageUrl: category.imageUrl || "https://via.placeholder.com/400x300",
				description: category.parentCategory?.name,
				alt: `${t("preview.image_of")} ${category.name}`,
			}));
			setCategories(mappedCategories);
		};

		fetchBrands();
		fetchCategories();
	}, [t]);

	return (
		<div className="bg-gray-50">
			<Section title={t("preview.shop_by_brand")} data={brands} type="brand" />
			<Section title={t("preview.shop_by_category")} data={categories} type="category" />
		</div>
	);
}
