import React, { useEffect, useState } from "react";
import authClient from "apis/auth";
import { useTranslation } from "react-i18next";
import PageWithBackButton from "./PageWithBackButton";

const API_URL = "/user/user-addresses";

interface Address {
	addressId: number;
	address: string;
	city: string;
	state: string;
	postalCode: string;
	name: string;
	phoneNumber: string;
	isDefault: boolean;
	createdAt: string;
	updatedAt: string;
}

const AddressPage: React.FC = () => {
	const { t } = useTranslation();
	const [addresses, setAddresses] = useState<Address[]>([]);
	const [loading, setLoading] = useState(true);
	const [selectedAddress, setSelectedAddress] = useState<Address | null>(null);
	const [isModalOpen, setIsModalOpen] = useState(false);

	// 배송지 목록 불러오기
	const fetchAddresses = async () => {
		try {
			const response = await authClient.get(API_URL);
			if (response.data.success) {
				setAddresses(response.data.data);
			}
		} catch (error) {
			console.error(t("address.fetch_error"), error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchAddresses();
	}, []);

	// 배송지 추가 또는 수정
	const handleSaveAddress = async (newAddress: Address) => {
		try {
			if (newAddress.addressId) {
				// 수정
				await authClient.put(`${API_URL}/${newAddress.addressId}`, newAddress);
			} else {
				// 추가
				await authClient.post(API_URL, newAddress);
			}
			fetchAddresses();
			setIsModalOpen(false);
		} catch (error) {
			console.error(t("address.save_error"), error);
		}
	};

	// 배송지 삭제
	const handleDeleteAddress = async (addressId: number) => {
		if (!window.confirm(t("address.confirm_delete"))) return;
		try {
			await authClient.delete(`${API_URL}/${addressId}`);
			fetchAddresses();
		} catch (error) {
			console.error(t("address.delete_error"), error);
		}
	};

	// 기본 배송지 설정
	const handleSetDefault = async (addressId: number) => {
		try {
			await authClient.put(`${API_URL}/${addressId}`, { isDefault: true });
			fetchAddresses();
		} catch (error) {
			console.error(t("address.set_default_error"), error);
		}
	};

	return (
		<PageWithBackButton>
			<div>
				<h2 className="text-2xl font-bold mb-4">{t("address.title")}</h2>

				{loading ? (
					<p>{t("common.loading")}</p>
				) : addresses.length === 0 ? (
					<p className="text-gray-500">{t("address.no_address")}</p>
				) : (
					<div className="space-y-4">
						{addresses.map((address) => (
							<div key={address.addressId} className="p-4 border rounded-lg shadow hover:shadow-lg transition">
								<div className="flex justify-between items-center">
									<div>
										<p className="font-bold">{address.address}</p>
										<p className="text-sm text-gray-600">
											{address.city}, {address.state} {address.postalCode}
										</p>
										<p className="text-sm text-gray-600">
											📍 {address.name} / 📞 {address.phoneNumber}
										</p>
										{address.isDefault && <span className="text-blue-500 text-sm font-semibold">{t("address.default")}</span>}
									</div>
									<div className="space-x-2">
										<button
											onClick={() => {
												setSelectedAddress(address);
												setIsModalOpen(true);
											}}
											className="text-blue-500 hover:text-blue-700">
											{t("common.edit")}
										</button>
										<button onClick={() => handleDeleteAddress(address.addressId)} className="text-red-500 hover:text-red-700">
											{t("common.delete")}
										</button>

										{!address.isDefault && (
											<button onClick={() => handleSetDefault(address.addressId)} className="text-green-500 hover:text-green-700">
												{t("address.set_default")}
											</button>
										)}
									</div>
								</div>
							</div>
						))}
					</div>
				)}

				{/* 배송지 추가 버튼 */}
				<button
					onClick={() => {
						setSelectedAddress(null);
						setIsModalOpen(true);
					}}
					className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-700">
					+ {t("address.add")}
				</button>

				{/* 배송지 추가/수정 모달 */}
				{isModalOpen && <AddressModal onClose={() => setIsModalOpen(false)} onSave={handleSaveAddress} initialAddress={selectedAddress} />}
			</div>
		</PageWithBackButton>
	);
};

// 배송지 추가/수정 모달 컴포넌트
const AddressModal = ({ onClose, onSave, initialAddress }: { onClose: () => void; onSave: (address: Address) => void; initialAddress: Address | null }) => {
	const [address, setAddress] = useState<Address>(
		initialAddress || {
			addressId: 0,
			address: "",
			city: "",
			state: "",
			postalCode: "",
			name: "",
			phoneNumber: "",
			isDefault: false,
			createdAt: "",
			updatedAt: "",
		}
	);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setAddress((prev) => ({ ...prev, [name]: value }));
	};

	const handleSubmit = () => {
		onSave(address);
	};

	return (
		<div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
			<div className="bg-white p-6 rounded-lg w-full max-w-md">
				<h3 className="text-lg font-semibold mb-4">{initialAddress ? "배송지 수정" : "새 배송지 추가"}</h3>
				<div className="space-y-4">
					<input
						type="text"
						name="name"
						value={address.name}
						onChange={handleChange}
						placeholder="수취인명"
						className="w-full p-2 border rounded-lg"
					/>
					<input
						type="text"
						name="phoneNumber"
						value={address.phoneNumber}
						onChange={handleChange}
						placeholder="핸드폰 번호"
						className="w-full p-2 border rounded-lg"
					/>
					<input
						type="text"
						name="address"
						value={address.address}
						onChange={handleChange}
						placeholder="주소"
						className="w-full p-2 border rounded-lg"
					/>
					<input type="text" name="city" value={address.city} onChange={handleChange} placeholder="도시" className="w-full p-2 border rounded-lg" />
					<input
						type="text"
						name="state"
						value={address.state}
						onChange={handleChange}
						placeholder="주/도"
						className="w-full p-2 border rounded-lg"
					/>
					<input
						type="text"
						name="postalCode"
						value={address.postalCode}
						onChange={handleChange}
						placeholder="우편번호"
						className="w-full p-2 border rounded-lg"
					/>
				</div>
				<div className="mt-6 flex justify-end gap-2">
					<button onClick={onClose} className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-700">
						취소
					</button>
					<button onClick={handleSubmit} className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-700">
						저장
					</button>
				</div>
			</div>
		</div>
	);
};

export default AddressPage;
