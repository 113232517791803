import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { infoService } from "services/info";
import { useTranslation } from "react-i18next";
import { ellipsisString } from "utils/strUtil";

interface Seller {
	sellerId: number;
	name: string;
	logoUrl: string;
	description: string;
}

const BrandPage: React.FC = () => {
	const [sellers, setSellers] = useState<Seller[]>([]);
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();
	const { t } = useTranslation();

	useEffect(() => {
		const fetchSellers = async () => {
			try {
				const sellers = await infoService.getRandomSellers();
				if (sellers) {
					setSellers(sellers);
				}
			} catch (error) {
				console.error(t("brand.fetch_error"), error);
			} finally {
				setLoading(false);
			}
		};

		fetchSellers();
	}, [t]);

	const truncateDescription = (desc: string) => {
		if (!desc) return t("brand.no_description");
		return ellipsisString(desc, 200);
	};

	return (
		<div className="container mx-auto p-6">
			<h2 className="text-2xl font-bold mb-6">{t("brand.page_title")}</h2>

			{loading ? (
				<p className="text-gray-500">{t("common.loading")}</p>
			) : (
				<ul role="list" className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
					{sellers.map((seller) => (
						<li key={seller.sellerId} className="relative">
							<div
								onClick={() => navigate(`/brand/${seller.sellerId}`)}
								className="group cursor-pointer overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
								<img
									alt={seller.name}
									src={seller.logoUrl || "https://via.placeholder.com/300"}
									className="pointer-events-none aspect-[10/7] object-cover group-hover:opacity-75"
								/>
								<button type="button" className="absolute inset-0 focus:outline-none">
									<span className="sr-only">{t("brand.view_details", { name: seller.name })}</span>
								</button>
							</div>
							<div className="mt-2 text-center">
								<p className="pointer-events-none block text-sm font-medium text-gray-900">{seller.name}</p>
								<p className="pointer-events-none block text-xs text-gray-600 mt-1">{truncateDescription(seller.description)}</p>
							</div>
						</li>
					))}
				</ul>
			)}
		</div>
	);
};

export default BrandPage;
