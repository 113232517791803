import authClient from "apis/auth";
import { commonClient } from "apis/common";

class AuthService {
	login = async (email: string, password: string) => {
		const response = await commonClient.post("/auth/login", { email, password });
		const { access_token, refresh_token } = response.data.data;

		localStorage.setItem("access_token", access_token);
		localStorage.setItem("refresh_token", refresh_token);

		return response.data;
	};

	signup = async (email: string, password: string, name: string, phoneNumber: string) => {
		const response = await commonClient.post("/signup", {
			email,
			password,
			name,
			phoneNumber,
		});
		return response.data;
	};

	sendVerificationCode = async (email: string) => {
		const response = await commonClient.post("/send-verification-code", { email });
		return response.data;
	};

	verifyCode = async (email: string, code: string) => {
		const response = await commonClient.post("/verify-code", { email, code });
		return response.data;
	};

	checkEmailAvailability = async (email: string) => {
		const response = await commonClient.post("/check-email", { email });
		return response.data;
	};

	getCurrentUser = async () => {
		const response = await authClient.get("/user/profile/me");
		return response.data.data;
	};

	logout = () => {
		localStorage.removeItem("access_token");
		localStorage.removeItem("refresh_token");
		window.location.href = "/login"; // Redirect to login page
	};

	lineLogin = async (code: string) => {
		const response = await commonClient.post("/auth/line-login", { code });
		const { accessToken, isNewUser, email } = response.data?.data;

		localStorage.setItem("access_token", accessToken);

		return { accessToken, isNewUser, email };
	};

	signupWithLine = async (email: string, name: string) => {
		const response = await commonClient.post("/auth/signup/line", { email, name });
		return response.data;
	};

	googleLogin = async (code: string) => {
		const response = await commonClient.post("/auth/google-login", { code });
		const { accessToken, isNewUser, email } = response.data?.data;

		localStorage.setItem("access_token", accessToken);

		return { accessToken, isNewUser, email };
	};

	signupWithGoogle = async (email: string, name: string) => {
		const response = await commonClient.post("/auth/signup/google", { email, name });
		return response.data;
	};

	appleLogin = async (code: string) => {
		const response = await commonClient.post("/auth/apple-login", { code });
		const { accessToken, isNewUser, email } = response.data?.data;

		localStorage.setItem("access_token", accessToken);

		return { accessToken, isNewUser, email };
	};

	signupWithApple = async (email: string, name: string) => {
		const response = await commonClient.post("/auth/signup/apple", { email, name });
		return response.data;
	};
}

export const authService = new AuthService();
