import { Dialog, DialogPanel, Popover, PopoverButton, PopoverGroup, PopoverPanel } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Bars3Icon, ShoppingCartIcon, UserIcon, XMarkIcon } from "@heroicons/react/24/outline";
import logo from "assets/logo/logo-no-background.png";
import { useUser } from "contexts/UserProvider";
import LanguageSwitcher from "pages/LanguageSwitcher";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { infoService } from "services/info";

const currencies = ["CAD", "USD", "AUD", "EUR", "GBP"];

export const PublicTopNav = () => {
	const [open, setOpen] = useState(false);
	const [brands, setBrands] = useState([]);
	const [categories, setCategories] = useState([]);
	const navigate = useNavigate();
	const { user } = useUser();

	useEffect(() => {
		setBrands(
			infoService.getRandomSellers().map((seller) => ({
				name: seller.name,
				to: `/brand/${seller.sellerId}`,
			}))
		);
		setCategories(
			infoService.getCategories().map((category) => ({
				name: category.name,
				to: `/category/${category.categoryId}`,
			}))
		);
	}, []);

	const handleAccountClick = () => {
		if (user) {
			navigate("/mypage");
		} else {
			navigate("/login");
		}
	};

	return (
		<div className="bg-white">
			{/* Mobile menu */}
			<Dialog open={open} onClose={setOpen} className="relative z-40 lg:hidden">
				<div className="fixed inset-0 z-40 flex">
					<DialogPanel className="relative flex w-full max-w-xs transform flex-col overflow-y-auto bg-white pb-12 shadow-xl transition duration-300 ease-in-out data-[closed]:-translate-x-full">
						<div className="flex px-4 pb-2 pt-5">
							<button
								type="button"
								onClick={() => setOpen(false)}
								className="-m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400">
								<span className="sr-only">Close menu</span>
								<XMarkIcon aria-hidden="true" className="h-6 w-6" />
							</button>
						</div>

						<div className="space-y-6 border-b border-gray-200 px-4 py-6">
							<div className="flow-root">
								<a
									className="-m-2 block p-2 font-medium text-gray-900 cursor-pointer"
									onClick={() => {
										navigate("/preview");
										setOpen(false);
									}}>
									Preview
								</a>
							</div>
						</div>

						<div className="space-y-6 px-4 py-6">
							<div>
								<p
									className="font-medium text-gray-900 cursor-pointer"
									onClick={() => {
										navigate("/brand");
									}}>
									Brand
								</p>
								<ul role="list" className="mt-6 space-y-6">
									{brands.map((item) => (
										<li key={item.name} className="flex">
											<a
												className="text-gray-500 cursor-pointer"
												onClick={() => {
													navigate(item.to);
													setOpen(false);
												}}>
												{item.name}
											</a>
										</li>
									))}
								</ul>
							</div>
							<div>
								<p
									className="font-medium text-gray-900 cursor-pointer"
									onClick={() => {
										navigate("/category");
									}}>
									Category
								</p>
								<ul role="list" className="mt-6 space-y-6">
									{categories.map((item) => (
										<li key={item.name} className="flex">
											<a
												className="text-gray-500 cursor-pointer"
												onClick={() => {
													navigate(item.to);
													setOpen(false);
												}}>
												{item.name}
											</a>
										</li>
									))}
								</ul>
							</div>
						</div>

						{!user && (
							<div className="space-y-6 border-t border-gray-200 px-4 py-6">
								<div className="flow-root">
									<a href="/signup" className="-m-2 block p-2 font-medium text-gray-900">
										Create an account
									</a>
								</div>
								<div className="flow-root">
									<a href="/login" className="-m-2 block p-2 font-medium text-gray-900">
										Sign in
									</a>
								</div>
							</div>
						)}

						<div className="space-y-6 border-t border-gray-200 px-4 py-6">
							<form>
								<div className="inline-block">
									<LanguageSwitcher></LanguageSwitcher>
									{/* <label htmlFor="mobile-currency" className="sr-only">
										Currency
									</label>
									<div className="group relative -ml-2 rounded-md border-transparent focus-within:ring-2 focus-within:ring-white">
										<select
											id="mobile-currency"
											name="currency"
											className="flex items-center rounded-md border-transparent bg-none py-0.5 pl-2 pr-5 text-sm font-medium text-gray-700 focus:border-transparent focus:outline-none focus:ring-0 group-hover:text-gray-800">
											{currencies.map((currency) => (
												<option key={currency}>{currency}</option>
											))}
										</select>
										<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
											<ChevronDownIcon aria-hidden="true" className="h-5 w-5 text-gray-500" />
										</div>
									</div> */}
								</div>
							</form>
						</div>
					</DialogPanel>
				</div>
			</Dialog>

			<header className="relative">
				<nav aria-label="Top">
					<div className="bg-white">
						<div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
							<div className="border-b border-gray-200">
								<div className="flex h-16 items-center justify-between">
									<div className="hidden lg:flex lg:items-center">
										<a onClick={() => navigate("/")} className="cursor-pointer">
											<span className="sr-only">Your Company</span>
											<img alt="" src={logo} className="h-16 w-auto" />
										</a>
									</div>

									<div className="hidden h-full lg:flex">
										<PopoverGroup className="ml-8">
											<div className="flex h-full justify-center space-x-8">
												<div
													className="flex items-center text-sm font-medium text-gray-700 hover:text-gray-800 cursor-pointer"
													onClick={() => navigate("/preview")}>
													Preview
												</div>

												<Popover className="flex">
													<div className="relative flex">
														<PopoverButton className="relative z-10 -mb-px flex items-center border-b-2 border-transparent pt-px text-sm font-medium text-gray-700 transition-colors duration-200 ease-out hover:text-gray-800 data-[open]:border-indigo-600 data-[open]:text-rose-400">
															Brand
														</PopoverButton>
													</div>

													<PopoverPanel className="absolute inset-x-0 top-full text-gray-500 sm:text-sm z-[100]">
														{({ close }) => (
															<div>
																<div aria-hidden="true" className="absolute inset-0 top-1/2 bg-white shadow" />
																<div className="relative bg-white">
																	<div className="mx-auto max-w-7xl px-8">
																		<div className="grid grid-cols-4 gap-4 py-6">
																			{brands.map((brand) => (
																				<div key={brand.name}>
																					<a
																						className="block text-gray-900 hover:text-gray-800 cursor-pointer hover:bg-gray-50 px-4 py-2 rounded-md"
																						onClick={() => {
																							navigate(brand.to);
																							close();
																						}}>
																						{brand.name}
																					</a>
																				</div>
																			))}
																		</div>
																	</div>
																</div>
															</div>
														)}
													</PopoverPanel>
												</Popover>

												<Popover className="flex">
													<div className="relative flex">
														<PopoverButton className="relative z-10 -mb-px flex items-center border-b-2 border-transparent pt-px text-sm font-medium text-gray-700 transition-colors duration-200 ease-out hover:text-gray-800 data-[open]:border-indigo-600 data-[open]:text-rose-400">
															Category
														</PopoverButton>
													</div>

													<PopoverPanel className="absolute inset-x-0 top-full text-gray-500 sm:text-sm z-[100]">
														{({ close }) => (
															<div>
																<div aria-hidden="true" className="absolute inset-0 top-1/2 bg-white shadow" />
																<div className="relative bg-white">
																	<div className="mx-auto max-w-7xl px-8">
																		<div className="grid grid-cols-4 gap-4 py-6">
																			{categories.map((category) => (
																				<div key={category.name}>
																					<a
																						className="block text-gray-900 hover:text-gray-800 cursor-pointer hover:bg-gray-50 px-4 py-2 rounded-md"
																						onClick={() => {
																							navigate(category.to);
																							close();
																						}}>
																						{category.name}
																					</a>
																				</div>
																			))}
																		</div>
																	</div>
																</div>
															</div>
														)}
													</PopoverPanel>
												</Popover>
												<Popover className="flex ml-auto">
													<div className="relative z-10 -mb-px flex items-center border-b-2 border-transparent pt-px text-sm font-medium text-gray-700 transition-colors duration-200 ease-out hover:text-gray-800 data-[open]:border-indigo-600 data-[open]:text-rose-400">
														<LanguageSwitcher></LanguageSwitcher>
													</div>
												</Popover>
											</div>
										</PopoverGroup>
									</div>

									<div className="flex flex-1 items-center lg:hidden">
										<button type="button" onClick={() => setOpen(true)} className="-ml-2 rounded-md bg-white p-2 text-gray-400">
											<span className="sr-only">Open menu</span>
											<Bars3Icon aria-hidden="true" className="h-6 w-6" />
										</button>

										{/* <a className="ml-2 p-2 text-gray-400 hover:text-gray-500">
											<span className="sr-only">Search</span>
											<MagnifyingGlassIcon aria-hidden="true" className="h-6 w-6" />
										</a> */}
									</div>

									<a onClick={() => navigate("/")} className="lg:hidden cursor-pointer">
										<span className="sr-only">Your Company</span>
										<img alt="" src={logo} className="h-16 w-auto" />
									</a>

									<div className="flex flex-1 items-center justify-end">
										<div className="flex items-center lg:ml-8">
											<div className="flex space-x-8">
												<div className="flex">
													<a className="-m-2 p-2 text-gray-400 hover:text-gray-500 cursor-pointer" onClick={handleAccountClick}>
														<span className="sr-only">Account</span>
														<UserIcon aria-hidden="true" className="h-6 w-6" />
													</a>
												</div>
											</div>

											<span aria-hidden="true" className="mx-4 h-6 w-px bg-gray-200 lg:mx-6" />

											<div className="flow-root">
												<a className="group -m-2 flex items-center p-2 cursor-pointer" onClick={() => navigate("/order/shopping-cart")}>
													<ShoppingCartIcon
														aria-hidden="true"
														className="h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
													/>
													<span className="sr-only">장바구니 보기</span>
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</nav>
			</header>
		</div>
	);
};

export default PublicTopNav;
