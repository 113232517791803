import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import authClient from "apis/auth";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import PageWithBackButton from "./PageWithBackButton";
import { authService } from "services/authService";

const ProfilePage: React.FC = () => {
	const { t } = useTranslation();
	const [user, setUser] = useState<{ name: string; email: string; phoneNumber: string } | null>(null);
	const navigate = useNavigate();

	useEffect(() => {
		fetchUserData();
	}, []);

	const fetchUserData = async () => {
		try {
			const response = await authClient.get("/user/profile/me");
			setUser(response.data.data);
		} catch (error) {
			console.error(t("profile.fetch_error"), error);
			toast.error(t("profile.fetch_error"));
		}
	};

	const handleLogout = async () => {
		try {
			// TODO api 추가 필요
			// await authClient.post("/auth/logout"); // 로그아웃 API 호출
			authService.logout();
			toast.success(t("profile.logout_success"));
			navigate("/login");
		} catch (error) {
			toast.error(t("profile.logout_error"));
		}
	};

	if (!user) {
		return <p className="text-center text-gray-600">{t("common.loading")}</p>;
	}

	return (
		<PageWithBackButton>
			<div className="max-w-lg mx-auto bg-white shadow-lg rounded-lg p-6">
				<h2 className="text-2xl font-bold text-gray-900 text-center">{t("profile.title")}</h2>

				<div className="mt-4">
					<p className="text-lg text-gray-700 font-medium">{t("profile.name")}</p>
					<p className="text-gray-600">{user.name}</p>
				</div>

				<div className="mt-4">
					<p className="text-lg text-gray-700 font-medium">{t("profile.email")}</p>
					<p className="text-gray-600">{user.email}</p>
				</div>

				<div className="mt-4">
					<p className="text-lg text-gray-700 font-medium">{t("profile.phone")}</p>
					<p className="text-gray-600">{user.phoneNumber || t("profile.no_phone")}</p>
				</div>

				{/* 버튼 그룹 */}
				<div className="mt-6 space-y-3">
					<button
						onClick={() => navigate("/change-password")}
						className="w-full bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 rounded-lg transition">
						{t("profile.change_password")}
					</button>

					<button onClick={handleLogout} className="w-full bg-red-500 hover:bg-red-600 text-white font-medium py-2 rounded-lg transition">
						{t("profile.logout")}
					</button>
				</div>
			</div>
		</PageWithBackButton>
	);
};

export default ProfilePage;
