import useDeviceDetect from "hook/useDeviceDetect";
import React from "react";
import MyPageLayoutForMobile from "./MyPageLayoutForMobile";
import ProfilePage from "./ProfilePage";

export default function MyPage() {
	const { isMobile } = useDeviceDetect(); // 모바일/PC 감지

	return isMobile ? <MyPageLayoutForMobile /> : <ProfilePage />;
}
