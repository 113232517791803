import React from "react";
import { Route, Routes } from "react-router-dom";

import CategoryPage from "pages/Category";
import CategoryDetailPage from "pages/Category/detail";

export default () => (
	<Routes>
		<Route index element={<CategoryPage />}></Route>
		<Route path=":categoryId" element={<CategoryDetailPage />}></Route>
	</Routes>
);
