import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { authService } from "services/authService";
import { LINE_CLIENT_ID, LINE_REDIRECT_URI } from "constants/config";

import lineIcon from "assets/icon/line-icon.svg";
import googleIcon from "assets/icon/google-icon.svg";
import appleIcon from "assets/icon/apple-icon.svg";

const Login: React.FC = () => {
	const { t } = useTranslation();
	const [email, setEmail] = useState<string>("");
	const [password, setPassword] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(false);
	const navigate = useNavigate();
	const location = useLocation();

	const from = location.state?.from?.pathname || "/";

	useEffect(() => {
		// OAuth 인증 후 라인 로그인 코드가 있는 경우 처리
		const params = new URLSearchParams(location.search);
		const lineCode = params.get("code");

		if (lineCode) {
			handleLineLogin(lineCode);
		}
	}, [location.search]);

	const handleLineLogin = async (code: string) => {
		setLoading(true);
		try {
			const response = await authService.lineLogin(code);
			if (response.accessToken) {
				localStorage.setItem("access_token", response.accessToken);
				window.location.href = "/"; // 로그인 성공 후 메인 페이지로 이동
			}
		} catch (error) {
			console.error("Line login failed:", error);
			alert(t("login.failed"));
		} finally {
			setLoading(false);
		}
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		setLoading(true);

		try {
			const response = await authService.login(email, password);
			if (response.status === 201 || response.statusCode === 201) {
				window.location.href = "/";
			}
		} catch (error) {
			console.error("Login failed:", error);
			alert(t("login.failed"));
		} finally {
			setLoading(false);
		}
	};

	const handleSocialLogin = (provider: string) => {
		if (provider === "line") {
			const LINE_LOGIN_URL = "https://access.line.me/oauth2/v2.1/authorize?response_type=code&scope=profile%20openid%20phone%20profile%20email";
			// const LINE_STATE = "39f";
			const LINE_STATE = Math.random().toString(36).substring(8);
			const lineLoginUrl = `${LINE_LOGIN_URL}&client_id=${LINE_CLIENT_ID}&redirect_uri=${LINE_REDIRECT_URI}&state=${LINE_STATE}`;
			window.location.href = lineLoginUrl;
		} else if (provider === "google") {
			const GOOGLE_LOGIN_URL = "https://accounts.google.com/o/oauth2/auth?response_type=code&scope=email%20profile&access_type=offline";
			const googleLoginUrl = `${GOOGLE_LOGIN_URL}&client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_GOOGLE_REDIRECT_URI}`;
			window.location.href = googleLoginUrl;
		} else if (provider === "apple") {
			const APPLE_LOGIN_URL = "https://appleid.apple.com/auth/authorize?response_type=code&scope=email%20name";
			const appleLoginUrl = `${APPLE_LOGIN_URL}&client_id=${process.env.REACT_APP_APPLE_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_APPLE_REDIRECT_URI}`;
			window.location.href = appleLoginUrl;
		}
	};

	return (
		<div className="min-h-screen flex items-center justify-center bg-gray-50">
			<div className="max-w-md w-full bg-white p-8 shadow rounded-md">
				<h2 className="text-2xl font-bold text-center mb-4">{t("login.title")}</h2>
				<form onSubmit={handleSubmit} className="space-y-4">
					<div>
						<label htmlFor="email" className="block text-sm font-medium text-gray-700">
							{t("login.email")}
						</label>
						<input
							type="email"
							id="email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							required
							className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-sm focus:ring-indigo-500 focus:border-indigo-500"
						/>
					</div>
					<div>
						<label htmlFor="password" className="block text-sm font-medium text-gray-700">
							{t("login.password")}
						</label>
						<input
							type="password"
							id="password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							required
							className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-sm focus:ring-indigo-500 focus:border-indigo-500"
						/>
					</div>
					<button
						type="submit"
						className="w-full bg-rose-400 text-white py-2 px-4 rounded-md hover:bg-rose-500 disabled:opacity-50"
						disabled={loading}>
						{loading ? t("login.loading") : t("login.submit")}
					</button>
				</form>

				<div className="mt-6">
					<div className="relative">
						<div aria-hidden="true" className="absolute inset-0 flex items-center">
							<div className="w-full border-t border-gray-300" />
						</div>
						<div className="relative flex justify-center text-sm">
							<span className="bg-white px-2 text-gray-500">{t("login.or_social")}</span>
						</div>
					</div>

					<div className="mt-6 grid grid-cols-2 gap-4">
						<button
							onClick={() => handleSocialLogin("line")}
							className="flex w-full items-center justify-center gap-3 rounded-md bg-green-500 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-600">
							<img src={lineIcon} alt="Line Icon" className="h-5 w-5" />
							{t("login.line")}
						</button>

						<button
							onClick={() => handleSocialLogin("google")}
							className="flex w-full items-center justify-center gap-3 rounded-md bg-black px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-800">
							<img src={googleIcon} alt="Google Icon" className="h-5 w-5" />
							{t("login.google")}
						</button>

						<button
							onClick={() => handleSocialLogin("apple")}
							className="flex w-full items-center justify-center gap-3 rounded-md bg-black px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-800">
							<img src={appleIcon} alt="Apple Icon" className="h-5 w-5" />
							{t("login.apple")}
						</button>
					</div>
				</div>

				<p className="text-sm text-center text-gray-500 mt-4">
					{t("login.no_account")}{" "}
					<a href="/signup" className="text-rose-400 hover:text-rose-300">
						{t("login.signup")}
					</a>
				</p>
			</div>
		</div>
	);
};

export default Login;
