import { commonClient } from "apis/common";
import SpinnerMedium from "components/Spinner/Medium";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CategoryPage = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [categories, setCategories] = useState([]);
	const [loading, setLoading] = useState(true);

	// 최상위 카테고리 불러오기
	useEffect(() => {
		const fetchCategories = async () => {
			try {
				const res = await commonClient.get(`/categories/root`);
				setCategories(res.data.data.items);
			} catch (error) {
				console.error(t("category.fetch_error"), error);
			} finally {
				setLoading(false);
			}
		};

		fetchCategories();
	}, []);

	if (loading) {
		return <SpinnerMedium />;
	}

	return (
		<div className="bg-white">
			<div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
				<h2 className="text-3xl font-bold tracking-tight text-gray-900 mb-6">{t("category.page_title")}</h2>

				{/* 최상위 카테고리 리스트 */}
				<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-x-6 gap-y-10">
					{categories.map((category) => (
						<CategoryTree key={category.categoryId} category={category} navigate={navigate} />
					))}
				</div>
			</div>
		</div>
	);
};

// ✅ 트리 구조의 카테고리를 렌더링하는 컴포넌트
const CategoryTree = ({ category, navigate }) => {
	const { t } = useTranslation();
	const [expanded, setExpanded] = useState(false);
	const [subCategories, setSubCategories] = useState(category.subCategories || []);
	const [loading, setLoading] = useState(false);

	// 하위 카테고리 로드
	const handleExpand = async () => {
		if (subCategories.length > 0) {
			setExpanded(!expanded);
			return;
		}

		try {
			setLoading(true);
			const res = await commonClient.get(`/categories/sub/${category.categoryId}`);
			setSubCategories(res.data.data.items);
			setExpanded(true);
		} catch (error) {
			console.error(t("category.fetch_sub_error"), error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="group relative">
			{/* ✅ 이미지 클릭 시 상품보기 페이지 이동 추가 ✅ */}
			<img
				alt={category.name}
				src={category.imageUrl || "https://via.placeholder.com/150"}
				className="aspect-[2/1] w-full rounded-md bg-gray-200 object-cover group-hover:opacity-75 cursor-pointer"
				onClick={() => navigate(`/category/${category.categoryId}`)}
			/>

			{/* 카테고리 정보 */}
			<div className="mt-2 flex justify-between items-center">
				<h3 className="text-sm text-gray-700">{category.name}</h3>
				<div className="flex gap-2">
					<button
						onClick={() => navigate(`/category/${category.categoryId}`)}
						className="text-sm bg-blue-500 text-white px-3 py-1 rounded-lg hover:bg-blue-700">
						{t("category.view_products")} →
					</button>
					{category.subCategories?.length > 0 && (
						<button onClick={handleExpand} className="text-gray-500 text-sm">
							{expanded ? t("category.collapse") : t("category.expand")}
						</button>
					)}
				</div>
			</div>

			{/* 하위 카테고리 로딩 */}
			{loading && <SpinnerMedium />}

			{/* 하위 카테고리 표시 */}
			{expanded && subCategories.length > 0 && (
				<div className="mt-4 space-y-4">
					{subCategories.map((subCategory) => (
						<CategoryTree key={subCategory.categoryId} category={subCategory} navigate={navigate} />
					))}
					{/* 하위 카테고리 마지막에 접기 버튼 추가 */}
					<button onClick={() => setExpanded(false)} className="text-gray-500 text-sm">
						{t("category.collapse")}
					</button>
				</div>
			)}
		</div>
	);
};

export default CategoryPage;
