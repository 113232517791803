import React from "react";
import { useTranslation } from "react-i18next";

const TermsPage: React.FC = () => {
	const { t } = useTranslation();

	return (
		<div className="bg-white py-10 px-6 sm:px-10 lg:px-20">
			<div className="max-w-4xl mx-auto">
				<h1 className="text-3xl font-bold text-gray-900">{t("terms.title")}</h1>
				<p className="text-sm text-gray-600 mt-2">{t("terms.last_updated")}</p>

				<div className="mt-6">
					{/* 전체 약관 내용을 하나의 텍스트 블록으로 표시 */}
					<div className="text-gray-700 whitespace-pre-line">{t("terms.full_content")}</div>
				</div>
			</div>
		</div>
	);
};

export default TermsPage;
