"use client";

import { commonClient } from "apis/common";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const FrontPage: React.FC = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [categories, setCategories] = useState([]);
	const [collections, setCollections] = useState([]);
	const [banners, setBanners] = useState([]);
	const [currentIndex, setCurrentIndex] = useState(0);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const categoryRes = await commonClient.get("/categories"); // 카테고리 API
				const bannerRes = await commonClient.get("/banners/active"); // 활성화된 배너 API
				setCategories(categoryRes.data.data.items);
				setBanners(bannerRes.data.data); // 배너 데이터 저장
			} catch (error) {
				console.error("데이터 로드 실패:", error);
			}
		};

		fetchData();
	}, []);

	// 3초마다 자동으로 배너 변경
	useEffect(() => {
		if (banners.length > 1) {
			const interval = setInterval(() => {
				setCurrentIndex((prevIndex) => (prevIndex + 1) % banners.length);
			}, 3000);
			return () => clearInterval(interval);
		}
	}, [banners]);

	// 배너 수동 변경 함수
	const nextBanner = () => {
		setCurrentIndex((prevIndex) => (prevIndex + 1) % banners.length);
	};

	const prevBanner = () => {
		setCurrentIndex((prevIndex) => (prevIndex - 1 + banners.length) % banners.length);
	};

	return (
		<div className="bg-white">
			{/* Hero Section */}
			<div className="relative bg-gray-900">
				{banners.length > 0 && (
					<>
						<div aria-hidden="true" className="absolute inset-0 overflow-hidden transition-opacity duration-1000 ease-in-out">
							<img alt={banners[currentIndex].title} src={banners[currentIndex].imageUrl} className="size-full object-cover" />
						</div>
						<div aria-hidden="true" className="absolute inset-0 bg-gray-900 opacity-50" />

						<div className="relative mx-auto flex max-w-3xl flex-col items-center px-6 py-32 text-center sm:py-64 lg:px-0">
							<h1 className="text-4xl font-bold tracking-tight text-white lg:text-6xl">{banners[currentIndex].title}</h1>
							<p className="mt-4 text-xl text-white">{banners[currentIndex].description}</p>
							{banners[currentIndex].buttonLink && banners[currentIndex].buttonText && (
								<button
									onClick={() => navigate(banners[currentIndex].buttonLink)}
									className="mt-8 inline-block rounded-md border border-transparent bg-white px-8 py-3 text-base font-medium text-gray-900 hover:bg-gray-100">
									{banners[currentIndex].buttonText}
								</button>
							)}
						</div>

						{/* 배너 변경 버튼 */}
						{banners.length > 1 && (
							<>
								<button
									onClick={prevBanner}
									className="absolute left-4 top-1/2 -translate-y-1/2 text-white bg-gray-800 bg-opacity-50 p-2 rounded-full">
									&#8592;
								</button>
								<button
									onClick={nextBanner}
									className="absolute right-4 top-1/2 -translate-y-1/2 text-white bg-gray-800 bg-opacity-50 p-2 rounded-full">
									&#8594;
								</button>
							</>
						)}
					</>
				)}
			</div>

			<main>
				{/* Category Section */}
				<section aria-labelledby="category-heading" className="pt-24 sm:pt-32 xl:mx-auto xl:max-w-7xl xl:px-8">
					<div className="px-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8 xl:px-0">
						<h2 id="category-heading" className="text-2xl font-bold tracking-tight text-gray-900">
							{t("frontpage.shop_by_category")}
						</h2>
						<button onClick={() => navigate("/category")} className="hidden text-sm font-semibold text-rose-400 hover:text-rose-300 sm:block">
							{t("frontpage.browse_all_categories")} &rarr;
						</button>
					</div>

					<div className="mt-4">
						<div className="overflow-x-auto scrollbar-hide">
							<div className="flex space-x-8 px-4 sm:px-6 lg:px-8 xl:px-0 min-w-max">
								{categories.map((category) => (
									<button
										key={category.id}
										onClick={() => navigate(`/category/${category.categoryId}`)}
										className="relative flex h-80 w-56 flex-col overflow-hidden rounded-lg p-6 hover:opacity-75">
										<span aria-hidden="true" className="absolute inset-0">
											<img alt={category.name} src={category.imageUrl} className="size-full object-cover" />
										</span>
										<span aria-hidden="true" className="absolute inset-x-0 bottom-0 h-2/3 bg-gradient-to-t from-gray-800 opacity-50" />
										<span className="relative mt-auto text-center text-xl font-bold text-white">{category.name}</span>
									</button>
								))}
							</div>
						</div>
					</div>
				</section>

				<section className="pt-24 sm:pt-32 xl:mx-auto xl:max-w-7xl xl:px-8"></section>

				{/* Collection Section */}
				{/* <section aria-labelledby="collection-heading" className="mx-auto max-w-xl px-4 pt-24 sm:px-6 sm:pt-32 lg:max-w-7xl lg:px-8">
					<h2 id="collection-heading" className="text-2xl font-bold tracking-tight text-gray-900">
						Shop by Collection
					</h2>
					<p className="mt-4 text-base text-gray-500">
						Each season, we collaborate with world-class designers to create a collection inspired by the natural world.
					</p>

					<div className="mt-10 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:space-y-0">
						{collections.map((collection) => (
							<button key={collection.id} onClick={() => navigate(`/collections/${collection.id}`)} className="group block">
								<img
									alt={collection.name}
									src={collection.imageUrl}
									className="aspect-[3/2] w-full rounded-lg object-cover group-hover:opacity-75 lg:aspect-[5/6]"
								/>
								<h3 className="mt-4 text-base font-semibold text-gray-900">{collection.name}</h3>
								<p className="mt-2 text-sm text-gray-500">{collection.description}</p>
							</button>
						))}
					</div>
				</section> */}
			</main>
		</div>
	);
};

export default FrontPage;
