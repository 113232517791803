import { ChevronDownIcon, ChevronUpIcon, XMarkIcon } from "@heroicons/react/20/solid";
import authClient from "apis/auth";
import SpinnerMedium from "components/Spinner/Medium";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { formatCurrency } from "utils/strUtil";

// 인터페이스 정의 (응답 구조에 맞게 수정)
interface CartItem {
	cartItemId: number;
	quantity: number;
	product: {
		productId: number;
		name: string;
		description: string;
		mainImage: string;
		price: string; // 문자열로 응답이 오므로 string으로 유지
		seller: {
			sellerId: number;
			name: string;
			shippingPolicy: SellerShippingPolicy | null;
		};
	};
	productOptionValue: { value: string } | null;
}

interface SellerShippingPolicy {
	policyId: number;
	minimumOrderAmount: string; // 문자열로 응답이 오므로 string
	shippingFee: string;
	freeShippingThreshold: string;
	estimatedDeliveryTime?: string;
	returnPeriod?: number;
	returnConditions?: string;
	seller?: {
		sellerId: number;
		name: string;
	};
}

interface SellerGroup {
	sellerId: number;
	sellerName: string;
	items: CartItem[];
	shippingPolicy: SellerShippingPolicy;
}

const ShoppingCartPage: React.FC = () => {
	const { t, i18n } = useTranslation();
	const [sellerGroups, setSellerGroups] = useState<SellerGroup[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [updating, setUpdating] = useState<boolean>(false);
	const navigate = useNavigate();

	useEffect(() => {
		fetchCartData();
	}, []);

	const fetchCartData = async () => {
		try {
			setLoading(true);
			const response = await authClient.get(`/user/carts/item`, { params: { locale: i18n.language } });
			const { items, sellerPolicies } = response?.data?.data || { items: [], sellerPolicies: [] };

			// 셀러별로 그룹핑
			const groupedBySeller = items.reduce((acc: Record<number, SellerGroup>, item: CartItem) => {
				const sellerId = item.product.seller.sellerId;
				if (!acc[sellerId]) {
					// 우선 product.seller.shippingPolicy에서 정책을 확인
					let policy = item.product.seller.shippingPolicy;
					if (!policy) {
						// product에 정책이 없으면 sellerPolicies에서 매핑
						policy = sellerPolicies.find((p: SellerShippingPolicy) => p.seller?.sellerId === sellerId) || {
							policyId: -1,
							minimumOrderAmount: "0",
							shippingFee: "3000", // 기본값
							freeShippingThreshold: "50000", // 기본값
						};
					}
					console.log(policy);
					acc[sellerId] = {
						sellerId,
						sellerName: item.product.seller.name,
						items: [],
						shippingPolicy: policy,
					};
				}
				acc[sellerId].items.push(item);
				return acc;
			}, {});

			setSellerGroups(Object.values(groupedBySeller));
		} catch (e) {
			console.error(t("cart.fetch_error"), e);
			toast.error(t("cart.fetch_error"));
		} finally {
			setLoading(false);
		}
	};

	const handleQuantityChange = async (cartItemId: number, newQuantity: number) => {
		if (newQuantity < 1) return;
		try {
			setUpdating(true);
			await authClient.patch(`/user/carts/item/${cartItemId}/quantity`, { quantity: newQuantity });
			setSellerGroups((prev) =>
				prev.map((group) => ({
					...group,
					items: group.items.map((item) => (item.cartItemId === cartItemId ? { ...item, quantity: newQuantity } : item)),
				}))
			);
		} catch (e) {
			console.error(t("cart.update_quantity_error"), e);
			toast.error(t("cart.update_quantity_error"));
		} finally {
			setUpdating(false);
		}
	};

	const handleRemove = async (cartItemId: number) => {
		try {
			await authClient.delete(`/user/carts/item/${cartItemId}`);
			toast.success(t("cart.item_removed"));
			fetchCartData();
		} catch (e) {
			console.error(t("cart.remove_error"), e);
			toast.error(t("cart.remove_error"));
		}
	};

	const handleCheckout = () => {
		navigate("/order/checkout", { state: { sellerGroups } });
		window.scrollTo(0, 0);
	};

	if (loading) {
		return <SpinnerMedium />;
	}

	const calculateSubtotal = (items: CartItem[]) => items.reduce((acc, item) => acc + Number(item.product.price) * item.quantity, 0);

	const calculateShippingFee = (group: SellerGroup) => {
		const subtotal = calculateSubtotal(group.items);
		return subtotal >= Number(group.shippingPolicy.freeShippingThreshold) ? 0 : Number(group.shippingPolicy.shippingFee);
	};

	const totalPrice = sellerGroups.reduce((acc, group) => acc + calculateSubtotal(group.items) + calculateShippingFee(group), 0);

	return (
		<div className="bg-white min-h-screen">
			<div className="mx-auto max-w-2xl px-4 pb-24 pt-4 sm:px-6 lg:max-w-7xl lg:px-8">
				<h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{t("cart.title")}</h1>

				{sellerGroups.length === 0 ? (
					<div className="flex flex-col items-center justify-center mt-16">
						<p className="text-lg text-gray-600">{t("cart.empty")}</p>
						<button className="mt-6 rounded-md bg-rose-400 px-6 py-3 text-white hover:bg-rose-500" onClick={() => navigate("/")}>
							{t("cart.continue_shopping")}
						</button>
					</div>
				) : (
					<form className="mt-12 lg:grid lg:grid-cols-12 lg:items-start lg:gap-x-12 xl:gap-x-16">
						<section aria-labelledby="cart-heading" className="lg:col-span-7">
							<h2 id="cart-heading" className="sr-only">
								{t("cart.items_list")}
							</h2>
							{sellerGroups.map((group) => {
								const subtotal = calculateSubtotal(group.items);
								const shippingFee = calculateShippingFee(group);

								return (
									<div key={group.sellerId} className="mb-8 bg-gray-50 p-4 rounded-lg shadow-sm">
										<h3 className="text-lg font-semibold text-gray-900 mb-4">
											{group.sellerName} ({group.items.length} {t("cart.items")})
										</h3>
										<ul role="list" className="divide-y divide-gray-200">
											{group.items.map((cartItem) => (
												<li key={cartItem.cartItemId} className="flex py-6">
													<div className="flex-shrink-0">
														<img
															alt={cartItem.product.name}
															src={cartItem.product.mainImage}
															className="h-24 w-24 rounded-md object-cover object-center sm:h-48 sm:w-48"
														/>
													</div>
													<div className="ml-4 flex flex-1 flex-col justify-between sm:ml-6">
														<div className="relative pr-9 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:pr-0">
															<div>
																<h4 className="text-sm font-medium text-gray-700 hover:text-gray-800">
																	{cartItem.product.name}
																</h4>
																<p className="mt-1 text-sm text-gray-500">
																	{cartItem.product.description || t("cart.no_description")}
																</p>
																{cartItem.productOptionValue && (
																	<p className="mt-1 text-sm text-gray-700">
																		<strong>{t("cart.option")}</strong> {cartItem.productOptionValue.value}
																	</p>
																)}
																<p className="mt-1 text-sm font-medium text-gray-900">
																	{formatCurrency(Number(cartItem.product.price), i18n.language)}
																</p>
															</div>
															<div className="mt-4 sm:mt-0 sm:pr-9 flex items-center space-x-3">
																<button
																	className="p-1 rounded bg-gray-200 hover:bg-gray-300 disabled:opacity-50"
																	onClick={() => handleQuantityChange(cartItem.cartItemId, cartItem.quantity - 1)}
																	disabled={cartItem.quantity <= 1 || updating}>
																	<ChevronDownIcon className="h-5 w-5 text-gray-600" />
																</button>
																<span className="text-lg font-medium text-gray-900">{cartItem.quantity}</span>
																<button
																	className="p-1 rounded bg-gray-200 hover:bg-gray-300 disabled:opacity-50"
																	onClick={() => handleQuantityChange(cartItem.cartItemId, cartItem.quantity + 1)}
																	disabled={updating}>
																	<ChevronUpIcon className="h-5 w-5 text-gray-600" />
																</button>
																<div className="absolute right-0 top-0">
																	<button
																		type="button"
																		className="-m-2 inline-flex p-2 text-gray-400 hover:text-gray-500"
																		onClick={() => handleRemove(cartItem.cartItemId)}>
																		<XMarkIcon aria-hidden="true" className="h-5 w-5" />
																	</button>
																</div>
															</div>
														</div>
													</div>
												</li>
											))}
										</ul>
										<div className="mt-4 space-y-2">
											<p className="text-sm text-gray-600">
												{t("cart.subtotal")}: {formatCurrency(subtotal, i18n.language)}
											</p>
											<p className="text-sm text-gray-600">
												{t("cart.shipping_fee")}: {formatCurrency(shippingFee, i18n.language)}
												{shippingFee === 0 && <span className="text-green-600 ml-2">({t("cart.free_shipping")})</span>}
											</p>
											{/* {group.shippingPolicy.estimatedDeliveryTime && (
												<p className="text-sm text-gray-500">
													{t("cart.estimated_delivery")}: {group.shippingPolicy.estimatedDeliveryTime}
												</p>
											)}
											{group.shippingPolicy.returnConditions && (
												<p className="text-sm text-gray-500">
													{t("cart.return_conditions")}: {group.shippingPolicy.returnConditions}
												</p>
											)} */}
											{subtotal < Number(group.shippingPolicy.minimumOrderAmount) && (
												<p className="text-sm text-red-600">
													{t("cart.minimum_order_warning", {
														amount: formatCurrency(Number(group.shippingPolicy.minimumOrderAmount), i18n.language),
													})}
												</p>
											)}
										</div>
									</div>
								);
							})}
						</section>

						<section aria-labelledby="summary-heading" className="mt-16 lg:col-span-5 lg:mt-0">
							<h2 id="summary-heading" className="text-lg font-medium text-gray-900">
								{t("cart.order_summary")}
							</h2>
							<dl className="mt-6 space-y-4">
								{sellerGroups.map((group) => (
									<React.Fragment key={group.sellerId}>
										<div className="flex justify-between">
											<dt className="text-sm text-gray-600">
												{group.sellerName} {t("cart.subtotal")}
											</dt>
											<dd className="text-sm font-medium text-gray-900">
												{formatCurrency(calculateSubtotal(group.items), i18n.language)}
											</dd>
										</div>
										<div className="flex justify-between">
											<dt className="text-sm text-gray-600">
												{group.sellerName} {t("cart.shipping_fee")}
											</dt>
											<dd className="text-sm font-medium text-gray-900">{formatCurrency(calculateShippingFee(group), i18n.language)}</dd>
										</div>
									</React.Fragment>
								))}
								<div className="flex justify-between border-t border-gray-200 pt-4">
									<dt className="text-base font-medium text-gray-900">{t("cart.total")}</dt>
									<dd className="text-base font-medium text-gray-900">{formatCurrency(totalPrice, i18n.language)}</dd>
								</div>
							</dl>
							<div className="mt-6">
								<button
									className="w-full rounded-md bg-rose-400 px-4 py-3 text-white hover:bg-rose-500 disabled:bg-gray-400 disabled:cursor-not-allowed"
									onClick={handleCheckout}
									disabled={sellerGroups.some((group) => calculateSubtotal(group.items) < Number(group.shippingPolicy.minimumOrderAmount))}>
									{t("cart.checkout")}
								</button>
							</div>
						</section>
					</form>
				)}
			</div>
		</div>
	);
};

export default ShoppingCartPage;
