import { menuItems } from "constants/menu";
import React, { useState } from "react";
import { NavLink, Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";

const MyPageLayout: React.FC = () => {
	const { t } = useTranslation();
	const [menuOpen, setMenuOpen] = useState(false); // 모바일 메뉴 상태 관리

	return (
		<div className="min-h-screen bg-gray-50">
			<div className="container mx-auto py-10 px-4 sm:px-6 lg:px-8">
				<div className="lg:flex lg:gap-6">
					{/* Sidebar (PC) */}
					<nav className={`lg:w-1/4 bg-white shadow rounded-lg p-4 transition-transform ${menuOpen ? "block" : "hidden lg:block"}`}>
						{/* Menu Toggle for Mobile */}
						<button className="lg:hidden mb-4 text-rose-400 font-bold" onClick={() => setMenuOpen(!menuOpen)}>
							{menuOpen ? t("menu.close") : t("menu.open")}
						</button>
						<ul className="space-y-4">
							{menuItems.map((item) => (
								<li key={item.path}>
									<NavLink
										to={item.path}
										className={({ isActive }) =>
											`block rounded-md px-4 py-2 text-sm font-medium ${
												isActive ? "bg-rose-400 text-white" : "text-gray-700 hover:bg-gray-100"
											}`
										}>
										{t(item.nameKey)}
									</NavLink>
								</li>
							))}
						</ul>
					</nav>

					{/* Content Area */}
					<div className="lg:w-3/4 bg-white shadow rounded-lg p-6 w-full">
						<Outlet />
					</div>
				</div>
			</div>
		</div>
	);
};

export default MyPageLayout;
