import { StarIcon } from "@heroicons/react/20/solid";
import { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import React from "react";
import { XMarkIcon, ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { commonClient } from "apis/common";
import { useTranslation } from "react-i18next";

function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(" ");
}

export default function ReviewPage() {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { productId } = useParams();
	const [reviews, setReviews] = useState([]);
	const [totalReviews, setTotalReviews] = useState(0);
	const [page, setPage] = useState(1);
	const [loading, setLoading] = useState(false);
	const [selectedReview, setSelectedReview] = useState<number | null>(null);
	const observer = useRef<IntersectionObserver | null>(null);
	const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0); // 선택된 이미지

	const fetchReviews = useCallback(async () => {
		if (loading) return;
		setLoading(true);
		try {
			const reviewRes = await commonClient.get(`/reviews/product/${productId}?limit=10&page=${page}`);
			if (reviewRes.data.success) {
				setReviews((prev) => [...prev, ...reviewRes.data.data.items]);
				setTotalReviews(reviewRes.data.data.total);
				setPage((prev) => prev + 1);
			}
		} catch (error) {
			console.error("Error fetching reviews", error);
		} finally {
			setLoading(false);
		}
	}, [productId, page, loading]);

	useEffect(() => {
		fetchReviews();
	}, []);

	const lastReviewElementRef = useCallback(
		(node: HTMLElement | null) => {
			if (loading) return;
			if (observer.current) {
				observer.current.disconnect();
			}
			observer.current = new IntersectionObserver((entries) => {
				if (entries[0].isIntersecting && reviews.length < totalReviews) {
					fetchReviews();
				}
			});
			if (node) observer.current.observe(node);
		},
		[loading, reviews, totalReviews, fetchReviews]
	);

	const handleReviewClick = (index: number) => {
		setSelectedReview(index);
		setSelectedImageIndex(0); // 리뷰 선택 시 첫 번째 이미지로 초기화
	};

	const closeReview = () => {
		setSelectedReview(null);
	};

	const navigateReview = (direction: number) => {
		if (selectedReview !== null) {
			setSelectedReview((prev) => Math.max(0, Math.min(reviews.length - 1, prev + direction)));
			setSelectedImageIndex(0); // 리뷰 이동 시 첫 번째 이미지로 초기화
		}
	};

	const handleImageClick = (index: number) => {
		setSelectedImageIndex(index); // 이미지 클릭 시 선택된 이미지 인덱스 업데이트
	};

	const formatDate = (dateString: string) => {
		const date = new Date(dateString);
		return date.toLocaleDateString("ko-KR", {
			year: "numeric",
			month: "long",
			day: "numeric",
		});
	};

	return (
		<div className="bg-white">
			<div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-6 lg:max-w-7xl lg:px-8">
				<a
					className="text-sm font-semibold text-rose-400 hover:text-rose-300 mb-3 block cursor-pointer"
					onClick={() => {
						navigate(-1);
					}}>
					<span aria-hidden="true">&larr; </span>
					{t("common.back")}
				</a>
				<h2 className="text-lg font-medium text-gray-900">Recent Reviews</h2>
				<div className="mt-6 divide-y divide-gray-200 border-b border-t border-gray-200">
					{reviews.length ? (
						reviews.map((review, index) => (
							<div
								key={review.reviewId}
								ref={index === reviews.length - 1 ? lastReviewElementRef : null}
								className="py-10 lg:grid lg:grid-cols-12 lg:gap-x-8 cursor-pointer hover:bg-gray-50"
								onClick={() => handleReviewClick(index)}>
								{/* PC: 왼쪽에 유저 이름과 작성일 표시 */}
								<div className="hidden lg:block lg:col-span-4 lg:col-start-1 lg:row-start-1 lg:mt-0 lg:flex-col lg:items-start xl:col-span-3">
									<p className="font-medium text-gray-900">{review.user.name}</p>
									<time dateTime={review.createdAt} className="mt-2 text-sm text-gray-500">
										{formatDate(review.createdAt)}
									</time>
								</div>

								{/* 리뷰 내용과 이미지 */}
								<div className="lg:col-span-8 lg:col-start-5 xl:col-span-9 xl:col-start-4 xl:grid xl:grid-cols-3 xl:items-start xl:gap-x-8">
									<div className="flex items-center xl:col-span-1">
										<div className="flex items-center">
											{[...Array(5)].map((_, i) => (
												<StarIcon
													key={i}
													className={classNames(review.rating > i ? "text-yellow-400" : "text-gray-200", "size-5 shrink-0")}
												/>
											))}
										</div>
										<p className="ml-3 text-sm text-gray-700">{review.rating}</p>
									</div>
									<div className="mt-4 lg:mt-6 xl:col-span-2 xl:mt-0">
										<p className="text-sm text-gray-900">{review.content}</p>
										{review.images.length > 0 && (
											<div className="mt-4 flex space-x-2 overflow-x-auto">
												{review.images.slice(0, 10).map((image, idx) => (
													<img
														key={idx}
														src={image.url}
														alt={`Review image ${idx + 1}`}
														className="h-20 w-20 object-cover rounded-lg"
													/>
												))}
											</div>
										)}
									</div>
								</div>

								{/* 모바일: 하단에 유저 이름과 작성일 표시 */}
								<div className="mt-6 flex items-center text-sm lg:hidden">
									<p className="font-medium text-gray-900">{review.user.name}</p>
									<time dateTime={review.createdAt} className="ml-4 border-l border-gray-200 pl-4 text-gray-500">
										{formatDate(review.createdAt)}
									</time>
								</div>
							</div>
						))
					) : (
						<p className="text-gray-500">등록된 리뷰가 없습니다.</p>
					)}
				</div>
			</div>

			{/* 선택된 리뷰를 크게 보여주는 모달 */}
			{selectedReview !== null && (
				<div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
					<div className="bg-white p-6 rounded-lg w-11/12 md:w-3/4 lg:w-1/2 xl:w-1/3 relative">
						<button className="absolute top-2 right-2" onClick={closeReview}>
							<XMarkIcon className="w-6 h-6" />
						</button>
						<div className="flex flex-col lg:flex-row gap-6">
							{/* 왼쪽: 큰 이미지 */}
							<div className="lg:w-1/2">
								{reviews[selectedReview].images.length > 0 && (
									<img
										src={reviews[selectedReview].images[selectedImageIndex].url}
										alt={`Selected review image`}
										className="w-full h-96 object-cover rounded-lg"
									/>
								)}
							</div>
							{/* 오른쪽: 이미지 목록과 리뷰 내용 */}
							<div className="lg:w-1/2">
								{/* 이미지 목록 */}
								<div className="grid grid-cols-4 gap-2 mb-4">
									{reviews[selectedReview].images.slice(0, 10).map((image, idx) => (
										<img
											key={idx}
											src={image.url}
											alt={`Review image ${idx + 1}`}
											className={`w-full h-20 object-cover rounded-lg cursor-pointer ${
												selectedImageIndex === idx ? "border-2 border-blue-500" : ""
											}`}
											onClick={() => handleImageClick(idx)}
										/>
									))}
								</div>
								{/* 리뷰 내용과 정보 */}
								<div className="text-lg font-bold mb-2">{reviews[selectedReview].content}</div>
								<div className="mt-2 text-sm text-gray-500">
									<p>{reviews[selectedReview].user.name}</p>
									<time dateTime={reviews[selectedReview].createdAt}>{formatDate(reviews[selectedReview].createdAt)}</time>
								</div>
								<div className="flex justify-between mt-4">
									{selectedReview > 0 && (
										<button onClick={() => navigateReview(-1)} className="p-2">
											<ChevronLeftIcon className="w-6 h-6" />
										</button>
									)}
									{selectedReview < reviews.length - 1 && (
										<button onClick={() => navigateReview(1)} className="p-2">
											<ChevronRightIcon className="w-6 h-6" />
										</button>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
