import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import authClient from "apis/auth";
import { useNavigate } from "react-router-dom";
import PageWithBackButton from "./PageWithBackButton";
import i18n from "locales/i18n";
import { formatCurrency } from "utils/strUtil";

const WISHLIST_API_URL = "/user/wishlist";

interface WishlistItem {
	wishlistId: number;
	product: {
		productId: number;
		name: string;
		mainImage: string;
		price: number;
		discountPrice?: number;
	};
}

const WishlistPage: React.FC = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [wishlist, setWishlist] = useState<WishlistItem[]>([]);
	const [loading, setLoading] = useState<boolean>(true);

	// 찜한 상품 불러오기
	const fetchWishlist = async () => {
		try {
			const response = await authClient.get(WISHLIST_API_URL);
			if (response.data.success) {
				setWishlist(response.data.data.items);
			}
		} catch (error) {
			console.error(t("wishlist.fetch_error"), error);
		} finally {
			setLoading(false);
		}
	};

	// 찜한 상품 삭제
	const handleRemove = async (productId: number) => {
		if (!window.confirm(t("wishlist.confirm_delete"))) return;

		try {
			await authClient.delete(`${WISHLIST_API_URL}/${productId}`);
			setWishlist(wishlist.filter((item) => item.product.productId !== productId));
		} catch (error) {
			console.error(t("wishlist.delete_error"), error);
			alert(t("wishlist.delete_error"));
		}
	};

	useEffect(() => {
		fetchWishlist();
	}, []);

	return (
		<PageWithBackButton>
			<div>
				<h2 className="text-2xl font-bold mb-4">{t("wishlist.title")}</h2>

				{/* 로딩 상태 */}
				{loading ? (
					<p>{t("common.loading")}</p>
				) : wishlist.length === 0 ? (
					<p className="text-gray-500">{t("wishlist.no_items")}</p>
				) : (
					<div className="space-y-4">
						{wishlist.map(({ wishlistId, product }) => (
							<div key={wishlistId} className="p-4 border rounded-lg shadow flex justify-between items-center">
								{/* 상품 정보 */}
								<div className="flex gap-4 items-center" onClick={() => navigate(`/product/${product.productId}`)}>
									<img src={product.mainImage} alt={product.name} className="w-24 h-24 object-cover rounded-md" />
									<div>
										<p className="font-bold">{product.name}</p>
										<p className="text-gray-500">
											{t("wishlist.price")}:{" "}
											<span className="text-red-500 font-semibold">{formatCurrency(product.price, i18n.language)}</span>
											{product.discountPrice && (
												<span className="ml-2 line-through text-gray-400">{formatCurrency(product.discountPrice, i18n.language)}</span>
											)}
										</p>
									</div>
								</div>

								{/* 버튼들 */}
								<div className="flex gap-2">
									<button
										onClick={() => handleRemove(product.productId)}
										className="px-3 py-2 text-sm bg-red-500 text-white rounded-md hover:bg-red-600 transition">
										{t("common.delete")}
									</button>
								</div>
							</div>
						))}
					</div>
				)}
			</div>
		</PageWithBackButton>
	);
};

export default WishlistPage;
