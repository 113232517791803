import axios from "axios";
import { SERVER_DOMAIN } from "constants/config";
import { Cookies } from "react-cookie";
const cookies = new Cookies();

export const commonClient = axios.create({
	baseURL: SERVER_DOMAIN,
	withCredentials: true,
});

export const tryAssignDevAuthCookie = (token) => {
	if (window.location.href.includes("localhost")) {
		console.log("[DEVELOPMENT ENVIRONMENT] setting cookie for localhost");
		cookies.set("Authorization", `Bearer ${token}`, { path: "/" });
	}
};

export namespace CommonClient {
	export const loginViaAccount = async (userId, password) => {
		const body = {
			user_id: userId,
			password,
		};
		try {
			const ret = await commonClient.post(`/auth/login`, body);
			const { token, refreshToken, password_reset_required } = ret.data;
			console.log("token", token);
			console.log("password_reset_required", password_reset_required);
			// if my url is localhost
			tryAssignDevAuthCookie(token);
			localStorage.setItem("refreshToken", refreshToken);
			return { token, password_reset_required };
		} catch (e) {
			return null;
		}
	};

	export const signinViaLine = async (line_state, userDatas) => {
		try {
			const body = {
				...userDatas,
				line_state: line_state,
			};
			const ret = await commonClient.post("/line/signin", body);
			console.log("[signinViaLine] POST /line/signin", ret);
			const { token, refreshToken } = ret.data;
			tryAssignDevAuthCookie(token);
			localStorage.setItem("refreshToken", refreshToken);
			return { token, refreshToken };
		} catch (e) {
			console.log("[signinViaLine] POST /line/signin error", e);
			return null;
		}
	};
}
