import { commonClient } from 'apis/common';
import axios from 'axios';

export const uploadCdnImg = async (file: File) => {
    try {
        console.log("!!!!! FILE", file)
        // Step 1: Get signed URL from server
        const response = await commonClient.get(`/s3/generate-s3-url`, {
            params: {
                filename: file.name,
                filetype: file.type
            }
        });

        if (response.status !== 200) {
            console.error('Failed to get signed URL.');
            return;
        }
        console.log("!!!!! RET", response)

        const { url } = response.data;
        // const url = "https://39f-storage.s3.ap-northeast-1.amazonaws.com/uploads/1722752684916_jms?AWSAccessKeyId=AKIAR2EGG6GELIQAJJRT&Content-Type=jms&Expires=1722752744&Signature=00m9lz1hrAqSZ8jfxFT6j2xgGHg%3D&x-amz-acl=public-read"
        // Step 2: Upload image to S3 using the signed URL
        const uploadResponse = await axios.put(url, file, {
            headers: {
                'Content-Type': file.type
            }
        });

        if (uploadResponse.status === 200) {
            // insertToEditor(url.split('?')[0]);
            return url.split('?')[0];
        } else {
            console.error('Failed to upload image.');
        }
    } catch (error) {
        console.error('An error occurred during the image upload process:', error);
    }
}
