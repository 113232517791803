import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./en.json";
import translationKO from "./ko.json";
import translationJA from "./ja.json";
import { DEFAULT_LOCALE } from "constants/common";

const resources = {
	en: {
		translation: translationEN,
	},
	ko: {
		translation: translationKO,
	},
	ja: {
		translation: translationJA,
	},
};

i18n.use(LanguageDetector) // 브라우저 언어 감지
	.use(initReactI18next) // React 연동
	.init({
		resources,
		fallbackLng: DEFAULT_LOCALE, // 기본 언어
		supportedLngs: ["en", "ko", "ja"], // 지원 언어
		debug: true,
		detection: {
			order: ["localStorage", "navigator"], // 언어 감지 우선순위
			caches: ["localStorage"],
		},
		interpolation: { escapeValue: false },
		// backend: {
		// 	loadPath: "/locales/{{lng}}.json", // 다국어 JSON 파일 위치
		// },
	});

export default i18n;
