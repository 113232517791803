// export const menuItems = [
// 	{ name: "내 정보", path: "/mypage/profile" },
// 	{ name: "포인트", path: "/mypage/point" },
// 	{ name: "주문 목록", path: "/mypage/orders" },
// 	{ name: "취소/반품/교환 목록", path: "/mypage/returns" },
// 	{ name: "할인 쿠폰", path: "/mypage/coupons" },
// 	{ name: "찜한 상품", path: "/mypage/wishlist" },
// 	{ name: "리뷰 작성", path: "/mypage/review-write" },
// 	{ name: "리뷰 관리", path: "/mypage/reviews" },
// 	{ name: "배송지 관리", path: "/mypage/address" },
// ];
export const menuItems = [
	{ nameKey: "menu.profile", path: "/mypage/profile" },
	{ nameKey: "menu.point", path: "/mypage/point" },
	{ nameKey: "menu.orders", path: "/mypage/orders" },
	{ nameKey: "menu.returns", path: "/mypage/returns" },
	{ nameKey: "menu.coupons", path: "/mypage/coupons" },
	{ nameKey: "menu.wishlist", path: "/mypage/wishlist" },
	{ nameKey: "menu.review_write", path: "/mypage/review-write" },
	{ nameKey: "menu.review_manage", path: "/mypage/reviews" },
	{ nameKey: "menu.address", path: "/mypage/address" },
];
