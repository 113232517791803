import React, { useEffect, useState } from "react";
import authClient from "apis/auth";
import { format } from "date-fns";
import { useNavigate, useParams } from "react-router-dom";
import SpinnerMedium from "components/Spinner/Medium";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import i18n from "locales/i18n";
import { formatCurrency } from "utils/strUtil";

const OrderDetailPage: React.FC = () => {
	const { t } = useTranslation();
	const { orderId } = useParams();
	const [order, setOrder] = useState<any | null>(null);
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();

	useEffect(() => {
		const fetchOrderDetails = async () => {
			try {
				const response = await authClient.get(`/user/orders/${orderId}`);
				setOrder(response.data.data);
			} catch (error) {
				console.error("Failed to fetch order details:", error);
				toast.error(t("order.error_fetch"));
			} finally {
				setLoading(false);
			}
		};
		fetchOrderDetails();
	}, [orderId, t]);

	if (loading) {
		return <SpinnerMedium />;
	}

	if (!order) {
		return <p className="text-center text-gray-600">{t("order.not_found")}</p>;
	}

	// 주문 상태별 버튼 관리
	const getActionButtons = () => {
		switch (order.status) {
			case "PENDING":
				return (
					<button className="w-full bg-red-500 text-white py-2 rounded-lg hover:bg-red-700" onClick={() => toast.info(t("order.cancel_requested"))}>
						{t("order.cancel_request")}
					</button>
				);
			case "DELIVERED":
				return (
					<div className="flex flex-col gap-2">
						<button
							className="w-full bg-rose-400 text-white py-2 rounded-lg hover:bg-rose-500"
							onClick={() => navigate(`/shipping-tracking/${orderId}`)}>
							{t("order.track_shipping")}
						</button>
						<button className="w-full bg-green-500 text-white py-2 rounded-lg hover:bg-green-700" onClick={() => navigate(`/review/${orderId}`)}>
							{t("order.write_review")}
						</button>
					</div>
				);
			case "COMPLETED":
				return (
					<button className="w-full bg-gray-500 text-white py-2 rounded-lg hover:bg-gray-700" disabled>
						{t("order.completed")}
					</button>
				);
			default:
				return null;
		}
	};

	return (
		<div className="bg-white">
			<div className="mx-auto max-w-7xl px-4 py-10 sm:px-6 lg:px-8">
				<h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">{t("order.detail_title")}</h1>

				{/* 주문번호 및 날짜 */}
				<div className="mt-4 border-b border-gray-200 pb-5 text-sm sm:flex sm:justify-between">
					<dl className="flex">
						<dt className="text-gray-500">{t("order.number")}&nbsp;</dt>
						<dd className="font-medium text-gray-900">{order.orderId}</dd>
						<dt>
							<span className="sr-only">Date</span>
							<span aria-hidden="true" className="mx-2 text-gray-400">
								&middot;
							</span>
						</dt>
						<dd className="font-medium text-gray-900">
							<time dateTime={order.createdAt}>{format(new Date(order.createdAt), "yyyy-MM-dd")}</time>
						</dd>
					</dl>
					<button onClick={() => navigate("/mypage/orders")} className="text-rose-400 hover:text-rose-300 font-medium">
						{t("order.back_to_list")} →
					</button>
				</div>

				{/* 배송지 정보 */}
				<div className="mt-6 p-4 border rounded-lg bg-gray-50">
					<h2 className="text-lg font-medium text-gray-900">{t("order.shipping_info")}</h2>
					<p className="text-sm text-gray-600 mt-1">
						{order.recipientName} | {order.recipientPhone}
					</p>
					<p className="text-sm text-gray-600">{order.shippingAddress}</p>
				</div>

				{/* 상품 목록 */}
				<div className="mt-8">
					<h2 className="sr-only">구매한 상품</h2>
					<div className="space-y-10">
						{order.items.map((item) => (
							<div
								key={item.orderItemId}
								onClick={() => navigate(`/product/${item.product?.productId}`)}
								className="grid grid-cols-1 sm:grid-cols-12 gap-x-6 md:gap-x-8 lg:gap-x-8">
								<div className="sm:col-span-3">
									<img
										alt={item.product?.name || "Product Image"}
										src={item.product?.mainImage || "https://via.placeholder.com/100"}
										className="w-24 h-24 sm:w-full sm:max-w-[120px] rounded-lg bg-gray-50 object-cover"
									/>
								</div>
								<div className="sm:col-span-5">
									<h3 className="text-lg font-medium text-gray-900">{item.product?.name || "Unknown Product"}</h3>
									<p className="mt-1 text-sm text-gray-500">{item.product?.description || "설명이 없습니다."}</p>
									<p className="mt-1 text-sm font-medium text-gray-900">{formatCurrency(item.price, i18n.language)}</p>
								</div>
							</div>
						))}
					</div>
				</div>

				{/* 결제 정보 */}
				<div className="mt-10 rounded-lg bg-gray-50 px-6 py-6">
					<h2 className="text-lg font-medium text-gray-900">{t("order.payment_info")}</h2>
					<dl className="mt-4 space-y-4 text-sm">
						<div className="flex justify-between">
							<dt className="text-gray-600">{t("order.total_price")}</dt>
							<dd className="font-medium text-gray-900">{formatCurrency(order.totalPrice, i18n.language)}</dd>
						</div>
						<div className="flex justify-between">
							<dt className="text-gray-600">{t("order.discount_coupon")}</dt>
							<dd className="font-medium text-red-600">- {formatCurrency(order.discountCoupon, i18n.language)}</dd>
						</div>
						<div className="flex justify-between">
							<dt className="text-gray-600">{t("order.used_points")}</dt>
							<dd className="font-medium text-red-600">- {Number(order.usedPoints).toLocaleString()}P</dd>
						</div>
						<div className="flex justify-between">
							<dt className="text-gray-600">{t("order.shipping_fee")}</dt>
							<dd className="font-medium text-gray-900">{formatCurrency(order.shippingFee, i18n.language)}</dd>
						</div>
						<div className="flex justify-between">
							<dt className="text-gray-900 font-medium">{t("order.final_payment")}</dt>
							<dd className="font-medium text-rose-400">{formatCurrency(order.finalPayment, i18n.language)}</dd>
						</div>
						<div className="flex justify-between">
							<dt className="text-gray-600">{t("order.payment_method")}</dt>
							<dd className="font-medium text-gray-900">{order.paymentMethod}</dd>
						</div>
					</dl>
				</div>

				{/* 주문 상태별 액션 버튼 */}
				<div className="mt-8">{getActionButtons()}</div>
			</div>
		</div>
	);
};

export default OrderDetailPage;
