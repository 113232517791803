import { useEffect, useState } from "react";

export default function useDeviceDetect() {
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		const checkMobile = () => {
			// setIsMobile(window.innerWidth <= 768);
			setIsMobile(window.innerWidth <= 1023);
		};
		checkMobile();
		window.addEventListener("resize", checkMobile);
		return () => window.removeEventListener("resize", checkMobile);
	}, []);

	return { isMobile };
}
