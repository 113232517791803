import { commonClient } from "apis/common";
import SpinnerMedium from "components/Spinner/Medium";
import useDeviceDetect from "hook/useDeviceDetect";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { formatCurrency } from "utils/strUtil";

const CategoryDetailPage = () => {
	const { categoryId } = useParams();
	const navigate = useNavigate();
	const { t, i18n } = useTranslation();
	const [productDatas, setProductDatas] = useState(null);
	const [categoryInfo, setCategoryInfo] = useState(null);
	const [brands, setBrands] = useState(null);
	const [subCategories, setSubCategories] = useState([]); // ✅ 하위 카테고리
	const [categoryTree, setCategoryTree] = useState([]); // ✅ 상위 카테고리 트리
	const { isMobile } = useDeviceDetect();

	useEffect(() => {
		fetchData();
	}, [categoryId]);

	const fetchData = async () => {
		try {
			const categoryRet = await commonClient.get(`/categories/${categoryId}`);
			const categoryData = categoryRet.data.data;
			setCategoryInfo(categoryData);
			setSubCategories(categoryData.subCategories || []);
			buildCategoryTree(categoryData); // ✅ 상위 카테고리 트리 생성

			const ret = await commonClient.get(`/products/categories/${categoryId}`);
			const products = ret.data.data.items;
			setProductDatas(products);

			const tempBrands = _.uniqBy(
				products.map((product) => product.seller),
				"sellerId"
			);
			setBrands(tempBrands);
		} catch (e) {
			console.error(t("category.fetch_error"), e);
		}
	};

	// ✅ 상위 카테고리 트리 생성
	const buildCategoryTree = async (category) => {
		let tree = [];
		let currentCategory = category;
		while (currentCategory?.parentCategory) {
			tree.unshift(currentCategory.parentCategory);
			const parentRet = await commonClient.get(`/categories/${currentCategory.parentCategory.categoryId}`);
			currentCategory = parentRet.data.data;
		}
		setCategoryTree(tree);
	};

	if (_.isNil(productDatas)) {
		return <SpinnerMedium />;
	}

	return (
		<div>
			<div className="bg-white">
				<div className="mx-auto max-w-2xl px-4 py-6 sm:px-6 lg:max-w-7xl lg:px-8">
					{/* ✅ 상위 카테고리 트리 (Breadcrumb) */}
					{categoryTree.length > 0 && (
						<nav className="mb-4 flex space-x-2 text-sm text-gray-600">
							{categoryTree.map((cat, index) => (
								<span key={cat.categoryId} className="flex items-center">
									<a onClick={() => navigate(`/category/${cat.categoryId}`)} className="cursor-pointer hover:underline">
										{cat.name}
									</a>
									{index < categoryTree.length - 1 && <span className="mx-2">/</span>}
								</span>
							))}
						</nav>
					)}

					{/* ✅ 하위 카테고리 리스트 */}
					{subCategories.length > 0 && (
						<div className="mb-6">
							{/* <h3 className="text-lg font-semibold text-gray-800 mb-3">{t("category.sub_categories")}</h3> */}
							<div className="flex space-x-4 overflow-x-auto pb-2">
								{subCategories.map((sub) => (
									<button
										key={sub.categoryId}
										onClick={() => navigate(`/category/${sub.categoryId}`)}
										className="flex flex-col items-center bg-gray-100 hover:bg-gray-200 px-4 py-2 rounded-lg transition duration-200 shadow-sm">
										{!isMobile && sub.imageUrl && <img src={sub.imageUrl} alt={sub.name} className="h-16 w-16 object-cover rounded-md" />}
										<span className="text-sm font-medium text-gray-800">{sub.name}</span>
									</button>
								))}
							</div>
						</div>
					)}

					{/* 현재 카테고리 타이틀 */}
					<div className="md:flex md:items-center md:justify-between">
						<h2 className="text-2xl font-bold tracking-tight text-gray-900">{categoryInfo?.name}</h2>
					</div>

					{/* 브랜드별 상품 리스트 */}
					<div>
						{brands.length > 0 ? (
							brands.map((brand) => (
								<div key={brand.sellerId}>
									<div className="md:flex md:items-center md:justify-between mt-5">
										<h2 className="text-xl font-bold tracking-tight text-gray-900">{brand.name}</h2>
									</div>
									<div className="mt-2 grid grid-cols-2 gap-x-4 gap-y-10 sm:gap-x-6 md:grid-cols-4 lg:gap-x-8">
										{productDatas
											.filter((product) => product.seller.sellerId === brand.sellerId)
											.map((product) => (
												<div key={product.productId} className="group relative">
													<div className="h-56 w-full overflow-hidden rounded-md bg-gray-200 group-hover:opacity-75 lg:h-72 xl:h-80">
														<img src={product.mainImage} className="h-full w-full object-cover object-center" alt={product.name} />
													</div>
													<h3 className="mt-2 text-sm text-gray-700">
														<a className="cursor-pointer" onClick={() => navigate(`/product/${product.productId}`)}>
															<span className="absolute inset-0" />
															{product.name}
														</a>
													</h3>
													<p className="mt-1 text-sm font-medium text-gray-900">{formatCurrency(product.price, i18n.language)}</p>
												</div>
											))}
									</div>
								</div>
							))
						) : (
							<p className="text-gray-500 text-center py-6">{t("category.no_products")}</p>
						)}
					</div>

					<div className="mt-8 text-sm md:hidden">
						<a href="#" className="font-medium text-rose-400 hover:text-rose-300">
							{t("category.shop_collection")}
							<span aria-hidden="true"> &rarr;</span>
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CategoryDetailPage;
