import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import authClient from "apis/auth";
import PageWithBackButton from "./PageWithBackButton";

const API_URL = "/user/orders/reviewable";

interface OrderItem {
	orderItemId: number;
	quantity: number;
	price: string;
	product: {
		productId: number;
		name: string;
		mainImage: string;
	};
}

interface Order {
	orderId: number;
	createdAt: string;
	items: OrderItem[];
}

const ReviewWritePage: React.FC = () => {
	const { t } = useTranslation();
	const [orders, setOrders] = useState<Order[]>([]);
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();

	// 주문 목록 불러오기
	const fetchOrders = async () => {
		try {
			const response = await authClient.get(API_URL);
			if (response.data.success) {
				setOrders(response.data.data.items);
			}
		} catch (error) {
			console.error(t("review.fetch_orders_error"), error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchOrders();
	}, [t]);

	return (
		<PageWithBackButton>
			<div>
				<h2 className="text-2xl font-bold mb-4">{t("review.write_review")}</h2>

				{/* 주문 목록 */}
				{loading ? (
					<p>{t("common.loading")}</p>
				) : orders.length === 0 ? (
					<p className="text-gray-500">{t("review.no_reviewable_orders")}</p>
				) : (
					<div className="space-y-4">
						{orders.map((order) =>
							order.items.map((item) => (
								<div key={item.orderItemId} className="p-4 border rounded-lg shadow transition hover:shadow-lg flex items-center gap-4">
									{/* 상품 이미지 */}
									<img
										onClick={() => navigate(`/product/${item.product.productId}`)}
										src={item.product.mainImage}
										alt={item.product.name}
										className="w-20 h-20 object-cover rounded-md"
									/>

									{/* 상품 정보 */}
									<div className="flex-grow">
										<p className="font-bold" onClick={() => navigate(`/product/${item.product.productId}`)}>
											{item.product.name}
										</p>
										<p className="text-sm text-gray-500">
											{t("review.order_id")}: <span className="font-semibold">{order.orderId}</span>
										</p>
										<p className="text-sm text-gray-500">
											{t("review.purchase_date")}: <span className="font-semibold">{new Date(order.createdAt).toLocaleDateString()}</span>
										</p>
									</div>

									{/* 구매 후기 작성 버튼 */}
									<button
										onClick={() => navigate(`/mypage/review-write/${item.product.productId}`)}
										className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition">
										{t("review.write_review_button")}
									</button>
								</div>
							))
						)}
					</div>
				)}
			</div>
		</PageWithBackButton>
	);
};

export default ReviewWritePage;
