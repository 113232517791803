import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import authClient from "apis/auth";
import PageWithBackButton from "./PageWithBackButton";
import { commonClient } from "apis/common";
import { uploadCdnImg } from "utils/file";

const REVIEW_API_URL = "/user/reviews";
const PRODUCT_API_URL = "/products"; // 상품 정보를 불러올 API

const ReviewWriteForm: React.FC = () => {
	const { t } = useTranslation();
	const { productId } = useParams<{ productId: string }>();
	const navigate = useNavigate();

	// 상품 정보
	const [product, setProduct] = useState<{ productId: String; name: string; mainImage: string } | null>(null);
	const [rating, setRating] = useState<number>(5);
	const [content, setContent] = useState<string>("");
	const [imageUrls, setImageUrls] = useState<string[]>([]);
	const [loading, setLoading] = useState<boolean>(true);

	// 상품 정보 불러오기
	useEffect(() => {
		const fetchProduct = async () => {
			try {
				const response = await commonClient.get(`${PRODUCT_API_URL}/${productId}`);
				if (response.data.success) {
					setProduct(response.data.data);
				}
			} catch (error) {
				console.error(t("review.fetch_product_error"), error);
			} finally {
				setLoading(false);
			}
		};

		fetchProduct();
	}, [productId, t]);

	// 이미지 업로드 핸들러
	const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const files = event.target.files;
		if (!files) return;

		const uploadedUrls: string[] = [];
		for (const file of files) {
			const imageUrl = await uploadCdnImg(file);
			uploadedUrls.push(imageUrl);
		}

		setImageUrls((prev) => [...prev, ...uploadedUrls]);
	};

	// 리뷰 제출 핸들러
	const handleSubmit = async () => {
		if (!content.trim()) {
			alert(t("review.empty_content_error"));
			return;
		}

		const reviewData = {
			productId: parseInt(productId, 10),
			rating,
			content,
			images: imageUrls,
		};

		try {
			await authClient.post(REVIEW_API_URL, reviewData);
			alert(t("review.submit_success"));
			navigate("/mypage/reviews");
		} catch (error) {
			console.error(t("review.submit_error"), error);
			alert(t("review.submit_error"));
		}
	};

	return (
		<PageWithBackButton>
			<div>
				<h2 className="text-2xl font-bold mb-4">{t("review.write_title")}</h2>

				{loading ? (
					<p>{t("common.loading")}</p>
				) : product ? (
					<div className="space-y-4">
						{/* 상품 정보 */}
						<div className="p-4 border rounded-lg shadow" onClick={() => navigate(`/product/${product.productId}`)}>
							<img src={product.mainImage} alt={product.name} className="w-24 h-24 object-cover rounded-md mb-2" />
							<p className="font-bold">{product.name}</p>
						</div>

						{/* 별점 선택 */}
						<div>
							<label className="block text-sm font-semibold">{t("review.select_rating")}</label>
							<div className="flex space-x-2 mt-2">
								{[1, 2, 3, 4, 5].map((num) => (
									<button
										key={num}
										onClick={() => setRating(num)}
										className={`w-8 h-8 flex items-center justify-center rounded-full ${rating >= num ? "bg-yellow-400" : "bg-gray-200"}`}>
										⭐
									</button>
								))}
							</div>
						</div>

						{/* 리뷰 내용 입력 */}
						<div>
							<label className="block text-sm font-semibold">{t("review.content")}</label>
							<textarea
								value={content}
								onChange={(e) => setContent(e.target.value)}
								className="w-full h-24 border rounded p-2 mt-1"
								placeholder={t("review.content_placeholder")}
							/>
						</div>

						{/* 이미지 업로드 */}
						<div>
							<label className="block text-sm font-semibold">{t("review.upload_images")}</label>
							<input type="file" accept="image/*" multiple onChange={handleImageUpload} className="mt-1" />
							<div className="flex space-x-2 mt-2">
								{imageUrls.map((url, index) => (
									<img key={index} src={url} alt={`${t("review.image")} ${index}`} className="w-16 h-16 object-cover rounded-md" />
								))}
							</div>
						</div>

						{/* 제출 버튼 */}
						<button onClick={handleSubmit} className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition">
							{t("review.submit")}
						</button>
					</div>
				) : (
					<p className="text-gray-500">{t("review.fetch_product_error")}</p>
				)}
			</div>
		</PageWithBackButton>
	);
};

export default ReviewWriteForm;
