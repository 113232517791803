import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import SpinnerMedium from "components/Spinner/Medium";
import Footer from "pages/Footer/index";
import React, { useEffect, useState } from "react";
import AppRoutes from "routes/AppRoutes";
import { infoService } from "services/info";
import "./App.scss";
import i18n from "locales/i18n";
import { I18nextProvider } from "react-i18next";

// QueryClient 생성
const queryClient = new QueryClient();

const App: React.FC = () => {
	const [isReady, setIsReady] = useState(false);

	useEffect(() => {
		init();
	}, []);

	const init = async () => {
		try {
			await infoService.init();
			setIsReady(true);
		} catch (e) {
			console.error("[init] error", e);
		}
	};

	if (!isReady) {
		return <SpinnerMedium></SpinnerMedium>;
	}

	return (
		<QueryClientProvider client={queryClient}>
			<I18nextProvider i18n={i18n}>
				<div className="">
					{/* <PublicTopNav></PublicTopNav> */}
					<AppRoutes />
					<Footer />
				</div>
				<ReactQueryDevtools initialIsOpen={false} />
			</I18nextProvider>
		</QueryClientProvider>
	);
};

export default App;
