import React from "react";
import { Route, Routes } from "react-router-dom";

import BrandPage from "pages/Brand";
import BrandDetailPage from "pages/Brand/detail";

export default () => (
	<Routes>
		<Route index element={<BrandPage />} />
		<Route path=":sellerId" element={<BrandDetailPage />}></Route>
	</Routes>
);
