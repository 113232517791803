import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { authService } from "services/authService";
import { toast } from "react-toastify";

const GoogleSignup: React.FC = () => {
	const navigate = useNavigate();
	const [email, setEmail] = useState<string | null>(null);
	const [name, setName] = useState("");
	const [verificationCode, setVerificationCode] = useState("");
	const [isEmailVerified, setIsEmailVerified] = useState(false);
	const [isCodeSent, setIsCodeSent] = useState(false);
	const [canResendCode, setCanResendCode] = useState(true);
	const [timer, setTimer] = useState(0);
	const [isSendingCode, setIsSendingCode] = useState(false);
	const [isNewUser, setIsNewUser] = useState(false);

	useEffect(() => {
		const handleGoogleLogin = async () => {
			const params = new URLSearchParams(window.location.search);
			const code = params.get("code");

			if (!code) {
				toast.error("Google authentication failed.");
				navigate("/login");
				return;
			}

			try {
				const response = await authService.googleLogin(code);
				setIsNewUser(response.isNewUser);

				if (response.email) {
					setEmail(response.email);
					setIsEmailVerified(true);
				}
			} catch (error) {
				toast.error("Google authentication failed.");
				navigate("/login");
			}
		};

		handleGoogleLogin();
	}, [navigate]);

	const handleSendVerificationCode = async () => {
		if (!email) {
			toast.warn("Email is required.");
			return;
		}

		setIsSendingCode(true);
		try {
			const emailCheckResponse = await authService.checkEmailAvailability(email);
			if (emailCheckResponse.exists) {
				toast.warn("Email is already registered.");
				setIsSendingCode(false);
				return;
			}

			const response = await authService.sendVerificationCode(email);
			if (response.status === 201) {
				toast.success("Verification code sent.");
				setIsCodeSent(true);
				setCanResendCode(false);
				setTimer(60);
			}
		} catch (error) {
			console.error("Failed to send verification code:", error);
			toast.error("Verification code failed.");
		} finally {
			setIsSendingCode(false);
		}
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();

		if (!isEmailVerified) {
			toast.warn("Please verify your email first.");
			return;
		}

		// if (password !== confirmPassword) {
		// 	toast.warn("Passwords do not match.");
		// 	return;
		// }

		try {
			const response = await authService.signupWithGoogle(email!, name);
			if (response.status === 201) {
				toast.success("Welcome! Your account has been created.");
				navigate("/");
			}
		} catch (error) {
			console.error("Sign-up failed:", error);
			toast.error("Signup failed.");
		}
	};

	return (
		<div className="min-h-screen flex items-center justify-center bg-gray-50">
			<div className="max-w-lg w-full bg-white p-8 shadow rounded-md">
				<h2 className="text-2xl font-bold text-center mb-4">Complete Your Profile</h2>
				<form onSubmit={handleSubmit} className="space-y-4">
					{/* Email 입력 */}
					{!email && (
						<div>
							<label htmlFor="email" className="block text-sm font-medium text-gray-700">
								Email
							</label>
							<input
								type="email"
								id="email"
								value={email || ""}
								onChange={(e) => setEmail(e.target.value)}
								required
								className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-sm"
							/>
							<button type="button" onClick={handleSendVerificationCode}>
								Send Code
							</button>
						</div>
					)}

					{/* 이름 입력 */}
					<div>
						<label htmlFor="name">Name</label>
						<input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} required />
					</div>

					{/* 비밀번호 입력 */}
					{/* <div>
						<label htmlFor="password">Password</label>
						<input type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
					</div> */}

					<button type="submit">Complete Signup</button>
				</form>
			</div>
		</div>
	);
};

export default GoogleSignup;
