import useDeviceDetect from "hook/useDeviceDetect";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function PageWithBackButton({ children }) {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { isMobile } = useDeviceDetect();

	return (
		<div>
			{children}
			<br />
			{isMobile && (
				<button onClick={() => navigate(-1)} className="mb-4 p-2 text-gray-700 bg-gray-200 rounded-lg">
					← {t("common.back")}
				</button>
			)}
		</div>
	);
}
