import React from "react";
import { Routes, Route } from "react-router-dom";

import ProductDetailPage from "pages/Product/productDetailPage";
import ReviewPage from "pages/Product/reviewPage";

export default () => (
	<Routes>
		<Route path="/:productId" element={<ProductDetailPage />}></Route>
		<Route path="/review/:productId" element={<ReviewPage />}></Route>
	</Routes>
);
