import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import authClient from "apis/auth";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const ChangePasswordPage: React.FC = () => {
	const { t } = useTranslation();
	const [currentPassword, setCurrentPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	const handleChangePassword = async () => {
		// 검증 로직
		if (!currentPassword || !newPassword || !confirmPassword) {
			toast.error(t("password.error_fill_all"));
			return;
		}
		if (newPassword.length < 6) {
			toast.error(t("password.error_too_short"));
			return;
		}
		if (newPassword !== confirmPassword) {
			toast.error(t("password.error_not_match"));
			return;
		}

		try {
			setLoading(true);
			await authClient.put("/user/profile/password/update", {
				currentPassword,
				newPassword,
			});
			toast.success(t("password.success"));
			navigate("/mypage"); // 마이페이지로 이동
		} catch (error) {
			console.error(t("password.error_update_failed"), error);
			toast.error(t("password.error_update_failed"));
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="max-w-md mx-auto bg-white shadow-lg rounded-lg p-6">
			<h2 className="text-2xl font-bold text-gray-900 text-center">{t("password.title")}</h2>

			<div className="mt-4">
				<label className="block text-gray-700 font-medium">{t("password.current")}</label>
				<input
					type="password"
					value={currentPassword}
					onChange={(e) => setCurrentPassword(e.target.value)}
					className="w-full border rounded-lg p-2 mt-2"
					placeholder={t("password.current_placeholder")}
				/>
			</div>

			<div className="mt-4">
				<label className="block text-gray-700 font-medium">{t("password.new")}</label>
				<input
					type="password"
					value={newPassword}
					onChange={(e) => setNewPassword(e.target.value)}
					className="w-full border rounded-lg p-2 mt-2"
					placeholder={t("password.new_placeholder")}
				/>
			</div>

			<div className="mt-4">
				<label className="block text-gray-700 font-medium">{t("password.confirm")}</label>
				<input
					type="password"
					value={confirmPassword}
					onChange={(e) => setConfirmPassword(e.target.value)}
					className="w-full border rounded-lg p-2 mt-2"
					placeholder={t("password.confirm_placeholder")}
				/>
			</div>

			{/* 버튼 */}
			<div className="mt-6">
				<button
					onClick={handleChangePassword}
					disabled={loading}
					className={`w-full text-white font-medium py-2 rounded-lg transition ${
						loading ? "bg-gray-400 cursor-not-allowed" : "bg-blue-600 hover:bg-blue-700"
					}`}>
					{loading ? t("password.loading") : t("password.change")}
				</button>
			</div>
		</div>
	);
};

export default ChangePasswordPage;
