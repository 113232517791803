import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { authService } from "services/authService";
import { toast } from "react-toastify";

const GoogleCallback: React.FC = () => {
	const navigate = useNavigate();
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		const code = params.get("code");

		if (!code) {
			setError("Google login failed.");
			return;
		}

		handleGoogleLogin(code);
	}, []);

	const handleGoogleLogin = async (code: string) => {
		try {
			const response = await authService.googleLogin(code);

			if (response.accessToken) {
				localStorage.setItem("access_token", response.accessToken);

				if (response.isNewUser) {
					if (response.email) {
						window.location.href = "/";
					}
					navigate("/signup/line");
				} else {
					window.location.href = "/";
				}
			}
		} catch (error) {
			console.error("Google login failed:", error);
			setError("Login failed. Please try again.");
		}
	};

	return (
		<div className="flex items-center justify-center min-h-screen bg-gray-50">
			<div className="p-6 bg-white rounded shadow-md">
				{error ? <p className="text-red-500">{error}</p> : <p className="text-gray-700">Processing Google login...</p>}
			</div>
		</div>
	);
};

export default GoogleCallback;
