import React from "react";
import { useTranslation } from "react-i18next";

const DisputeResolutionPage: React.FC = () => {
	const { t } = useTranslation();

	return (
		<div className="bg-white py-10 px-6 sm:px-10 lg:px-20">
			<div className="max-w-4xl mx-auto">
				<h1 className="text-3xl font-bold text-gray-900">{t("dispute-resolution.title")}</h1>
				{/* <p className="text-sm text-gray-600 mt-2">{t("dispute-resolution.last_updated")}</p> */}

				<div className="mt-6">
					<div className="text-gray-700 whitespace-pre-line">{t("dispute-resolution.full_content")}</div>
				</div>
			</div>
		</div>
	);
};

export default DisputeResolutionPage;
