import React from "react";
import { useTranslation } from "react-i18next";

const PaymentAgencyPage = () => {
	const { t } = useTranslation();

	return (
		<div className="p-6 max-w-3xl mx-auto">
			<h1 className="text-2xl font-bold mb-4">{t("paymentAgency.title")}</h1>
			<ul className="bg-gray-100 p-4 rounded-lg">
				<li>
					<strong>{t("paymentAgency.companyName")}:</strong> {t("paymentAgency.companyValue")}
				</li>
				<li>
					<strong>{t("paymentAgency.address")}:</strong> {t("paymentAgency.addressValue")}
				</li>
				<li>
					<strong>{t("paymentAgency.businessNumber")}:</strong> {t("paymentAgency.businessNumberValue")}
				</li>
				<li>
					<strong>{t("paymentAgency.phoneNumber")}:</strong> {t("paymentAgency.phoneNumberValue")}
				</li>
				<li>
					<strong>{t("paymentAgency.email")}:</strong> {t("paymentAgency.emailValue")}
				</li>
				<li>
					<strong>{t("paymentAgency.ceo")}:</strong> {t("paymentAgency.ceoValue")}
				</li>
				<li>
					<strong>{t("paymentAgency.privacyOfficer")}:</strong> {t("paymentAgency.privacyOfficerValue")}
				</li>
			</ul>
		</div>
	);
};

export default PaymentAgencyPage;
