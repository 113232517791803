import { commonClient } from "apis/common";
import { shuffle } from "lodash";

// export const infoService = {
// 	getCategories: async () => {
// 		try {
// 			const ret = await apiClient.get("/categories");
// 			return ret.data?.data.items;
// 		} catch (e) {
// 			console.error("[fetchSellers] error", e);
// 			return [];
// 		}
// 	},
// 	getSellers: async () => {
// 		try {
// 			const ret = await apiClient.get("/sellers");
// 			return ret.data?.data.items;
// 		} catch (e) {
// 			console.error("[fetchSellers] error", e);
// 			return [];
// 		}
// 	},
// 	getBrands: async () => {
// 		try {
// 			const ret = await apiClient.get("/sellers");
// 			return ret.data?.data.items;
// 		} catch (e) {
// 			console.error("[fetchSellers] error", e);
// 			return [];
// 		}
// 	},
// };

class InfoService {
	categories = [];
	sellers = [];

	init = async () => {
		console.log("[init] called");
		await this.fetchSellers();
		await this.fetchCategories();
	};

	fetchSellers = async () => {
		try {
			const ret = await commonClient.get(`/sellers`);
			this.sellers = ret.data?.data.items;
			console.log("[fetchSellers] this.sellers", this.sellers);
		} catch (e) {
			console.error("[fetchSellers] error", e);
			return [];
		}
	};

	fetchCategories = async () => {
		try {
			const ret = await commonClient.get(`/categories/root`);
			this.categories = ret.data?.data.items;
			console.log("[fetchCategories] this.categories", this.categories);
			// this.categories = ret.data?.data?.categories;
		} catch (e) {
			console.error("[fetchCategories] error", e);
		}
	};

	getSellers = () => {
		return this.sellers;
	};

	getRandomSellers = () => {
		return shuffle(this.sellers);
	};

	getCategories = () => {
		return this.categories;
	};
}

export const infoService = new InfoService();
