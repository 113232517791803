import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import ProtectedRoute from "routes/ProtectedRoute";
import WishlistPage from "pages/Mypage/WishlistPage";
import AddressPage from "pages/Mypage/AddressPage";
import CouponsPage from "pages/Mypage/CouponsPage";
import MyPageLayout from "pages/Mypage/MyPageLayout";
import OrdersPage from "pages/Mypage/OrdersPage";
import ProfilePage from "pages/Mypage/ProfilePage";
import PointPage from "pages/Mypage/PointPage";
import MyPage from "pages/Mypage";
import ReviewWritePage from "pages/Mypage/ReviewWritePage";
import ReviewWriteForm from "pages/Mypage/ReviewWriteForm";
import ReviewManagePage from "pages/Mypage/ReviewManagePage";
import ReviewEditForm from "pages/Mypage/ReviewEditForm";
import OrderActionListPage from "pages/Mypage/OrderActionListPage";

export default () => (
	<Routes>
		<Route
			path="/*"
			element={
				<ProtectedRoute>
					<MyPageLayout />
				</ProtectedRoute>
			}>
			<Route index element={<MyPage />} />
			{/* <Route index element={<Navigate to="/mypage/profile" />} /> */}
			<Route path="profile" element={<ProfilePage />} />
			<Route path="point" element={<PointPage />} />
			<Route path="orders" element={<OrdersPage />} />
			<Route path="returns" element={<OrderActionListPage />} />
			<Route path="wishlist" element={<WishlistPage />} />
			<Route path="review-write" element={<ReviewWritePage />}></Route>
			<Route path="review-write/:productId" element={<ReviewWriteForm />}></Route>
			<Route path="reviews" element={<ReviewManagePage />} />
			<Route path="review-edit/:reviewId" element={<ReviewEditForm />}></Route>
			<Route path="address" element={<AddressPage />} />
			<Route path="coupons" element={<CouponsPage />} />
		</Route>
	</Routes>
);
