import React, { useEffect, useState } from "react";
import authClient from "apis/auth";
import { useTranslation } from "react-i18next";
import PageWithBackButton from "./PageWithBackButton";
import { toast } from "react-toastify";
import { formatCurrency } from "utils/strUtil";

const API_URL = "/user/user-coupons";

interface Coupon {
	userCouponId: number;
	status: string;
	createdAt: string;
	usedAt?: string;
	coupon: {
		couponId: number;
		code: string;
		discountAmount?: number;
		discountPercentage?: number;
		minimumOrderAmount?: number;
		maximumDiscountAmount?: number;
		status: string;
		startDate: string;
		expiryDate: string;
	};
}

const CouponsPage: React.FC = () => {
	const { t, i18n } = useTranslation();
	const [coupons, setCoupons] = useState<Coupon[]>([]);
	const [loading, setLoading] = useState(true);
	const [couponCode, setCouponCode] = useState(""); // ✅ 쿠폰 코드 입력 상태

	// ✅ 쿠폰 목록 불러오기
	const fetchCoupons = async () => {
		try {
			const response = await authClient.get(API_URL);
			if (response.data.success) {
				setCoupons(response.data.data.items);
			}
		} catch (error) {
			console.error(t("coupon.fetch_error"), error);
			toast.error(t("coupon.fetch_error"));
		} finally {
			setLoading(false);
		}
	};

	// ✅ 쿠폰 등록 API 호출
	const handleRegisterCoupon = async () => {
		if (!couponCode.trim()) {
			toast.warn(t("coupon.enter_code"));
			return;
		}

		try {
			const response = await authClient.post("/user/user-coupons/assign/code", { code: couponCode });
			if (response.data.success) {
				toast.success(t("coupon.register_success"));
				setCouponCode(""); // 입력 필드 초기화
				fetchCoupons(); // 쿠폰 목록 새로고침
			} else {
				toast.error(t("coupon.register_fail"));
			}
		} catch (error) {
			console.error(t("coupon.invalid_code"), error);
			toast.error(t("coupon.invalid_code"));
		}
	};

	useEffect(() => {
		fetchCoupons();
	}, []);

	return (
		<PageWithBackButton>
			<div>
				<h2 className="text-2xl font-bold mb-4">{t("coupon.my_coupons")}</h2>

				{/* ✅ 쿠폰 등록 입력 필드 */}
				<div className="mb-6 flex space-x-2">
					<input
						type="text"
						value={couponCode}
						onChange={(e) => setCouponCode(e.target.value)}
						placeholder={t("coupon.enter_code_placeholder")}
						className="flex-1 p-2 border rounded-lg"
					/>
					<button onClick={handleRegisterCoupon} className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-700">
						{t("common.register")}
					</button>
				</div>

				{/* ✅ 쿠폰 목록 */}
				{loading ? (
					<p>{t("common.loading")}</p>
				) : coupons.length === 0 ? (
					<p className="text-gray-500">{t("coupon.no_available")}</p>
				) : (
					<div className="space-y-4">
						{coupons.map((coupon) => (
							<div
								key={coupon.userCouponId}
								className={`p-4 border rounded-lg shadow transition ${coupon.status === "active" ? "hover:shadow-lg" : "opacity-60"}`}>
								<div className="flex justify-between">
									<div>
										<p className="font-bold text-lg">{coupon.coupon.code}</p>

										{/* 할인 정보 */}
										<p className="text-sm text-gray-600">
											{coupon.coupon.discountAmount
												? `${formatCurrency(coupon.coupon.discountAmount, i18n.language)} ${t("coupon.discount")}`
												: `${coupon.coupon.discountPercentage}% ${t("coupon.discount")}`}
										</p>

										{/* 최소 주문 금액 표시 (있는 경우) */}
										{coupon.coupon.minimumOrderAmount && (
											<p className="text-xs text-gray-500">
												{t("coupon.minimum_order")}: {formatCurrency(coupon.coupon.minimumOrderAmount, i18n.language)}
											</p>
										)}

										{/* 최대 할인 금액 표시 (있는 경우) */}
										{coupon.coupon.maximumDiscountAmount && (
											<p className="text-xs text-gray-500">
												{t("coupon.maximum_discount")}: {formatCurrency(coupon.coupon.maximumDiscountAmount, i18n.language)}
											</p>
										)}

										{/* 유효 기간 */}
										<p className="text-xs text-gray-500">
											{t("coupon.validity")}: {new Date(coupon.coupon.startDate).toLocaleDateString()} ~{" "}
											{new Date(coupon.coupon.expiryDate).toLocaleDateString()}
										</p>
									</div>

									{/* 쿠폰 상태 */}
									<p
										className={`text-sm font-semibold ${
											coupon.status === "active" ? "text-green-500" : coupon.status === "used" ? "text-gray-500" : "text-red-500"
										}`}>
										{coupon.status === "active" ? t("coupon.active") : coupon.status === "used" ? t("coupon.used") : t("coupon.expired")}
									</p>
								</div>
							</div>
						))}
					</div>
				)}
			</div>
		</PageWithBackButton>
	);
};

export default CouponsPage;
