import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import authClient from "apis/auth";
import PageWithBackButton from "./PageWithBackButton";

const REVIEW_API_URL = "/user/reviews";

interface Review {
	reviewId: number;
	product: {
		productId: number;
		name: string;
		mainImage: string;
	};
	rating: number;
	content: string;
	images: string[];
	createdAt: string;
}

const ReviewManagePage: React.FC = () => {
	const { t } = useTranslation();
	const [reviews, setReviews] = useState<Review[]>([]);
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();

	// 내가 작성한 리뷰 목록 불러오기
	const fetchReviews = async () => {
		try {
			const response = await authClient.get(REVIEW_API_URL);
			if (response.data.success) {
				setReviews(response.data.data.items);
			}
		} catch (error) {
			console.error(t("review.fetch_error"), error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchReviews();
	}, []);

	// 리뷰 삭제
	const handleDeleteReview = async (reviewId: number) => {
		if (!window.confirm(t("review.confirm_delete"))) return;

		try {
			await authClient.delete(`${REVIEW_API_URL}/${reviewId}`);
			alert(t("review.delete_success"));
			setReviews(reviews.filter((review) => review.reviewId !== reviewId));
		} catch (error) {
			console.error(t("review.delete_error"), error);
			alert(t("review.delete_error"));
		}
	};

	return (
		<PageWithBackButton>
			<div>
				<h2 className="text-2xl font-bold mb-4">{t("review.manage_title")}</h2>

				{loading ? (
					<p>{t("common.loading")}</p>
				) : reviews.length === 0 ? (
					<p className="text-gray-500">{t("review.no_reviews")}</p>
				) : (
					<div className="space-y-4">
						{reviews.map((review) => (
							<div key={review.reviewId} className="p-4 border rounded-lg shadow">
								<div className="flex items-center gap-4">
									{/* 상품 이미지 */}
									<img
										onClick={() => navigate(`/product/${review.product.productId}`)}
										src={review.product.mainImage}
										alt={review.product.name}
										className="w-20 h-20 object-cover rounded-md"
									/>

									{/* 리뷰 내용 */}
									<div className="flex-grow">
										<p className="font-bold" onClick={() => navigate(`/product/${review.product.productId}`)}>
											{review.product.name}
										</p>
										<div className="flex space-x-1 text-yellow-400">
											{Array.from({ length: review.rating }, (_, i) => (
												<span key={i}>⭐</span>
											))}
										</div>
										<p className="text-sm text-gray-500 mt-1">{review.content}</p>
										<p className="text-xs text-gray-400 mt-1">
											{t("review.created_at")}: {new Date(review.createdAt).toLocaleDateString()}
										</p>
									</div>

									{/* 수정 & 삭제 버튼 */}
									<div className="flex flex-col gap-2">
										<button
											onClick={() => navigate(`/mypage/review-edit/${review.reviewId}`)}
											className="px-3 py-1 text-sm bg-blue-500 text-white rounded-md hover:bg-blue-600 transition">
											{t("common.edit")}
										</button>
										<button
											onClick={() => handleDeleteReview(review.reviewId)}
											className="px-3 py-1 text-sm bg-red-500 text-white rounded-md hover:bg-red-600 transition">
											{t("common.delete")}
										</button>
									</div>
								</div>
							</div>
						))}
					</div>
				)}
			</div>
		</PageWithBackButton>
	);
};

export default ReviewManagePage;
