// src/routes/AppRoutes.tsx
import PublicTopNav from "containers/TopNav/public";
import { UserProvider } from "contexts/UserProvider";
import VerifyEmail from "pages/auth/VerifyEmail";
import CategoryPage from "pages/Category";
import CategoryDetailPage from "pages/Category/detail";
import FrontPage from "pages/FrontPage";
import PreviewPage from "pages/Preview";
import React from "react";
import { Route, Routes } from "react-router-dom";
import MypageRoute from "routes/Mypage";
import OrderRoute from "routes/Order";
import ProductRoute from "routes/Product";
import BrandRoute from "routes/Brand";
import CategoryRoute from "routes/Category";
import Login from "../pages/auth/Login";
import Signup from "../pages/auth/Signup";
import ProtectedRoute from "./ProtectedRoute";
import ChangePasswordPage from "pages/Mypage/ChangePasswordPage";
import TermsPage from "pages/Footer/Terms";
import PrivacyPolicyPage from "pages/Footer/PrivacyPolicy";
import PaymentAgencyPage from "pages/Footer/PaymentAgency";
import DisputeResolutionPage from "pages/Footer/DisputeResolution";
import LineCallback from "pages/auth/LineCallback";
import LineSignup from "pages/auth/LineSignup";
import GoogleCallback from "pages/auth/GoogleCallback";
import GoogleSignup from "pages/auth/GoogleSignup";

const AppRoutes: React.FC = () => {
	return (
		<UserProvider>
			<PublicTopNav />
			<Routes>
				{/* Public Routes */}
				<Route path="/" element={<FrontPage />} />
				<Route path="/login" element={<Login />} />
				<Route path="/login/line-callback" element={<LineCallback />} />
				<Route path="/signup/line" element={<LineSignup />} />
				<Route path="/login/google-callback" element={<GoogleCallback />} />
				<Route path="/signup/google" element={<GoogleSignup />} />
				<Route path="/signup" element={<Signup />} />
				<Route path="/verify-email" element={<VerifyEmail />} />
				<Route path="/change-password" element={<ChangePasswordPage />} />

				<Route path="/terms" element={<TermsPage />} />
				<Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
				<Route path="/payment-agency" element={<PaymentAgencyPage />} />
				<Route path="/dispute-resolution" element={<DisputeResolutionPage />} />

				<Route path="/preview" element={<PreviewPage />} />
				<Route path="/product/*" element={<ProductRoute />}></Route>
				<Route path="/brand/*" element={<BrandRoute />}></Route>
				<Route path="/category/*" element={<CategoryRoute />}></Route>
				{/* Routes with PrivateRoute */}
				<Route
					path="/mypage/*"
					element={
						<ProtectedRoute>
							<MypageRoute />
						</ProtectedRoute>
					}></Route>
				<Route
					path="/order/*"
					element={
						<ProtectedRoute>
							<OrderRoute />
						</ProtectedRoute>
					}></Route>
			</Routes>
		</UserProvider>
	);
};

export default AppRoutes;
