import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { authService } from "services/authService";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const LineSignup: React.FC = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [email, setEmail] = useState<string | null>(null);
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [name, setName] = useState("");
	// const [phoneNumber, setPhoneNumber] = useState("");
	const [verificationCode, setVerificationCode] = useState("");
	const [isEmailVerified, setIsEmailVerified] = useState(false);
	const [isCodeSent, setIsCodeSent] = useState(false);
	const [canResendCode, setCanResendCode] = useState(true);
	const [timer, setTimer] = useState(0);
	const [isSendingCode, setIsSendingCode] = useState(false);
	const [isNewUser, setIsNewUser] = useState(false);

	useEffect(() => {
		const handleLineLogin = async () => {
			const params = new URLSearchParams(window.location.search);
			const code = params.get("code");

			if (!code) {
				toast.error(t("signup.line_auth_failed"));
				navigate("/login");
				return;
			}

			try {
				const response = await authService.lineLogin(code);
				setIsNewUser(response.isNewUser);

				if (response.email) {
					setEmail(response.email);
					setIsEmailVerified(true); // 라인에서 이메일이 제공된 경우, 인증된 것으로 간주
				}
			} catch (error) {
				toast.error(t("signup.line_auth_failed"));
				navigate("/login");
			}
		};

		handleLineLogin();
	}, [navigate, t]);

	// 타이머 관리
	useEffect(() => {
		let interval: NodeJS.Timeout | null = null;
		if (!canResendCode && timer > 0) {
			interval = setInterval(() => setTimer((prev) => prev - 1), 1000);
		} else if (timer <= 0) {
			setCanResendCode(true);
			if (interval) clearInterval(interval);
		}
		return () => {
			if (interval) clearInterval(interval);
		};
	}, [canResendCode, timer]);

	const handleSendVerificationCode = async () => {
		if (!email) {
			toast.warn(t("signup.email_required"));
			return;
		}

		setIsSendingCode(true);
		try {
			const emailCheckResponse = await authService.checkEmailAvailability(email);
			if (emailCheckResponse.exists) {
				toast.warn(t("signup.email_already_registered"));
				setIsSendingCode(false);
				return;
			}

			const response = await authService.sendVerificationCode(email);
			if (response.status === 201) {
				toast.success(t("signup.verification_code_sent"));
				setIsCodeSent(true);
				setCanResendCode(false);
				setTimer(60);
			}
		} catch (error) {
			console.error("Failed to send verification code:", error);
			toast.error(t("signup.verification_code_failed"));
		} finally {
			setIsSendingCode(false);
		}
	};

	const handleVerifyCode = async () => {
		try {
			const response = await authService.verifyCode(email!, verificationCode);
			if (response.verified) {
				toast.success(t("signup.email_verified"));
				setIsEmailVerified(true);
			} else {
				toast.warn(t("signup.invalid_verification_code"));
			}
		} catch (error) {
			console.error("Verification failed:", error);
			toast.error(t("signup.verification_failed"));
		}
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();

		if (!isEmailVerified) {
			toast.warn(t("signup.verify_email_first"));
			return;
		}

		if (password !== confirmPassword) {
			toast.warn(t("signup.password_mismatch"));
			return;
		}

		try {
			const response = await authService.signupWithLine(email!, name);
			if (response.status === 201) {
				toast.success(t("signup.welcome_message"));
				navigate("/");
			}
		} catch (error) {
			console.error("Sign-up failed:", error);
			toast.error(t("signup.signup_failed"));
		}
	};

	return (
		<div className="min-h-screen flex items-center justify-center bg-gray-50">
			<div className="max-w-lg w-full bg-white p-8 shadow rounded-md">
				<h2 className="text-2xl font-bold text-center mb-4">{t("signup.title")}</h2>
				<form onSubmit={handleSubmit} className="space-y-4">
					{/* Email Input - 라인에서 이메일을 제공하지 않은 경우 */}
					{!email && (
						<div>
							<label htmlFor="email" className="block text-sm font-medium text-gray-700">
								{t("signup.email")}
							</label>
							<div className="flex space-x-2">
								<input
									type="email"
									id="email"
									value={email || ""}
									onChange={(e) => setEmail(e.target.value)}
									required
									className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-sm"
								/>
								<button
									type="button"
									onClick={handleSendVerificationCode}
									disabled={isSendingCode || (isCodeSent && !canResendCode)}
									className="bg-rose-400 text-white py-2 px-4 rounded-md text-sm flex items-center justify-center disabled:opacity-50">
									{isSendingCode ? (
										<>
											<span className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin mr-2"></span>
											{t("signup.sending")}
										</>
									) : !isCodeSent || canResendCode ? (
										t("signup.send_code")
									) : (
										`${t("signup.resend_in")} ${timer}s`
									)}
								</button>
							</div>
						</div>
					)}

					{/* Verification Code */}
					{isCodeSent && !isEmailVerified && (
						<div>
							<label htmlFor="verificationCode" className="block text-sm font-medium text-gray-700">
								{t("signup.verification_code")}
							</label>
							<div className="flex space-x-2">
								<input
									type="text"
									id="verificationCode"
									value={verificationCode}
									onChange={(e) => setVerificationCode(e.target.value)}
									required
									className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-sm"
								/>
								<button
									type="button"
									onClick={handleVerifyCode}
									disabled={isEmailVerified}
									className="bg-rose-400 text-white py-2 px-4 rounded-md text-sm disabled:opacity-50">
									{isEmailVerified ? t("signup.verified") : t("signup.verify")}
								</button>
							</div>
						</div>
					)}

					{/* 기타 정보 입력 */}
					<div>
						<label htmlFor="name" className="block text-sm font-medium text-gray-700">
							{t("signup.name")}
						</label>
						<input
							type="text"
							id="name"
							value={name}
							onChange={(e) => setName(e.target.value)}
							required
							className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-sm"
						/>
					</div>

					{/* <div key="phoneNumber">
						<label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700">
							{t(`signup.phoneNumber`)}
						</label>
						<input
							type="text"
							id="phoneNumber"
							value={eval(phoneNumber)}
							onChange={(e) => eval(`set${"phoneNumber".charAt(0).toUpperCase() + "phoneNumber".slice(1)}`)(e.target.value)}
							required
							className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-sm"
						/>
					</div> */}
					{/* 비밀번호 입력 */}
					{/* <div>
						<label htmlFor="password" className="block text-sm font-medium text-gray-700">
							{t("signup.password")}
						</label>
						<input
							type="password"
							id="password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							required
							className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-sm"
						/>
					</div>
					<div>
						<label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
							{t("signup.confirmPassword")}
						</label>
						<input
							type="password"
							id="confirmPassword"
							value={confirmPassword}
							onChange={(e) => setConfirmPassword(e.target.value)}
							required
							className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-sm"
						/>
					</div> */}

					<button type="submit" className="w-full bg-rose-400 text-white py-2 px-4 rounded-md">
						{t("signup.sign_up")}
					</button>
				</form>
			</div>
		</div>
	);
};

export default LineSignup;
