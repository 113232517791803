import { useTranslation } from "react-i18next";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import React from "react";
import { Cookies } from "react-cookie";
import { COOKIE_DOMAIN } from "constants/config";

const cookies = new Cookies();

const LanguageSwitcher = () => {
	const { i18n } = useTranslation();
	cookies.set("locale", `${i18n.language}`, { path: "/" });

	const changeLanguage = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const selectedLanguage = event.target.value;
		i18n.changeLanguage(selectedLanguage);
		console.log("Language changed to:", i18n.language);
		// cookies.set("locale", `${i18n.language}`, { path: "/" });
		cookies.set("locale", `${i18n.language}`, { path: "/", domain: COOKIE_DOMAIN ?? ".39f.jp", sameSite: "none", secure: true });

		window.location.reload();
	};

	return (
		<>
			<label htmlFor="mobile-language" className="sr-only">
				Language
			</label>
			<div className="group relative -ml-2 rounded-md border-transparent focus-within:ring-2 focus-within:ring-white">
				<select
					id="mobile-language"
					name="language"
					value={i18n.language} // 현재 설정된 언어 선택 유지
					onChange={changeLanguage} // 이벤트 핸들러 수정
					className="flex items-center rounded-md border-transparent bg-none py-0.5 pl-2 pr-5 text-sm font-medium text-gray-700 focus:border-transparent focus:outline-none focus:ring-0 group-hover:text-gray-800">
					<option value="ja">🇯🇵 日本語</option>
					<option value="ko">🇰🇷 한국어</option>
					{/* <option value="en">🇬🇧 English</option> */}
				</select>
				<div className="pointer-events-none absolute top-0 right-0 flex items-center">
					{/* <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center"> */}
					<ChevronDownIcon aria-hidden="true" className="h-5 w-5 text-gray-500" />
				</div>
			</div>
		</>
	);
};

export default LanguageSwitcher;
