import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import authClient from "apis/auth";
import PageWithBackButton from "./PageWithBackButton";
import { formatCurrency } from "utils/strUtil";

const REVIEW_API_URL = "/user/reviews";

const ReviewEditForm: React.FC = () => {
	const { t, i18n } = useTranslation();
	const { reviewId } = useParams<{ reviewId: string }>();
	const navigate = useNavigate();

	const [review, setReview] = useState<{
		rating: number;
		content: string;
		images: {
			image_id: number;
			url: string;
		}[];
		product: {
			productId: string;
			name: string;
			mainImage: string;
			price: number;
			discountPrice: number;
		};
	} | null>(null);
	const [loading, setLoading] = useState<boolean>(true);
	const [newContent, setNewContent] = useState<string>("");
	const [newRating, setNewRating] = useState<number>(5);

	// 기존 리뷰 데이터 불러오기
	useEffect(() => {
		const fetchReview = async () => {
			try {
				const response = await authClient.get(`${REVIEW_API_URL}/${reviewId}`);
				if (response.data.success) {
					const data = response.data.data;
					setReview(data);
					setNewContent(data.content);
					setNewRating(data.rating);
				}
			} catch (error) {
				console.error(t("review.fetch_error"), error);
			} finally {
				setLoading(false);
			}
		};

		fetchReview();
	}, [reviewId, t]);

	// 리뷰 수정 제출
	const handleUpdateReview = async () => {
		if (!newContent.trim()) {
			alert(t("review.empty_content_error"));
			return;
		}

		try {
			await authClient.patch(`${REVIEW_API_URL}/${reviewId}`, {
				rating: newRating,
				content: newContent,
			});
			alert(t("review.update_success"));
			navigate("/mypage/reviews");
		} catch (error) {
			console.error(t("review.update_error"), error);
			alert(t("review.update_error"));
		}
	};

	return (
		<PageWithBackButton>
			<div>
				<h2 className="text-2xl font-bold mb-4">{t("review.edit_title")}</h2>

				{loading ? (
					<p>{t("common.loading")}</p>
				) : review ? (
					<div className="space-y-4">
						{/* 상품 정보 표시 */}
						<div className="p-4 border rounded-lg shadow flex gap-4" onClick={() => navigate(`/product/${review.product.productId}`)}>
							<img src={review.product.mainImage} alt={review.product.name} className="w-24 h-24 object-cover rounded-md" />
							<div>
								<p className="font-bold">{review.product.name}</p>
								<p className="text-gray-500">
									{t("review.price")}:{" "}
									<span className="text-red-500 font-semibold">{formatCurrency(review.product.price, i18n.language)}</span>
									{review.product.discountPrice && (
										<span className="ml-2 line-through text-gray-400">{formatCurrency(review.product.discountPrice, i18n.language)}</span>
									)}
								</p>
							</div>
						</div>

						{/* 리뷰 이미지 갤러리 */}
						<div>
							<label className="block text-sm font-semibold">{t("review.images")}</label>
							{review.images.length > 0 ? (
								<div className="flex space-x-2 overflow-x-auto mt-2 p-2 border rounded-lg">
									{review.images.map((image, index) => (
										<img
											key={index}
											src={image.url}
											alt={`${t("review.image")} ${index + 1}`}
											className="w-32 h-32 object-cover rounded-md"
										/>
									))}
								</div>
							) : (
								<p className="text-gray-500 mt-2">{t("review.no_images")}</p>
							)}
						</div>

						{/* 별점 선택 */}
						<div>
							<label className="block text-sm font-semibold">{t("review.select_rating")}</label>
							<div className="flex space-x-2 mt-2">
								{[1, 2, 3, 4, 5].map((num) => (
									<button
										key={num}
										onClick={() => setNewRating(num)}
										className={`w-8 h-8 flex items-center justify-center rounded-full ${
											newRating >= num ? "bg-yellow-400" : "bg-gray-200"
										}`}>
										⭐
									</button>
								))}
							</div>
						</div>

						{/* 리뷰 내용 입력 */}
						<div>
							<label className="block text-sm font-semibold">{t("review.content")}</label>
							<textarea
								value={newContent}
								onChange={(e) => setNewContent(e.target.value)}
								className="w-full h-24 border rounded p-2 mt-1"
								placeholder={t("review.content_placeholder")}
							/>
						</div>

						{/* 수정 버튼 */}
						<button onClick={handleUpdateReview} className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition">
							{t("review.update")}
						</button>
					</div>
				) : (
					<p className="text-gray-500">{t("review.not_found")}</p>
				)}
			</div>
		</PageWithBackButton>
	);
};

export default ReviewEditForm;
