import React, { useEffect, useState } from "react";
import authClient from "apis/auth";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PageWithBackButton from "./PageWithBackButton";
import { formatCurrency } from "utils/strUtil";

const OrderActionListPage: React.FC = () => {
	const { t, i18n } = useTranslation();
	const [orderActions, setOrderActions] = useState<any[]>([]);
	const [loading, setLoading] = useState(true);
	const [selectedType, setSelectedType] = useState("CANCEL");
	const navigate = useNavigate();

	// API 호출
	useEffect(() => {
		const fetchOrderActions = async () => {
			try {
				setLoading(true);
				const response = await authClient.get(`/user/orders/actions?type=${selectedType}`);
				setOrderActions(response.data.data.items);
			} catch (error) {
				console.error("Failed to fetch order actions:", error);
			} finally {
				setLoading(false);
			}
		};
		fetchOrderActions();
	}, [selectedType]);

	// 필터링 버튼
	const renderFilterButton = (type: string, label: string) => (
		<button
			className={`px-4 py-2 rounded-md ${selectedType === type ? "bg-rose-400 text-white" : "bg-gray-100 text-gray-800"}`}
			onClick={() => setSelectedType(type)}>
			{t(label)}
		</button>
	);

	return (
		<PageWithBackButton>
			<div className="bg-white">
				<div className="max-w-5xl mx-auto px-4 py-10 sm:px-6 lg:px-8">
					<h1 className="text-2xl font-bold text-gray-900 sm:text-3xl">{t("order.history_title")}</h1>

					{/* 필터 버튼 */}
					<div className="mt-6 flex space-x-4">
						{renderFilterButton("CANCEL", "order.cancel_history")}
						{renderFilterButton("RETURN", "order.return_history")}
						{renderFilterButton("EXCHANGE", "order.exchange_history")}
					</div>

					{/* 로딩 상태 */}
					{loading ? (
						<p className="text-center text-gray-600 mt-6">{t("common.loading")}</p>
					) : orderActions.length === 0 ? (
						<p className="text-center text-gray-600 mt-6">{t("order.no_history")}</p>
					) : (
						<div className="mt-6 overflow-x-auto">
							<table className="min-w-full divide-y divide-gray-200 border">
								<thead className="bg-gray-100">
									<tr>
										<th className="px-4 py-3 text-left text-sm font-medium text-gray-600">{t("order.number")}</th>
										<th className="px-4 py-3 text-left text-sm font-medium text-gray-600">{t("order.order_date")}</th>
										<th className="px-4 py-3 text-left text-sm font-medium text-gray-600">{t("order.processed_date")}</th>
										<th className="px-4 py-3 text-right text-sm font-medium text-gray-600">{t("order.amount")}</th>
										<th className="px-4 py-3 text-left text-sm font-medium text-gray-600">{t("order.status")}</th>
										<th className="px-4 py-3 text-right text-sm font-medium text-gray-600">{t("order.detail")}</th>
									</tr>
								</thead>
								<tbody className="divide-y divide-gray-200 bg-white">
									{orderActions.map((action) => (
										<tr key={action.orderItemId}>
											<td className="px-4 py-3 text-sm text-gray-900">{action.order.orderId}</td>
											<td className="px-4 py-3 text-sm text-gray-600">{format(new Date(action.order.createdAt), "yyyy-MM-dd")}</td>
											<td className="px-4 py-3 text-sm text-gray-600">{format(new Date(action.updatedAt), "yyyy-MM-dd")}</td>
											<td className="px-4 py-3 text-sm text-gray-900 text-right">{formatCurrency(action.price, i18n.language)}</td>
											<td className="px-4 py-3 text-sm text-gray-600">
												{action.cancel_status || action.return_status || action.exchange_status}
											</td>
											<td className="px-4 py-3 text-right">
												<button
													onClick={() => navigate(`/order/details/${action.order.orderId}`)}
													className="text-rose-400 hover:text-rose-600 text-sm font-medium">
													{t("order.view_details")} →
												</button>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					)}
				</div>
			</div>
		</PageWithBackButton>
	);
};

export default OrderActionListPage;
