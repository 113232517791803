import React, { useEffect, useState } from "react";
import authClient from "apis/auth";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PageWithBackButton from "./PageWithBackButton";
import i18n from "locales/i18n";
import { formatCurrency } from "utils/strUtil";

const OrdersPage: React.FC = () => {
	const { t } = useTranslation();
	const [orders, setOrders] = useState<any[]>([]);
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();

	useEffect(() => {
		const fetchOrders = async () => {
			try {
				const response = await authClient.get("/user/orders");
				setOrders(response.data.data.items);
			} catch (error) {
				console.error(t("order.fetch_error"), error);
			} finally {
				setLoading(false);
			}
		};

		fetchOrders();
	}, [t]);

	if (loading) {
		return <p className="text-center text-gray-600">{t("common.loading")}</p>;
	}

	if (orders.length === 0) {
		return (
			<PageWithBackButton>
				<p className="text-center text-gray-600">{t("order.no_orders")}</p>
			</PageWithBackButton>
		);
	}

	return (
		<PageWithBackButton>
			<div className="bg-white">
				<div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8 lg:pb-24">
					<div className="max-w-xl">
						<h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">{t("order.history")}</h1>
						<p className="mt-2 text-sm text-gray-500">{t("order.history_description")}</p>
					</div>

					<div className="mt-16">
						<h2 className="sr-only">{t("order.recent_orders")}</h2>

						<div className="space-y-20">
							{orders.map((order) => (
								<div key={order.orderId}>
									<h3 className="sr-only">
										{t("order.placed_on")} <time dateTime={order.createdAt}>{format(new Date(order.createdAt), "yyyy-MM-dd")}</time>
									</h3>

									<div className="rounded-lg bg-gray-50 px-4 py-6 sm:flex sm:items-center sm:justify-between sm:space-x-6 sm:px-6 lg:space-x-8">
										<dl className="flex-auto space-y-6 divide-y divide-gray-200 text-sm text-gray-600 sm:grid sm:grid-cols-3 sm:gap-x-6 sm:space-y-0 sm:divide-y-0 lg:w-1/2 lg:flex-none lg:gap-x-8">
											<div className="flex justify-between sm:block">
												<dt className="font-medium text-gray-900">{t("order.date_placed")}</dt>
												<dd className="sm:mt-1">
													<time dateTime={order.createdAt}>{format(new Date(order.createdAt), "yyyy-MM-dd")}</time>
												</dd>
											</div>
											<div className="flex justify-between pt-6 sm:block sm:pt-0">
												<dt className="font-medium text-gray-900">{t("order.order_id")}</dt>
												<dd className="sm:mt-1">{order.orderId}</dd>
											</div>
											<div className="flex justify-between pt-6 font-medium text-gray-900 sm:block sm:pt-0">
												<dt>{t("order.total_amount")}</dt>
												<dd className="sm:mt-1">{formatCurrency(order.totalPrice, i18n.language).toLocaleString()}</dd>
											</div>
										</dl>
										<button
											onClick={() => navigate(`/order/details/${order.orderId}`)}
											className="mt-6 flex w-full items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto">
											{t("order.view_order")}
											<span className="sr-only">
												{t("order.for_order")} {order.orderId}
											</span>
										</button>
									</div>

									<table className="mt-4 w-full text-gray-500 sm:mt-6">
										<caption className="sr-only">{t("order.products")}</caption>
										<thead className="sr-only text-left text-sm text-gray-500 sm:not-sr-only">
											<tr>
												<th scope="col" className="py-3 pr-8 font-normal sm:w-2/5 lg:w-1/3">
													{t("order.product")}
												</th>
												<th scope="col" className="hidden w-1/5 py-3 pr-8 font-normal sm:table-cell">
													{t("order.price")}
												</th>
												<th scope="col" className="hidden py-3 pr-8 font-normal sm:table-cell">
													{t("order.quantity")}
												</th>
												<th scope="col" className="w-0 py-3 text-right font-normal">
													{t("order.info")}
												</th>
											</tr>
										</thead>
										<tbody className="divide-y divide-gray-200 border-b border-gray-200 text-sm sm:border-t">
											{order.items.map((item) => (
												<tr key={item.orderItemId}>
													<td className="py-6 pr-8">
														<div className="flex items-center">
															<img
																alt={item.product?.name || "Product Image"}
																src={item.product?.mainImage || "https://via.placeholder.com/100"}
																className="mr-6 size-16 rounded object-cover"
															/>
															<div>
																<div className="font-medium text-gray-900">
																	{item.product?.name || t("order.unknown_product")}
																</div>
																<div className="mt-1 sm:hidden">{formatCurrency(item.price, i18n.language)}</div>
															</div>
														</div>
													</td>
													<td className="hidden py-6 pr-8 sm:table-cell">{formatCurrency(item.price, i18n.language)}</td>
													<td className="hidden py-6 pr-8 sm:table-cell">{item.quantity}</td>
													<td className="whitespace-nowrap py-6 text-right font-medium">
														<button onClick={() => navigate(`/product/${item.product?.productId}`)} className="text-rose-400">
															{t("order.view_product")}
															<span className="sr-only">{item.product?.name}</span>
														</button>
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</PageWithBackButton>
	);
};

export default OrdersPage;
