import React from "react";
import { Routes, Route } from "react-router-dom";

import ShoppingCart from "pages/Order/shoppingCartPage";
import OrderCheckout from "pages/Order/checkoutPage";
import OrderDetailPage from "pages/Mypage/OrderDetailPage";

export default () => (
	<Routes>
		<Route path="/shopping-cart" element={<ShoppingCart />}></Route>
		<Route path="/checkout" element={<OrderCheckout />}></Route>
		<Route path="/details/:orderId" element={<OrderDetailPage />} />
	</Routes>
);
