import { useUser } from "contexts/UserProvider";
import React from "react";
import { Navigate, useLocation } from "react-router-dom";

interface PrivateRouteProps {
	element?: React.ReactNode; // `element` 형태
	children?: React.ReactNode; // `children` 형태
}

const ProtectedRoute: React.FC<PrivateRouteProps> = ({ element, children }) => {
	const { user } = useUser();
	const location = useLocation();

	if (!user) {
		// 로그인 상태가 아니라면 로그인 페이지로 리다이렉트
		// return <Navigate to="/login" replace />;
		return <Navigate to="/login" state={{ from: location }} replace />;
	}

	// `element` prop이 우선, 없으면 `children`을 사용
	return <>{element || children}</>;
};

export default ProtectedRoute;
