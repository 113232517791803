// src/config.js
export const SERVER_DOMAIN = process.env.REACT_APP_SERVER_DOMAIN;
export const ADMIN_SERVER_DOMAIN = process.env.REACT_APP_ADMIN_SERVER_DOMAIN;

export const COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN;

export const LINE_CLIENT_ID = process.env.REACT_APP_LINE_CLIENT_ID;
export const LINE_REDIRECT_URI = process.env.REACT_APP_LINE_REDIRECT_URI;
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const GOOGLE_REDIRECT_URI = process.env.REACT_APP_GOOGLE_REDIRECT_URI;
