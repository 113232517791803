import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

const Footer: React.FC = () => {
	const { t } = useTranslation();
	const [openSections, setOpenSections] = useState<{ [key: string]: boolean }>({
		company: true,
		support: true,
		notice: true,
		partner: true,
		policy: true,
	});

	// ✅ 섹션 열고 닫기 (각 섹션별 독립적으로 동작)
	const handleToggle = (section: string) => {
		console.log(section);
		setOpenSections((prev) => ({
			...prev,
			[section]: !prev[section],
		}));
	};

	// ✅ 페이지 최상단으로 스크롤 이동
	const scrollToTop = () => {
		window.scrollTo(0, 0);
	};

	return (
		<footer className="bg-gray-900 text-gray-300 py-8">
			<div className="max-w-7xl mx-auto px-6 lg:px-8">
				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
					{/* ✅ 공통 Disclosure 컴포넌트 */}
					{[
						{
							key: "company",
							title: t("footer.company_info"),
							content: (
								<>
									<p className="text-sm mt-2">{t("footer.company_name")}: 39F</p>
									<p className="text-sm">
										{t("footer.ceo_name")}: {t("footer.ceo_name_value")}
									</p>
									<p className="text-sm">{t("footer.business_number")}: 306-87-03492</p>
									<p className="text-sm">
										{t("footer.business_address")}: {t("footer.business_address_value")}
									</p>
								</>
							),
						},
						{
							key: "support",
							title: t("footer.customer_support"),
							content: (
								<>
									<p className="text-sm mt-2">{t("footer.email")}: support@39f.jp</p>
									<p className="text-sm">{t("footer.contact")}: 1588-1234</p>
									<p className="text-sm">
										{t("footer.business_hours")}: {t("footer.business_hours_value")}
									</p>
									<ul className="mt-2 space-y-1">
										<li>
											<Link to="/support/faq" className="text-sm hover:text-white" onClick={scrollToTop}>
												{t("footer.faq")}
											</Link>
										</li>
										<li>
											<Link to="/support/inquiry" className="text-sm hover:text-white" onClick={scrollToTop}>
												{t("footer.inquiry")}
											</Link>
										</li>
									</ul>
								</>
							),
						},
						{
							key: "notice",
							title: t("footer.notice"),
							content: (
								<ul className="mt-2 space-y-1">
									<li>
										<Link to="/notice" className="text-sm hover:text-white" onClick={scrollToTop}>
											{t("footer.notice_list")}
										</Link>
									</li>
								</ul>
							),
						},
						{
							key: "partner",
							title: t("footer.partner_support"),
							content: <p className="text-sm mt-2">{t("footer.partner_inquiry")}: partner@39f.jp</p>,
						},
						{
							key: "policy",
							title: t("footer.policy_terms"),
							content: (
								<ul className="mt-2 space-y-1">
									<li>
										<Link to="/terms" className="text-sm hover:text-white" onClick={scrollToTop}>
											{t("footer.terms_of_service")}
										</Link>
									</li>
									<li>
										<Link to="/privacy-policy" className="text-sm hover:text-white" onClick={scrollToTop}>
											{t("footer.privacy_policy")}
										</Link>
									</li>
									<li>
										<Link to="/dispute-resolution" className="text-sm hover:text-white" onClick={scrollToTop}>
											{t("footer.dispute_resolution")}
										</Link>
									</li>
									<li>
										<Link to="/payment-agency" className="text-sm hover:text-white" onClick={scrollToTop}>
											{t("footer.payment_agency")}
										</Link>
									</li>
								</ul>
							),
						},
					].map(({ key, title, content }) => (
						<Disclosure key={key} as="div">
							{() => (
								<div>
									<button
										onClick={() => handleToggle(key)}
										className="flex w-full justify-between text-lg font-bold text-white lg:cursor-default">
										{title}
										<ChevronDownIcon className={`h-5 w-5 transition-transform ${openSections[key] ? "rotate-180" : ""} lg:hidden`} />
									</button>
									{openSections[key] && <div className="mt-2">{content}</div>}
								</div>
							)}
						</Disclosure>
					))}
				</div>

				{/* ✅ 카피라이트 */}
				<div className="mt-6 border-t border-gray-700 pt-4 text-center text-sm">
					<p>© PINEAPPLESHERBET. {t("footer.all_rights_reserved")}</p>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
