import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const VerifyEmail: React.FC = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const email = location.state?.email || "your email";

	const handleVerification = () => {
		alert("Email verified! Redirecting to login...");
		navigate("/login");
	};

	return (
		<div className="min-h-screen flex items-center justify-center bg-gray-50">
			<div className="max-w-md w-full bg-white p-8 shadow rounded-md text-center">
				<h2 className="text-2xl font-bold mb-4">Verify Your Email</h2>
				<p className="text-sm text-gray-700 mb-6">
					A verification link has been sent to <span className="font-semibold">{email}</span>. Please check your inbox and click the link to verify
					your email.
				</p>
				<button onClick={handleVerification} className="w-full bg-rose-400 text-white py-2 px-4 rounded-md hover:bg-rose-500">
					I have verified
				</button>
			</div>
		</div>
	);
};

export default VerifyEmail;
