import React from "react";
import { useTranslation } from "react-i18next";

interface TableData {
	title: string;
	columns: string[];
	data: { [key: string]: string }[];
}

const PrivacyPolicyPage: React.FC = () => {
	const { t } = useTranslation();

	const renderTable = (table: TableData, keyPrefix: string) => (
		<div className="mt-6">
			<h3 className="text-xl font-semibold text-gray-900">{table.title}</h3>
			<div className="overflow-x-auto mt-4">
				<table className="w-full border-collapse border border-gray-300">
					<thead>
						<tr className="bg-gray-100">
							{table.columns.map((col, colIndex) => (
								<th key={colIndex} className="border border-gray-300 px-4 py-2 text-left">
									{col}
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{table.data.map((row, rowIndex) => (
							<tr key={rowIndex} className="border-b border-gray-200">
								{Object.keys(row).map((key, colIndex) => (
									<td key={colIndex} className="border border-gray-300 px-4 py-2 whitespace-pre-wrap">
										{row[key]}
									</td>
								))}
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);

	return (
		<div className="bg-white py-10 px-6 sm:px-10 lg:px-20">
			<div className="max-w-5xl mx-auto">
				<h1 className="text-3xl font-bold text-gray-900">{t("privacy.title")}</h1>
				<p className="text-sm text-gray-600 mt-2">{t("privacy.intro")}</p>
				<p className="text-sm text-gray-600 mt-2">{t("privacy.last_updated")}</p>

				{/* Section 1 */}
				<div className="mt-6">
					<h2 className="text-2xl font-bold text-gray-900">{t("privacy.sections.section1.title")}</h2>
					<p className="text-gray-700 mt-2">{t("privacy.sections.section1.content")}</p>
					{Object.keys(t("privacy.sections.section1.subsections", { returnObjects: true })).map((key) => {
						if (key === "table1" || key === "table2" || key === "table3") {
							const table = t(`privacy.sections.section1.subsections.${key}`, { returnObjects: true }) as TableData;
							return renderTable(table, `section1-${key}`);
						}
						return null;
					})}
					{(t("privacy.sections.section1.subsections.additional", { returnObjects: true }) as Array<{ title: string; content: string }>).map(
						(item, index) => (
							<div key={index} className="mt-4">
								<h3 className="text-xl font-semibold text-gray-900">{item.title}</h3>
								<p className="text-gray-700 mt-2">{item.content}</p>
							</div>
						)
					)}
				</div>

				{/* Section 2 */}
				<div className="mt-6">
					<h2 className="text-2xl font-bold text-gray-900">{t("privacy.sections.section2.title")}</h2>
					<p className="text-gray-700 mt-2">{t("privacy.sections.section2.content")}</p>
					<ul className="list-disc ml-6 mt-2 text-gray-700">
						{(t("privacy.sections.section2.conditions", { returnObjects: true }) as string[]).map((condition, index) => (
							<li key={index} dangerouslySetInnerHTML={{ __html: condition }} />
						))}
					</ul>
					{Object.keys(t("privacy.sections.section2.subsections", { returnObjects: true })).map((key) => {
						const table = t(`privacy.sections.section2.subsections.${key}`, { returnObjects: true }) as TableData;
						return renderTable(table, `section2-${key}`);
					})}
				</div>

				{/* Section 3 */}
				<div className="mt-6">
					<h2 className="text-2xl font-bold text-gray-900">{t("privacy.sections.section3.title")}</h2>
					<p className="text-gray-700 mt-2 whitespace-pre-wrap">{t("privacy.sections.section3.content")}</p>
					<ul className="list-disc ml-6 mt-2 text-gray-700">
						{(t("privacy.sections.section3.criteria", { returnObjects: true }) as string[]).map((criterion, index) => (
							<li key={index}>{criterion}</li>
						))}
					</ul>
					<p className="text-gray-700 mt-2">{t("privacy.sections.section3.additional")}</p>
				</div>

				{/* Section 4 */}
				<div className="mt-6">
					<h2 className="text-2xl font-bold text-gray-900">{t("privacy.sections.section4.title")}</h2>
					<p className="text-gray-700 mt-2 whitespace-pre-wrap">{t("privacy.sections.section4.content")}</p>
					{Object.keys(t("privacy.sections.section4.tables", { returnObjects: true })).map((key) => {
						const table = t(`privacy.sections.section4.tables.${key}`, { returnObjects: true }) as TableData;
						return renderTable(table, `section4-${key}`);
					})}
					<p className="text-gray-700 mt-2">{t("privacy.sections.section4.additional")}</p>
				</div>

				{/* Section 5 */}
				<div className="mt-6">
					<h2 className="text-2xl font-bold text-gray-900">{t("privacy.sections.section5.title")}</h2>
					<p className="text-gray-700 mt-2">{t("privacy.sections.section5.content")}</p>
					{Object.keys(t("privacy.sections.section5.tables", { returnObjects: true })).map((key) => {
						const table = t(`privacy.sections.section5.tables.${key}`, { returnObjects: true }) as TableData;
						return renderTable(table, `section5-${key}`);
					})}
					{(t("privacy.sections.section5.subsections", { returnObjects: true }) as Array<{ title: string; content: string }>).map((item, index) => (
						<div key={index} className="mt-4">
							<h3 className="text-xl font-semibold text-gray-900">{item.title}</h3>
							<p className="text-gray-700 mt-2 whitespace-pre-wrap">{item.content}</p>
						</div>
					))}
				</div>

				{/* Section 6 */}
				<div className="mt-6">
					<h2 className="text-2xl font-bold text-gray-900">{t("privacy.sections.section6.title")}</h2>
					<p className="text-gray-700 mt-2">{t("privacy.sections.section6.content")}</p>
					{(t("privacy.sections.section6.subsections", { returnObjects: true }) as Array<{ title: string; content: string }>).map((item, index) => (
						<div key={index} className="mt-4">
							<h3 className="text-xl font-semibold text-gray-900">{item.title}</h3>
							<p className="text-gray-700 mt-2">{item.content}</p>
						</div>
					))}
				</div>

				{/* Section 7 */}
				<div className="mt-6">
					<h2 className="text-2xl font-bold text-gray-900">{t("privacy.sections.section7.title")}</h2>
					{(t("privacy.sections.section7.subsections", { returnObjects: true }) as Array<{ title: string; content: string; rights?: string[] }>).map(
						(item, index) => (
							<div key={index} className="mt-4">
								<h3 className="text-xl font-semibold text-gray-900">{item.title}</h3>
								<p className="text-gray-700 mt-2 whitespace-pre-wrap">{item.content}</p>
								{item.rights && (
									<ul className="list-disc ml-6 mt-2 text-gray-700">
										{(item.rights as string[]).map((right, rightIndex) => (
											<li key={rightIndex}>{right}</li>
										))}
									</ul>
								)}
							</div>
						)
					)}
				</div>

				{/* Section 8 */}
				<div className="mt-6">
					<h2 className="text-2xl font-bold text-gray-900">{t("privacy.sections.section8.title")}</h2>
					<p className="text-gray-700 mt-2">{t("privacy.sections.section8.content")}</p>
					{Object.keys(t("privacy.sections.section8.tables", { returnObjects: true })).map((key) => {
						const table = t(`privacy.sections.section8.tables.${key}`, { returnObjects: true }) as TableData;
						return renderTable(table, `section8-${key}`);
					})}
					<p className="text-gray-700 mt-2" dangerouslySetInnerHTML={{ __html: t("privacy.sections.section8.additional") }} />
				</div>

				{/* Section 9 */}
				<div className="mt-6">
					<h2 className="text-2xl font-bold text-gray-900">{t("privacy.sections.section9.title")}</h2>
					<p className="text-gray-700 mt-2 whitespace-pre-wrap">{t("privacy.sections.section9.content")}</p>
					{(t("privacy.sections.section9.methods", { returnObjects: true }) as Array<{ title: string; items: string[] }>).map((method, index) => (
						<div key={index} className="mt-4">
							<h3 className="text-xl font-semibold text-gray-900">{method.title}</h3>
							<ul className="list-disc ml-6 mt-2 text-gray-700">
								{method.items.map((item, itemIndex) => (
									<li key={itemIndex}>{item}</li>
								))}
							</ul>
						</div>
					))}
				</div>

				{/* Section 10 */}
				<div className="mt-6">
					<h2 className="text-2xl font-bold text-gray-900">{t("privacy.sections.section10.title")}</h2>
					{(
						t("privacy.sections.section10.subsections", { returnObjects: true }) as Array<{
							title: string;
							content: string;
							tables?: { [key: string]: TableData };
							additional?: string;
							methods?: string[];
						}>
					).map((subsection, index) => (
						<div key={index} className="mt-4">
							<h3 className="text-xl font-semibold text-gray-900">{subsection.title}</h3>
							<p className="text-gray-700 mt-2 whitespace-pre-wrap">{subsection.content}</p>
							{subsection.tables &&
								Object.keys(subsection.tables).map((key) => {
									const table = subsection.tables![key];
									return renderTable(table, `section10-${index}-${key}`);
								})}
							{subsection.additional && <p className="text-gray-700 mt-2 whitespace-pre-wrap">{subsection.additional}</p>}
							{subsection.methods && (
								<ul className="list-disc ml-6 mt-2 text-gray-700">
									{subsection.methods.map((method, methodIndex) => (
										<li key={methodIndex} dangerouslySetInnerHTML={{ __html: method }} />
									))}
								</ul>
							)}
						</div>
					))}
				</div>

				{/* Section 11 */}
				<div className="mt-6">
					<h2 className="text-2xl font-bold text-gray-900">{t("privacy.sections.section11.title")}</h2>
					<p className="text-gray-700 mt-2 whitespace-pre-wrap">{t("privacy.sections.section11.content")}</p>
				</div>

				<div className="mt-6">
					<a href="/member/join/agreement/privacy-policy/history" target="_blank" className="text-blue-600 hover:underline text-lg">
						이전 개인정보 처리방침 확인하기
					</a>
				</div>
			</div>
		</div>
	);
};

export default PrivacyPolicyPage;
