import React, { createContext, useContext, useEffect, useState } from "react";
import authClient from "apis/auth";

interface User {
	id: number;
	email: string;
	// role: string;
	name: string;
	phoneNumber: string;
}

interface UserContextType {
	user: User | null | string;
	isLoggedIn: boolean;
	logout: () => void;
}

const UserContext = createContext<UserContextType | undefined>(undefined);
export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const [user, setUser] = useState<User | "loading" | null>("loading");
	const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

	useEffect(() => {
		const fetchUser = async () => {
			try {
				const response = await authClient.get("/user/profile/me");
				setUser(response.data.data);
				setIsLoggedIn(true);
			} catch (error) {
				setUser(null);
				setIsLoggedIn(false);
			}
		};

		if (localStorage.getItem("access_token")) {
			fetchUser();
		} else {
			setUser(null);
		}
	}, []);

	const logout = () => {
		localStorage.removeItem("access_token");
		localStorage.removeItem("refresh_token");
		setUser(null);
		setIsLoggedIn(false);
	};

	return <UserContext.Provider value={{ user, isLoggedIn, logout }}>{user === "loading" ? <div>Loading...</div> : children}</UserContext.Provider>;
};

export const useUser = () => {
	const context = useContext(UserContext);
	if (!context) {
		throw new Error("useUser must be used within a UserProvider");
	}
	return context;
};
