"use client";

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Disclosure, DisclosureButton, DisclosurePanel, Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import { StarIcon } from "@heroicons/react/20/solid";
import { HeartIcon, MinusIcon, PlusIcon } from "@heroicons/react/24/outline";
import authClient from "apis/auth";
import { commonClient } from "apis/common";
import QuillMadeArticle from "components/Article/QuillMade";
import SpinnerMedium from "components/Spinner/Medium";
import { useUser } from "contexts/UserProvider";
import _ from "lodash";
import Quill from "quill";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { formatCurrency } from "utils/strUtil";

function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(" ");
}

interface ProductDetail {
	sizeInfo: Record<string, string>;
	options: Record<string, string>;
	measureValues: Record<string, number>;
}

const ProductDetail = () => {
	const { user } = useUser();
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	const { productId } = useParams();
	const [quill] = useState(new Quill(document.createElement("div")));
	const [productData, setProductData] = useState<any>(null);
	const [detailHtmlTxt, setDetailHtmlTxt] = useState<string | null>(null);
	const [wishlistAdded, setWishlistAdded] = useState(false);
	const [productDetail, setProductDetail] = useState<ProductDetail>({
		sizeInfo: {},
		options: {},
		measureValues: {},
	});
	const [reviews, setReviews] = useState<any[]>([]);
	const [totalReviews, setTotalReviews] = useState(0);
	const [selectedOptions, setSelectedOptions] = useState<Record<string, any>>({});
	const [loading, setLoading] = useState(true);
	const [similarProducts, setSimilarProducts] = useState<any[]>([]);

	// 상품 및 리뷰 데이터 병렬 호출
	useEffect(() => {
		if (_.isNil(productId)) return;
		const fetchData = async () => {
			try {
				const [productRes, reviewRes] = await Promise.all([
					commonClient.get(`/products/${productId}?locale=${i18n.language}`),
					commonClient.get(`/reviews/product/${productId}?limit=3&page=1`),
				]);
				const product = productRes.data.data;
				setProductData(product);
				setLoading(false);

				if (reviewRes.data.success) {
					setReviews(reviewRes.data.data.items);
					setTotalReviews(reviewRes.data.data.total);
				}

				try {
					// 상품 상세 정보 조회
					const detailRes = await commonClient.get(`/product-detail/${productId}`);
					if (detailRes.data.data) {
						const detailData = detailRes.data.data;
						setProductDetail({
							sizeInfo: detailData.sizeInfo ? JSON.parse(detailData.sizeInfo) : {},
							options: detailData.options ? JSON.parse(detailData.options) : {},
							measureValues: detailData.measureValues ? JSON.parse(detailData.measureValues) : {},
						});
					}
				} catch (error) {
					console.error("detail 데이터 로드 실패:", error);
				}

				// 옵션 기본값 설정
				if (product && product.options) {
					const defaultOptions: Record<string, any> = {};
					product.options.forEach((option: any) => {
						defaultOptions[option.name] = option.values[0]?.value || "";
					});
					setSelectedOptions(defaultOptions);
				}

				try {
					// 유사 상품 조회: 같은 카테고리, 같은 브랜드 (최대 20개)
					if (product && product.category && product.seller) {
						const similarRes = await commonClient.get(
							`/products/similar?categoryId=${product.category.categoryId}&sellerId=${product.seller.sellerId}&limit=20`
						);
						setSimilarProducts(similarRes.data.data.items || []);
					}
				} catch (error) {
					console.error("similar 데이터 로드 실패:", error);
					setSimilarProducts([]);
				}
			} catch (error) {
				console.error("데이터 로드 실패:", error);
				toast.error(t("product.fetch_failed"));
				setLoading(false);
			}
		};

		fetchData();
	}, [productId, i18n.language]);

	// Quill 콘텐츠 설정
	useEffect(() => {
		if (_.isNil(quill) || _.isNil(productData)) return;
		const contents = productData?.contents;
		if (!contents) return;
		quill.setContents(contents);
		const tempHtml = quill.getSemanticHTML();
		setDetailHtmlTxt(tempHtml);
	}, [quill, productData]);

	// 찜한 상품 확인
	useEffect(() => {
		if (!user || !productData) return;
		const checkWishlist = async () => {
			try {
				const response = await authClient.get("/user/wishlist");
				const wishlistItems = response.data.data.items;
				setWishlistAdded(wishlistItems.some((item: any) => item.product.productId === Number(productId)));
			} catch (error) {
				console.error("Wishlist 불러오기 실패:", error);
				toast.error(t("product.wishlist_fetch_failed"));
			}
		};
		checkWishlist();
	}, [productData, productId, user, t]);

	// 옵션 선택 변경
	const handleOptionChange = (optionName: string, selectedValue: any) => {
		setSelectedOptions((prevOptions) => ({
			...prevOptions,
			[optionName]: {
				optionValueId: selectedValue?.valueId || null,
				value: selectedValue?.value || "",
			},
		}));
	};

	// 장바구니 추가
	const handleAddToCart = async () => {
		if (!user) {
			return toast.warn(t("product.login_required"));
		}
		const selectedOptionValueId = Object.values(selectedOptions)
			.map((opt: any) => opt.optionValueId)
			.filter((id) => id !== null)[0];
		const body = {
			productId: productData.productId,
			quantity: 1,
			optionValueId: selectedOptionValueId || undefined,
		};
		try {
			await authClient.post(`/user/carts/add`, body);
			toast.success(t("product.added_to_cart"));
		} catch (e) {
			console.error("[handleAddToCart] error", e);
			toast.error(t("product.add_to_cart_failed"));
		}
	};

	// 찜하기 토글
	const handleWishlistToggle = async () => {
		if (!productData) return;
		try {
			if (wishlistAdded) {
				await authClient.delete(`/user/wishlist/${productData.productId}`);
				toast.info(t("product.removed_from_wishlist"));
			} else {
				await authClient.post(`/user/wishlist/${productData.productId}`);
				toast.success(t("product.added_to_wishlist"));
			}
			setWishlistAdded(!wishlistAdded);
		} catch (error) {
			console.error("Wishlist 변경 실패:", error);
			toast.error(t("product.wishlist_toggle_failed"));
		}
	};

	if (loading) return <SpinnerMedium />;
	if (!productData) return <p className="text-center text-gray-600">{t("product.no_product_info")}</p>;

	// 이미지 URL 배열 생성
	const imagesUrls = [productData.mainImage, ...productData.images.map((img: any) => img.url)];

	return (
		<div className="bg-white">
			<div className="mx-auto max-w-2xl px-4 py-6 sm:px-6 sm:py-6 lg:max-w-7xl lg:px-8">
				<a
					className="text-sm font-semibold text-rose-400 hover:text-rose-300 mb-3 block cursor-pointer"
					onClick={() => {
						navigate(-1);
					}}>
					<span aria-hidden="true">&larr; </span>
					{t("common.back")}
				</a>
				<div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8">
					{/* 이미지 갤러리 */}
					<TabGroup className="flex flex-col-reverse">
						{/* Image selector */}
						<div className="mx-auto mt-6 w-full max-w-2xl lg:max-w-none hidden sm:block">
							<TabList className="grid grid-cols-4 gap-6">
								{imagesUrls.map((imageUrl, i) => (
									<Tab
										key={i}
										className="group relative flex h-24 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4">
										{/* <span className="sr-only">{image}</span> */}
										<span className="absolute inset-0 overflow-hidden rounded-md">
											<img alt="" src={imageUrl} className="h-full w-full object-cover object-center" />
										</span>
										<span
											aria-hidden="true"
											className="pointer-events-none absolute inset-0 rounded-md ring-2 ring-transparent ring-offset-2 group-data-[selected]:ring-indigo-500"
										/>
									</Tab>
								))}
							</TabList>
						</div>

						<div className="mx-auto mt-6 w-full max-w-2xl lg:max-w-none block sm:hidden overflow-scroll">
							<TabList className="flex space-x-2 py-4">
								{imagesUrls.map((imageUrl, i) => (
									<Tab
										key={i}
										className="group relative cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4">
										{/* <span className="sr-only">{image}</span> */}
										<div className="w-40 h-32">
											<span className="absolute inset-0 overflow-hidden rounded-md">
												<img alt="" src={imageUrl} className="h-full w-full object-cover object-center" />
											</span>
											<span
												aria-hidden="true"
												className="pointer-events-none absolute inset-0 rounded-md ring-2 ring-transparent ring-offset-2 group-data-[selected]:ring-indigo-500"
											/>
										</div>
									</Tab>
								))}
							</TabList>
						</div>

						<TabPanels className="aspect-h-1 aspect-w-1 w-full">
							{imagesUrls.map((imageUrl, i) => (
								<TabPanel key={i}>
									<img src={imageUrl} className="h-full w-full object-cover object-center sm:rounded-lg" />
								</TabPanel>
							))}
							{imagesUrls.map((imageUrl, i) => (
								<TabPanel key={i}>
									<img src={imageUrl} className="h-full w-full object-cover object-center sm:rounded-lg" />
								</TabPanel>
							))}
						</TabPanels>
					</TabGroup>

					{/* 상품 정보 */}
					<div className="mt-10 px-4 sm:mt-16 sm:px-0 lg:mt-0">
						<h1 className="text-3xl font-bold tracking-tight text-gray-900">{productData.name}</h1>

						<div className="mt-3">
							<h2 className="sr-only">{t("product.infomation")}</h2>
							<p className="text-3xl tracking-tight text-gray-900">{formatCurrency(productData.price, i18n.language)}</p>
						</div>

						{/* Reviews */}
						<div className="mt-3">
							<h3 className="sr-only">{t("product.review")}</h3>
							<div className="flex items-center">
								<div className="flex items-center">
									{[0, 1, 2, 3, 4].map((rating) => (
										<StarIcon
											key={rating}
											aria-hidden="true"
											className="text-rose-300 h-5 w-5 flex-shrink-0"
											// className={classNames(product.rating > rating ? "text-rose-300" : "text-gray-300", "h-5 w-5 flex-shrink-0")}
										/>
									))}
								</div>
								<p className="sr-only">5 out of 5 stars</p>
								{/* <p className="sr-only">{product.rating} out of 5 stars</p> */}
							</div>
						</div>

						<div className="mt-6">
							<h3 className="sr-only">{t("product.description")}</h3>

							<div dangerouslySetInnerHTML={{ __html: productData.description }} className="space-y-6 text-base text-gray-700" />
						</div>

						<form className="mt-6">
							{/* <h3 className="text-lg font-semibold text-gray-900">옵션 선택</h3> */}
							{productData.options.length > 0 ? (
								productData.options.map((option) => (
									<div key={option.optionId} className="mb-4">
										<label className="block text-gray-700 font-medium">{option.name}</label>
										<select
											value={selectedOptions[option.name]?.optionValueId || ""}
											onChange={(e) => {
												const selectedValue = option.values.find((v) => v.value === e.target.value);
												handleOptionChange(option.name, selectedValue);
											}}
											className="w-full border rounded-lg p-2 mt-2">
											<option value="">{t("product.choice_option")}</option> {/* 기본 선택값 */}
											{option.values.map((value) => (
												<option key={value.valueId} value={value.value}>
													{value.value}{" "}
													{parseFloat(value.additionalPrice) > 0 ? `(+${formatCurrency(value.additionalPrice, i18n.language)})` : ""}
												</option>
											))}
										</select>
									</div>
								))
							) : (
								<></>
								// <p className="text-gray-500">이 상품은 옵션이 없습니다.</p>
							)}

							<div className="mt-10 flex">
								<button
									type="submit"
									className="flex max-w-xs flex-1 items-center justify-center rounded-md border border-transparent bg-rose-400 px-8 py-3 text-base font-medium text-white hover:bg-rose-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50 sm:w-full"
									onClick={(e) => {
										e.preventDefault();
										handleAddToCart();
									}}>
									{t("product.addbag")}
								</button>

								<button
									type="button"
									onClick={handleWishlistToggle}
									className="ml-4 flex items-center justify-center rounded-md px-3 py-3 text-gray-400 hover:bg-gray-100 hover:text-gray-500">
									<HeartIcon aria-hidden="true" className={`h-6 w-6 flex-shrink-0 ${wishlistAdded ? "text-red-500" : "text-gray-400"}`} />
									<span className="sr-only">{t("product.addfavorites")}</span>
								</button>
							</div>
						</form>

						{/* 상품 상세 정보 섹션 */}
						<section aria-labelledby="details-heading" className="mt-12">
							<h2 id="details-heading" className="sr-only">
								{t("product.details")}
							</h2>
							<div className="divide-y divide-gray-200 border-t">
								<Disclosure as="div" defaultOpen={true}>
									<h3>
										<DisclosureButton className="group relative flex w-full items-center justify-between py-6 text-left">
											<span className="text-sm font-medium text-gray-900 group-data-[open]:text-rose-400">
												{t("product.description")}
											</span>
											<span className="ml-6 flex items-center">
												<PlusIcon className="block h-6 w-6 text-gray-400 group-hover:text-gray-500 group-data-[open]:hidden" />
												<MinusIcon className="hidden h-6 w-6 text-rose-200 group-hover:text-rose-300 group-data-[open]:block" />
											</span>
										</DisclosureButton>
									</h3>
									<DisclosurePanel className="prose prose-sm pb-6">
										{/* <ul role="list">
                                                {detail.items.map((item) => (
                                                    <li key={item}>{item}</li>
                                                ))}
                                            </ul> */}
										<QuillMadeArticle htmlText={detailHtmlTxt || ""} />
									</DisclosurePanel>
								</Disclosure>
								{Object.keys(productDetail.measureValues).length > 0 && (
									<Disclosure as="div" defaultOpen={true}>
										<h3>
											<DisclosureButton className="group relative flex w-full items-center justify-between py-6 text-left">
												<span className="text-sm font-medium text-gray-900 group-data-[open]:text-rose-400">
													{t("product.additional_details")}
												</span>
												<span className="ml-6 flex items-center">
													<PlusIcon className="block h-6 w-6 text-gray-400 group-hover:text-gray-500 group-data-[open]:hidden" />
													<MinusIcon className="hidden h-6 w-6 text-rose-200 group-hover:text-rose-300 group-data-[open]:block" />
												</span>
											</DisclosureButton>
										</h3>
										<DisclosurePanel className="prose prose-sm pb-6">
											<ul>
												{Object.entries(productDetail.measureValues).map(([key, value]) => (
													<li key={key}>
														<strong>{key}:</strong> {value}cm
													</li>
												))}
											</ul>
										</DisclosurePanel>
									</Disclosure>
								)}
								<Disclosure as="div" defaultOpen={false}>
									<h3>
										<DisclosureButton className="group relative flex w-full items-center justify-between py-6 text-left">
											<span className="text-sm font-medium text-gray-900 group-data-[open]:text-rose-400">
												{t("product.shipping_returns")}
											</span>
											<span className="ml-6 flex items-center">
												<PlusIcon className="block h-6 w-6 text-gray-400 group-hover:text-gray-500 group-data-[open]:hidden" />
												<MinusIcon className="hidden h-6 w-6 text-rose-200 group-hover:text-rose-300 group-data-[open]:block" />
											</span>
										</DisclosureButton>
									</h3>
									<DisclosurePanel className="prose prose-sm pb-6">
										<p>{t("product.shipping_info")}</p>
										<p>{t("product.return_policy")}</p>
									</DisclosurePanel>
								</Disclosure>
								<Disclosure as="div" defaultOpen={false}>
									<h3>
										<DisclosureButton className="group relative flex w-full items-center justify-between py-6 text-left">
											<span className="text-sm font-medium text-gray-900 group-data-[open]:text-rose-400">
												{t("product.seller_info")}
											</span>
											<span className="ml-6 flex items-center">
												<PlusIcon className="block h-6 w-6 text-gray-400 group-hover:text-gray-500 group-data-[open]:hidden" />
												<MinusIcon className="hidden h-6 w-6 text-rose-200 group-hover:text-rose-300 group-data-[open]:block" />
											</span>
										</DisclosureButton>
									</h3>
									<DisclosurePanel className="prose prose-sm pb-6">
										<ul>
											<li>
												<strong>{t("product.seller")}:</strong> {productData.seller.name || t("product.info_not_available")}
											</li>
											<li>
												<strong>{t("product.country")}:</strong> {productData.country || t("product.info_not_available")}
											</li>
											<li>
												<strong>{t("product.manufacturer")}:</strong> {productData.manufacturer || t("product.info_not_available")}
											</li>
										</ul>
									</DisclosurePanel>
								</Disclosure>
								<Disclosure as="div" defaultOpen={true}>
									<h3>
										<DisclosureButton className="group relative flex w-full items-center justify-between py-6 text-left">
											<span className="text-sm font-medium text-gray-900 group-data-[open]:text-rose-400">
												{t("product.customer_review")}
											</span>
											<span className="ml-6 flex items-center">
												<PlusIcon className="block h-6 w-6 text-gray-400 group-hover:text-gray-500 group-data-[open]:hidden" />
												<MinusIcon className="hidden h-6 w-6 text-rose-200 group-hover:text-rose-300 group-data-[open]:block" />
											</span>
										</DisclosureButton>
									</h3>
									<DisclosurePanel className="prose prose-sm pb-6">
										{reviews.length > 0 ? (
											<ul className="space-y-4">
												{reviews.map((review) => (
													<li key={review.reviewId} className="border p-4 rounded-lg shadow-sm bg-gray-50">
														<div className="flex items-center space-x-2">
															<p className="font-semibold">{review.user.name}</p>
															<div className="flex">
																{[...Array(5)].map((_, i) => (
																	<StarIcon
																		key={i}
																		className={classNames(
																			i < review.rating ? "text-yellow-400" : "text-gray-300",
																			"h-4 w-4 flex-shrink-0"
																		)}
																	/>
																))}
															</div>
														</div>
														<p className="text-gray-600 mt-2">{review.content}</p>
														{review.images.length > 0 && (
															<div className="mt-2 flex space-x-2">
																{review.images.map((image) => (
																	<img
																		key={image.image_id}
																		src={image.url}
																		alt="Review Image"
																		className="w-16 h-16 rounded-md object-cover"
																	/>
																))}
															</div>
														)}
													</li>
												))}
											</ul>
										) : (
											<p className="text-gray-500">{t("product.no_review")}</p>
										)}
										{totalReviews > 0 && (
											<div className="mt-4 text-center">
												<button
													onClick={() => navigate(`/product/review/${productId}`)}
													className="text-rose-400 hover:underline font-medium">
													{t("product.more_review")} ({totalReviews})
												</button>
											</div>
										)}
									</DisclosurePanel>
								</Disclosure>
							</div>
						</section>
					</div>
				</div>

				{/* 하단: 같은 카테고리 내, 같은 브랜드의 다른 상품 섹션 */}
				<section className="mt-12">
					<h2 className="text-2xl font-bold text-gray-900 mb-4">{t("product.other_products_same_brand")}</h2>
					<div className="overflow-x-auto">
						<div className="flex space-x-4 px-4">
							{similarProducts && similarProducts.length > 0 ? (
								similarProducts.map((prod: any) => (
									<div
										key={prod.productId}
										className="w-56 flex-shrink-0 border rounded-lg p-4 hover:shadow-lg cursor-pointer"
										onClick={() => {
											navigate(`/product/${prod.productId}`);
											window.scrollTo(0, 0);
										}}>
										<img src={prod.mainImage} alt={prod.name} className="w-full h-40 object-cover rounded-md" />
										<p className="mt-2 text-center font-semibold">{prod.name}</p>
									</div>
								))
							) : (
								<p className="text-gray-500">{t("product.no_other_products")}</p>
							)}
						</div>
					</div>
					<div className="mt-4 text-center">
						<button onClick={() => navigate(`/category/${productData.category.categoryId}`)} className="text-rose-400 hover:underline font-medium">
							{t("product.more_in_category")}
						</button>
					</div>
				</section>
			</div>
		</div>
	);
};

export default ProductDetail;
