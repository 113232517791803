import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const AddressSelectPopup = ({ addresses, onClose, onSelect }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	return (
		<div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
			<div className="bg-white p-6 rounded-lg w-full max-w-md">
				<h3 className="text-lg font-semibold mb-4">{t("address.select")}</h3>
				<div className="space-y-4">
					{addresses.map((address) => (
						<div key={address.addressId} className="p-4 border rounded-lg cursor-pointer hover:bg-gray-50" onClick={() => onSelect(address)}>
							<div className="font-medium">{address.name}</div>
							<div className="text-sm text-gray-600">{address.phoneNumber}</div>
							<div className="text-sm text-gray-600">
								{address.address}, {address.city}, {address.state} {address.postalCode}
							</div>
						</div>
					))}
				</div>
				<div className="mt-6 flex justify-end">
					<button onClick={() => navigate("/mypage/address")} className="mt-4 md:mt-0 text-blue-500 hover:text-blue-700">
						{t("address.manage")}
					</button>
				</div>
				<div className="mt-6 flex justify-end">
					<button onClick={onClose} className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-700">
						{t("common.close")}
					</button>
				</div>
			</div>
		</div>
	);
};

export default AddressSelectPopup;
