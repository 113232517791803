import axios from "axios";
import { SERVER_DOMAIN } from "constants/config";

const authClient = axios.create({
	baseURL: SERVER_DOMAIN,
	timeout: 10000,
	withCredentials: true,
});

authClient.interceptors.request.use(
	(config) => {
		const token = localStorage.getItem("access_token");
		console.log(token);
		if (token) {
			config.headers["Authorization"] = `Bearer ${token}`;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

authClient.interceptors.response.use(
	(response) => response,
	async (error) => {
		if (error.response?.status === 401) {
			// Token refresh or logout
			await handleUnauthorized();
		}
		return Promise.reject(error);
	}
);

const handleUnauthorized = async () => {
	try {
		const refreshToken = localStorage.getItem("refresh_token");
		if (!refreshToken) {
			logout();
			return;
		}

		const response = await authClient.post("/refresh", { refresh_token: refreshToken });
		const { access_token } = response.data;
		localStorage.setItem("access_token", access_token);
	} catch (error) {
		console.error("Token refresh failed:", error);
		logout();
	}
};

const logout = () => {
	localStorage.removeItem("access_token");
	localStorage.removeItem("refresh_token");
	window.location.href = "/login"; // Redirect to login page
};

export default authClient;
